import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'coincidenceBold',
})
export class BoldCoincidencePipe implements PipeTransform {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value: string, regex: any): any {
    return this.sanitize(this.replace(value, regex));
  }

  replace(str: string, regex: RegExp): string {
    return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
  }

  sanitize(str: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }
}
