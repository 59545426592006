<footer>
  <div class="footer-wrapper">
    <div class="main">
      <div class="footer-content">
        <div class="footer-content__left">
          <img
            class="footer-logo"
            src="assets/vinovoss-logo.webp"
            alt="app logo"
            width="234"
            height="40"
          />
          <div class="copywriting">
            <span>Copyright © {{ copyrightYear }} VinoVoss.</span>
            <span>All rights reserved.</span>
          </div>
        </div>
        <nav class="footer-menu">
          <a
            *ngFor="let link of footerLinks; let i = index"
            [routerLink]="[urlPrefix$ | async, link.link]"
            (click)="onLinkClick(link)"
          >
            {{ link.label }}
          </a>
        </nav>
        <div class="subscribe-form">
          <span>Subscribe to our Newsletter</span>
          <form class="form">
            <div class="form-group">
              <input
                class="input"
                type="email"
                id="email"
                [formControl]="subscribeFormControl"
                placeholder="Enter email address"
              />
              <ng-container *ngIf="subscribeFormControl.touched">
                <div class="validation-error" *ngIf="subscribeFormControl.hasError('required')">
                  This field must be filled
                </div>
                <div class="validation-error" *ngIf="subscribeFormControl.hasError('email')">
                  Invalid email format
                </div>
              </ng-container>
            </div>
            <button type="button" class="btn btn--red" (click)="subscribe()">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</footer>
