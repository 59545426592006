<div
  class="user-flow-page-header"
  [ngClass]="{ 'user-flow-page-header--with-buttons': data.showButtons }"
  *ngIf="data"
>
  <div class="user-flow-page-content">
    <h1 class="title" *ngIf="!data.modal" [ngClass]="{ 'no-padding': !data.description.length }">
      {{ data.title }}
    </h1>
    <div class="description" *ngIf="data.description.length">
      {{ data.description }}
    </div>
  </div>
  <div class="auth-buttons" *ngIf="data.showButtons">
    <!--    <button type="button" class="btn btn&#45;&#45;auth btn&#45;&#45;auth&#45;&#45;facebook">Facebook</button>-->
    <button type="button" class="btn btn--auth btn--auth--google" (click)="googleLogin()">
      Google
    </button>
  </div>
</div>
