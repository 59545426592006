import { Observable, of } from 'rxjs';

import {
  VINTAGE_SOURCE_AWARD_WINNING,
  VINTAGE_SOURCE_CELEBRITY,
  VINTAGE_SOURCE_ORANGE,
  VINTAGE_SOURCE_ORGANIC_BIO,
  VINTAGE_SOURCE_RARE_COLLECT,
  VINTAGE_SOURCE_SOMMELIER_SELECTION,
  VINTAGE_SOURCE_XMAS,
} from '@constants';
import { environment } from '@environment';
import { VintageSource } from '@interfaces';
import { isNYTheme } from '@utils';

export type TopLayerItems = { [key: string]: TopLayerItem };

export interface TopLayerItem {
  icon: string;
  label: string;
  items: Observable<any[]>;
  opened: boolean;
  hidden?: boolean;
  search?: boolean;
}

export interface TitleMap {
  [key: string]: {
    title: string;
    vintageSource: VintageSource;
    seoTitle: string;
  };
}

export const topLayerItems: TopLayerItems = {
  ['merchant']: {
    icon: 'merchant',
    label: 'Wine bar/Shop/Restaurant: All',
    hidden: environment.production,
    opened: false,
    items: of([]),
  },
  ['money']: {
    icon: 'money',
    label: 'USD',
    hidden: environment.production,
    opened: false,
    items: of([]),
  },
  ['language']: {
    icon: 'language',
    label: 'English',
    hidden: environment.production,
    opened: false,
    items: of([]),
  },
  ['location']: {
    icon: 'location',
    label: '',
    items: of([]),
    opened: false,
  },
  ['state']: {
    icon: 'state',
    label: '',
    items: of([]),
    opened: false,
    search: true,
  },
  ['city']: {
    icon: 'city',
    label: '',
    items: of([]),
    hidden: true,
    opened: false,
  },
};

export const pageTitleSourceMap: TitleMap = {
  celebrity: {
    title: 'Celebrity-Endorsed Wines',
    vintageSource: VINTAGE_SOURCE_CELEBRITY,
    seoTitle: 'Celebrity-Endorsed Wines',
  },
  award_winning: {
    title: 'Award-Winning Wines',
    vintageSource: VINTAGE_SOURCE_AWARD_WINNING,
    seoTitle: 'Award-Winning Wines',
  },
  organic: {
    title: 'Organic / Biodynamic Wines',
    vintageSource: VINTAGE_SOURCE_ORGANIC_BIO,
    seoTitle: 'Organic / Biodynamic Wines',
  },
  orange: {
    title: 'Orange Wines',
    vintageSource: VINTAGE_SOURCE_ORANGE,
    seoTitle: 'Orange Wines',
  },
  rare: {
    title: 'Collectible Wines',
    vintageSource: VINTAGE_SOURCE_RARE_COLLECT,
    seoTitle: 'Collectible Wines',
  },
  sommelier: {
    title: 'Sommelier-Selected Wines',
    vintageSource: VINTAGE_SOURCE_SOMMELIER_SELECTION,
    seoTitle: 'Sommelier-Selected Wines',
  },
  xmas: {
    title: 'VinoVoss Christmas Top Wine Selections',
    vintageSource: VINTAGE_SOURCE_XMAS,
    seoTitle: 'VinoVoss Christmas Top Wine Selections',
  },
};

export const menuItems: any[] = [
  {
    key: 'wine-discovery',
    name: 'Wine Discovery',
    menuItems: [
      {
        name: 'Wine Color/Type',
        menuItems: [
          { name: 'Red' },
          { name: 'White' },
          { name: 'Rosé' },
          { name: 'Sparkling' },
          { name: 'Fortified' },
          { name: 'Dessert' },
        ],
      },
      {
        name: 'Top Occasions',
        menuItems: [
          { name: 'Celebration' },
          { name: 'Fine Dining' },
          { name: 'Romantic Date' },
          { name: 'Sunny Afternoon' },
          { name: 'After Work' },
          { name: 'Gift-Giving' },
        ],
      },
      {
        name: 'Unique Wines',
        menuItems: [
          { name: 'Celebrity', key: 'celebrity' },
          { name: 'Award-Winning', key: 'award_winning' },
          { name: 'Organic/Biodynamic', key: 'organic' },
          { name: 'Orange Wines', key: 'orange' },
          { name: 'Rare Collectible', key: 'rare' },
          { name: 'Sommelier Selections', key: 'sommelier' },
        ],
      },
      {
        key: 'surprise-me',
        name: 'Surprise Me!',
        menuItems: [
          { name: 'Cabernet Sauvignon' },
          { name: 'Red Zinfandel' },
          { name: 'Barolo' },
          { name: 'Pinot Noir' },
          { name: 'Malbec' },
          { name: 'Cabernet Franc' },
        ],
      },
    ],
    allButton: {
      name: 'Browse all',
    },
    additionalData: {
      searchItems: [],
      searchTitle: 'Top Searched Queries for the Last 30 Days',
      id: 'header-wine-discovery',
    },
  },
  {
    key: 'grape-varieties',
    name: 'Grape Varieties',
    menuItems: [
      {
        key: 'popular-grapes',
        name: 'Popular Grapes',
        menuItems: [
          { name: 'Chardonnay' },
          { name: 'Cabernet Sauvignon' },
          { name: 'Merlot' },
          { name: 'Sauvignon Blanc' },
          { name: 'Pinot Noir' },
          { name: 'Syrah/Shiraz' },
        ],
      },
      {
        name: 'Popular Styles',
        menuItems: [
          { name: 'Fruity' },
          { name: 'Elegant' },
          { name: 'Bold' },
          { name: 'Fresh' },
          { name: 'Smooth' },
          { name: 'Oaked' },
        ],
      },
      {
        name: 'Classic Grapes',
        menuItems: [
          { name: 'Pinot Noir' },
          { name: 'Cabernet Sauvignon' },
          { name: 'Chardonnay' },
          { name: 'Merlot' },
          { name: 'Syrah/Shiraz' },
          { name: 'Sauvignon Blanc' },
        ],
      },
      {
        name: 'Emerging Grapes',
        menuItems: [
          { name: 'Albariño' },
          { name: 'Grüner Veltliner' },
          { name: 'Vermentino' },
          { name: 'Viognier' },
          { name: 'Barbera' },
          { name: 'Blaufränkisch' },
        ],
      },
    ],
    allButton: {
      name: 'Explore Grape Varieties',
    },
    additionalData: {
      searchTitle: 'Grapes of the day',
      id: 'header-grapes',
    },
  },
  {
    key: 'regions',
    name: 'Regions',
    menuItems: [
      {
        key: 'popular-regions',
        name: 'Popular Regions',
        menuItems: [
          { name: 'France' },
          { name: 'Italy' },
          { name: 'Spain' },
          { name: 'Australia' },
          { name: 'United States' },
          { name: 'South Africa' },
        ],
      },
      {
        name: 'Old World Classics',
        menuItems: [
          { name: 'Bordeaux' },
          { name: 'Burgundy' },
          { name: 'Tuscany' },
          { name: 'Piedmont' },
          { name: 'Rhone' },
          { name: 'Champagne' },
        ],
      },
      {
        name: 'New World Gems',
        menuItems: [
          { name: 'Napa Valley' },
          { name: 'Sonoma County' },
          { name: 'Mendoza' },
          { name: 'Margaret River' },
          { name: 'Stellenbosch' },
          { name: 'Casablanca Valley' },
        ],
      },
      {
        name: 'Terroir',
        menuItems: [
          { name: 'Cool' },
          { name: 'Warm' },
          { name: 'Volcanic' },
          { name: 'Coastal/Island' },
          { name: 'High Altitude' },
          { name: 'Extreme' },
        ],
      },
    ],
    allButton: {
      name: 'Explore other Regions',
    },
    additionalData: {
      searchTitle: 'Regions of the day',
      id: 'header-regions',
    },
  },
  {
    name: 'News & Articles',
    routerLink: '/news-and-articles',
    additionalData: { id: 'header-news-link' },
  },
  {
    name: 'Handpicks for You',
    routerLink: '/profile/handpick-for-you',
    additionalData: { id: 'header-handpick-link' },
  },
  {
    name: '',
    routerLink: '/recommendations?page-key=xmas',
    hidden: !isNYTheme(),
    additionalData: { id: 'xmas-wines', classList: ['xmas'] },
  },
];
