import { Component, Input } from '@angular/core';

import { COUNTRIES } from '@constants';

@Component({
  selector: 'app-wine-country',
  templateUrl: './wine-country.component.html',
  styleUrls: ['./wine-country.component.scss'],
})
export class WineCountryComponent {
  @Input() country: string = COUNTRIES[0];
  @Input() bold = false;
  @Input() classList: string[] = [];
  @Input() color = '#686868';
}
