import { WineRegion } from '@interfaces';
import { MenuData } from '@services/menu.service';
import { getNavigationBarPart } from 'src/app/utils/json-LD/navigation-bar-part.jsonld.utils';

import { getTextFirstSentence } from '../string.utils';

import { mainPageEntity } from './main-page-entity.utils';

export const getRegionPageJsonLD = (url: string, menuData: MenuData, region: WineRegion) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    ...mainPageEntity(url),
    ...getNavigationBarPart(
      menuData.surpriseMe,
      menuData.topQueries,
      menuData.grapes,
      menuData.regions
    ),
    headline: `${region.region_name}: Overview, Wines, and Characteristics`,
    description: `${getTextFirstSentence(region.description || '')}`,
    image: {
      '@type': 'ImageObject',
      url: `${region.image.banner}`,
      height: '700',
      width: '400',
    },
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    mainContentOfPage: {
      '@type': 'Article',
      name: `${region.region_name}`,
      articleSection: [
        'Region Description',
        'Grapes & Wine Styles',
        'Notable Producers',
        'Geography & Climate',
        `Fun Fact about ${region.region_name}`,
        `Find more Wines from ${region.region_name}`,
      ],
      headline: `${region.region_name}: Overview, Wines, and Characteristics`,
      image: {
        '@type': 'ImageObject',
        url: `${region.image.banner}`,
        height: '700',
        width: '400',
      },
      author: 'VinoVoss',
      about: {
        '@type': 'WineRegion',
        name: `${region.region_name}`,
        description: `${getTextFirstSentence(region.description)}`,
        geo: {
          '@type': 'GeoCoordinates',
          latitude: `${region.lat}`,
          longitude: `${region.lng}`,
        },
        image: `${region.image.main}`,
      },
    },
  };
};
