import { Winery } from '@interfaces';
import { MenuData } from '@services/menu.service';
import { getTextFirstSentence } from '@utils';

import { mainPageEntity } from './main-page-entity.utils';
import { getNavigationBarPart } from './navigation-bar-part.jsonld.utils';

export const getWineryPageJsonLD = (url: string, menuData: MenuData, winery: Winery) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    ...mainPageEntity(url),
    ...getNavigationBarPart(
      menuData.surpriseMe,
      menuData.topQueries,
      menuData.grapes,
      menuData.regions
    ),
    headline: `${winery.name}: Overview, Wines, and Characteristics`,
    description: `${getTextFirstSentence(winery.description)}`,
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    mainContentOfPage: {
      '@type': 'Article',
      name: `${winery.name}`,
      articleSection: [
        `About ${winery.name}`,
        `Wines by ${winery.name}`,
        `Find more Wines from ${winery.name}`,
      ],
      about: {
        '@type': 'Winery',
        name: `${winery.name}`,
        description: `${getTextFirstSentence(winery.description)}`,
        country: `${winery.country}`,
      },
    },
  };
};

// "image": {
//   "@type": "ImageObject",
//   "url": `${winery.image}`, // for future
//   "height": "400",
//   "width": "1440"
// },
