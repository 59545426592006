import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GOOGLE_ANALYTICS_EVENTS } from '@constants';
import { GoogleAnalyticsService } from '@services/app/google-analytics.service';
import { ModalNotificationsService } from '@services/app/modal-notifications.service';
import { OtherEndpointsService } from '@services/other-endpoints.service';
import { AppState, getUserLocationUrlPrefix } from '@store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public readonly footerLinks = [
    { label: 'About us', link: 'about-us' },
    { label: 'Contact us', link: 'contact-us' },
    { label: 'Careers', link: 'careers' },
    { label: 'Partnership', link: 'partnership' },
    { label: 'Terms of Use', link: 'terms-of-use' },
    { label: 'Privacy Policy', link: 'privacy-policy' },
    { label: 'Content Policy', link: 'content-policy' },
    { label: 'Sitemap', link: 'sitemap' },
  ];

  subscribeFormControl: FormControl;

  urlPrefix$: Observable<string | null>;

  get copyrightYear(): number {
    return new Date().getFullYear();
  }

  constructor(
    private otherEndpointsService: OtherEndpointsService,
    private modalNotificationService: ModalNotificationsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store<AppState>
  ) {
    this.subscribeFormControl = new FormControl('', [Validators.required, Validators.email]);
  }

  ngOnInit(): void {
    this.urlPrefix$ = this.store.select(getUserLocationUrlPrefix);
  }

  subscribe(): void {
    if (this.subscribeFormControl.invalid) {
      this.subscribeFormControl.markAsTouched();
      this.subscribeFormControl.updateValueAndValidity();
      return;
    }

    this.googleAnalyticsService.push(GOOGLE_ANALYTICS_EVENTS.FOOTER.SUBMIT_FORM);

    this.otherEndpointsService
      .subscribeOnNewsletter(this.subscribeFormControl.value)
      .subscribe(() => {
        this.modalNotificationService.showSuccessPopup(
          'Success',
          'You have successfully subscribed to the newsletters'
        );
        this.subscribeFormControl.markAsUntouched();
        this.subscribeFormControl.patchValue('');
      });
  }

  onLinkClick(link: { label: string; link: string }): void {
    this.googleAnalyticsService.push(GOOGLE_ANALYTICS_EVENTS.FOOTER.LINK_TAPPED, {
      footer_link_name: link.label,
    });
  }
}
