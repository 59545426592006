import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appShowPassword]',
})
export class ShowPasswordDirective {
  @HostListener('mousedown') onMouseDown() {
    this.element.nativeElement.classList.add('show');
    this.element.nativeElement.previousSibling.type = 'text';
  }

  @HostListener('mouseup') onMouseUp() {
    this.element.nativeElement.classList.remove('show');
    this.element.nativeElement.previousSibling.type = 'password';
  }

  constructor(private element: ElementRef) {}
}
