import { isPlatformBrowser, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { DEFAULT_COUNTRY, DEFAULT_REGION, USA_COUNTRY } from '@constants';
import { environment } from '@environment';
import { APIUserLocationData, UserLocationData } from '@interfaces';
import { UpdateUserLocation } from '@store/actions';
import { clearUserLocation, getBrowserStorageUserLocation, isRouteState } from '@utils';
import { STATES_LIST } from 'src/app/constants/states.constant';
import { WINDOW } from '@ng-toolkit/universal';

const EMBEDDED_WINE_CARD_URL = '/embedded/wine/';

@Injectable({
  providedIn: 'root',
})
export class UserLocationService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store,
    private router: Router,
    private location: Location,
    private http: HttpClient,
    @Inject(WINDOW) private window: Window
  ) {}

  public locationLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  async getUserLocationData() {
    if (
      isPlatformBrowser(this.platformId) &&
      !this.window.location.pathname.includes(EMBEDDED_WINE_CARD_URL)
    ) {
      const geolocationPermissions = await navigator.permissions.query({ name: 'geolocation' });
      const isGeolocationGranted = geolocationPermissions.state === 'granted';

      if (isGeolocationGranted) {
        clearUserLocation();
      }

      if (getBrowserStorageUserLocation() && isGeolocationGranted) {
        this.store.dispatch(new UpdateUserLocation(getBrowserStorageUserLocation()));
        return;
      }

      this.locationLoading$.next(true);

      navigator.geolocation.getCurrentPosition(
        (loc: GeolocationPosition) => {
          const latitude = loc.coords.latitude;
          const longitude = loc.coords.longitude;

          this.http
            .get<APIUserLocationData>(
              `${environment.API_URL}/reverse-geocode?latitude=${latitude}&longitude=${longitude}`
            )
            .subscribe({
              next: (data) => {
                this.store.dispatch(
                  new UpdateUserLocation({
                    ...data,
                    initial_country: data.country,
                    initial_region: data.region,
                    latitude,
                    longitude,
                  })
                );
                this.locationLoading$.next(false);
              },
              error: () => this.locationLoading$.next(false),
            });
        },
        () => {
          this.store.dispatch(
            new UpdateUserLocation({
              country: DEFAULT_COUNTRY,
              region: DEFAULT_REGION,
              initial_country: DEFAULT_COUNTRY,
              initial_region: DEFAULT_REGION,
            })
          );

          this.locationLoading$.next(false);
        }
      );
    }
  }

  getUrlPrefix(data: UserLocationData): string {
    if (isPlatformBrowser(this.platformId)) {
      let segments = window.location.pathname.split('/');
      segments = segments.filter((str) => !!str.length);

      if (isRouteState(`${segments[0]}/${segments[1]}`)) {
        delete segments[0];
        delete segments[1];
        segments = segments.filter((item) => !!item);
      }

      if (data.country === USA_COUNTRY) {
        const currentState = STATES_LIST.find((item) => item.name === data.region)?.value || '';

        segments.unshift('en');
        segments.unshift(`us${currentState.length ? '-' + currentState : ''}`);

        if (!window.location.pathname.includes(`/${segments[0]}/`)) {
          const url = this.router.createUrlTree(segments).toString();

          this.location.replaceState(`${url}${window.location.search}`);
        }

        return `/${segments[0] || ''}/en`;
      } else {
        if (!segments.length) {
          segments.push('');
        }

        const url = this.router.createUrlTree(segments).toString();
        this.location.replaceState(`${url}${window.location.search}`);

        if (data.url_prefix !== '' && data.url_prefix !== '/') {
          return '';
        }

        return data.url_prefix;
      }
    }

    return '';
  }
}
