import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LoaderService } from '@services/app/loader.service';

/* eslint-disable */
@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  private excludedRoutes = [
    'profile/favorites',
    'profile/rec-and-offers',
    'profile/browser-history',
    'news-and-articles',
    'recommendations',
  ];

  private excludedRequests = [
    '/meta',
    '/similar_vintages',
    '/cv',
    '/sibling',
    '/prices',
    '/drinking_window',
    '/rating',
    '/grapes',
    '/top_searched_queries',
    '/users-profile/wishlists',
    '/auto-suggest/v1',
  ];

  constructor(private loaderService: LoaderService, private router: Router) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    this.loaderService.isLoading$.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.excludedRoutes.some((str) => this.router.routerState.snapshot.url.includes(str))) {
      return next.handle(req);
    }

    if (this.excludedRequests.some((str) => req.url.includes(str))) {
      return next.handle(req);
    }

    this.requests.push(req);
    this.loaderService.isLoading$.next(true);

    return new Observable((observer: any) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );

      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}

/* eslint-enable */
