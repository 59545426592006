import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';

import { LOGIN, LOGIN_BAD_CREDENTIALS, LOGIN_MODAL, USER_FLOW_PAGE_DATA } from '@constants';
import { User, UserFlowPageData } from '@interfaces';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { setJWTToken, setUserProfile } from '@utils';

import { AppState, getUserLocationUrlPrefix } from '@store';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() urlAfterLogin: string | undefined = undefined;

  @Input() modal = false;

  @Output() closeDialog: EventEmitter<unknown> = new EventEmitter<unknown>();

  pageData: UserFlowPageData;

  loginForm: FormGroup;

  urlPrefix: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private store: Store<AppState>,
    @Inject(WINDOW) private window: Window
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      stay_login: [false],
    });

    this.store.select(getUserLocationUrlPrefix).subscribe((prefix) => {
      this.urlPrefix = prefix;
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.authService
      .login(this.loginForm.value)
      .pipe(
        tap((response) => setJWTToken(response.access_token)),
        switchMap(() => this.userService.me())
      )
      .subscribe({
        next: (user: User) => {
          setUserProfile(user);
          this.userService.userSubject$.next(user);

          if (!this.modal) {
            this.router.navigate([this.urlPrefix]);
          } else {
            this.closeDialog.emit();

            if (this.urlAfterLogin?.length) {
              this.router.navigate([this.urlAfterLogin]);
            } else {
              this.reloadPage();
            }
          }
        },
        error: (err: any) => {
          switch (err.error.detail) {
            case LOGIN_BAD_CREDENTIALS:
              this.loginForm.controls['password'].setErrors({ badCredentials: true });
              break;
            default:
              console.error(err.error);
          }
        },
      });
  }

  ngOnInit(): void {
    this.pageData =
      this.urlAfterLogin?.length && this.modal
        ? { ...USER_FLOW_PAGE_DATA[LOGIN_MODAL], urlAfterLogin: this.urlAfterLogin }
        : USER_FLOW_PAGE_DATA[this.modal ? LOGIN_MODAL : LOGIN];
  }

  goToSignUp(): void {
    if (this.modal) {
      this.closeDialog.emit();
    }

    this.router.navigate([this.urlPrefix, 'user', 'sign-up']);
  }

  goToForgotPassword(): void {
    if (this.modal) {
      this.closeDialog.emit();
    }

    this.router.navigate([this.urlPrefix, 'user', 'forgot-password']);
  }

  private reloadPage(): void {
    this.window.location.reload();
  }
}
