import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seoUrlFormat',
})
export class SeoUrlFormatPipe implements PipeTransform {
  transform(value: string, toLowerCase = true, separator = '-'): unknown {
    let formatted = value
      .replace(',', '')
      .replace(/[^\w\s]/gi, '')
      .replace(/[()]/g, '')
      .replace(/\s+/g, separator);

    if (toLowerCase) {
      formatted = formatted.toLowerCase();
    }

    return formatted;
  }
}
