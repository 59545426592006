import {
  WINE_COLOR_OTHER,
  WINE_COLOR_RED,
  WINE_COLOR_ROSE,
  WINE_COLOR_WHITE,
  WINE_RARING_RANKS,
  WINE_TYPE_DESSERT,
  WINE_TYPE_FORTIFIED,
  WINE_TYPE_SPARKLING,
  WINE_TYPE_STILL,
} from '@constants';
import { WineColor, WineType, WineTypeColor } from '@interfaces';

export const getWineRatingRank = (rank: number): string => {
  return WINE_RARING_RANKS[rank];
};

export const getWineClassByType = (type: WineType | null): string => {
  switch (type) {
    case WINE_TYPE_STILL:
      return WINE_TYPE_STILL.toLowerCase();
    case WINE_TYPE_SPARKLING:
      return WINE_TYPE_SPARKLING.toLowerCase();
    case WINE_TYPE_FORTIFIED:
      return WINE_TYPE_FORTIFIED.toLowerCase();
    case WINE_TYPE_DESSERT:
      return WINE_TYPE_DESSERT.toLowerCase();
    default:
      return WINE_COLOR_OTHER.toLowerCase();
  }
};

export const getWineClassByColor = (color: WineColor | null): string => {
  switch (color) {
    case WINE_COLOR_RED:
      return WINE_COLOR_RED.toLowerCase();
    case WINE_COLOR_WHITE:
      return WINE_COLOR_WHITE.toLowerCase();
    case WINE_COLOR_ROSE:
      return WINE_COLOR_ROSE.toLowerCase();
    default:
      return WINE_COLOR_OTHER.toLowerCase();
  }
};

export const getWineClassByColorType = (data: WineTypeColor): string => {
  if (data.color === WINE_COLOR_ROSE) {
    return getWineClassByColor(WINE_COLOR_ROSE);
  }

  if (data.type === WINE_TYPE_STILL) {
    if (!data.color) {
      return WINE_COLOR_RED.toLowerCase();
    }

    return getWineClassByColor(data.color);
  }

  if (data.type === WINE_TYPE_SPARKLING) {
    switch (data.color) {
      case WINE_COLOR_WHITE:
      case null:
        return WINE_TYPE_SPARKLING.toLowerCase();
      default:
        return getWineClassByColor(data.color);
    }
  }

  if (data.type === WINE_TYPE_DESSERT) {
    return WINE_TYPE_DESSERT.toLowerCase();
  }

  if (data.type === WINE_TYPE_FORTIFIED) {
    return WINE_TYPE_FORTIFIED.toLowerCase();
  }

  return WINE_COLOR_OTHER.toLowerCase();
};
