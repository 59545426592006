import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, finalize, Observable, switchMap, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { AuthService } from '@services/auth.service';
import { getJWTToken, setJWTToken, setRefreshToken } from '@utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  readonly withoutTokenPoints = ['/auth'];

  private isTokenRefreshing = false;
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.withoutTokenPoints.find((i) => req.url.includes(i)) || req.url.includes('/logout')) {
      req = this.addAuthHeader(req);
    }

    return next.handle(req).pipe(
      catchError((error): Observable<any> => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized) {
            return this.handleUnauthorizedError(req, next);
          }

          return throwError(() => error);
        }

        return throwError(() => error);
      })
    );
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isTokenRefreshing) {
      this.isTokenRefreshing = true;
      this.tokenSubject.next(null);

      return this.authService.updateAccessToken().pipe(
        // @ts-ignore
        switchMap((tokenResponse) => {
          if (tokenResponse) {
            this.tokenSubject.next(tokenResponse.access_token);
            setJWTToken(tokenResponse.access_token);
            setRefreshToken(tokenResponse.refresh_token);

            return next.handle(this.addAuthHeader(request));
          }
        }),
        finalize(() => {
          this.isTokenRefreshing = false;
        })
      );
    } else {
      this.isTokenRefreshing = false;
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthHeader(request));
        })
      );
    }
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = getJWTToken();
    if (!token) {
      return request;
    }
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
