<div class="price" [formGroup]="rangeForm">
  <div
    class="chart"
    [style]="safeCss('grid-template-columns: repeat(' + this.steps + ', 1fr)')"
    [innerHTML]="safeHtml(chart())"
  ></div>
  <ngx-slider
    *ngIf="isBrowser"
    [options]="rangeOptions"
    (userChange)="onUserChanging($event)"
    (userChangeEnd)="onUserChange($event)"
    formControlName="range"
  ></ngx-slider>
</div>
