<div
  class="form-group"
  #wrapper
  [formGroup]="formGroup"
  clickOutside
  (onClickOutside)="toggleAutoComplete(true)"
>
  <label class="label" for="query" *ngIf="label.length">{{ label }}</label>
  <input
    class="input"
    type="text"
    id="query"
    autocomplete="off"
    aria-autocomplete="none"
    [formControlName]="fcName"
    [placeholder]="placeholder"
    (focus)="toggleAutoComplete()"
    (keydown.enter)="handlePressEnter($event)"
  />
  <i class="autocomplete-icon-clear" *ngIf="inputValue?.length" (click)="clearInput($event)"></i>

  <div class="autocomplete-results" *ngIf="inputValue?.length && !autocompleteHidden">
    <div class="result-block">
      <span class="label">Searching for:</span>
      <div class="result result--search">
        <a href="#" (click)="handleLinkClick($event, inputValue)" class="result-link">
          {{ inputValue }}
        </a>
        <a href="#" (click)="handleSeeAllClick($event)" class="btn btn--link btn--link__red">
          See all wine results
        </a>
      </div>
    </div>
    <div class="result-block">
      <span class="label">Suggested Wine Titles:</span>
      <div class="result result--suggest">
        <ng-container *ngIf="wineTitles?.length && !autocompleteLoading">
          <ng-container *ngFor="let item of wineTitles">
            <a href="#" class="result-link">
              <span
                [innerHTML]="item | coincidenceBold : inputValue"
                (click)="handleLinkClick($event, item)"
              ></span>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!wineTitles?.length && !autocompleteLoading">
          <span>No information is available.</span>
        </ng-container>
        <ng-container *ngIf="autocompleteLoading">
          <ngx-skeleton-loader
            appearance="line"
            *ngFor="let item of arrayForSkeleton"
          ></ngx-skeleton-loader>
        </ng-container>
      </div>
    </div>
    <div class="result-block">
      <span class="label">Top Searched Queries for the Last 30 Days:</span>
      <div class="result result--queries">
        <app-header-search-query
          *ngFor="let query of topQueriesItems"
          [urlPrefix]="urlPrefix"
          [color]="query.color"
          [text]="query.text"
        ></app-header-search-query>
      </div>
    </div>
  </div>
</div>
