import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';

import { UserLocationData } from '@interfaces';
import { UserLocationService } from '@services/app/user-location.service';
import { AppState, getUserLocation } from '@store';

@Component({
  selector: 'app-region-resolver',
  template: `
    <div class="resolver-wrapper">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class RegionResolverComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private userLocationService: UserLocationService
  ) {
    this.activatedRoute.paramMap
      .pipe(switchMap(() => this.store.select(getUserLocation)))
      .subscribe((data: UserLocationData) => {
        this.userLocationService.getUrlPrefix(data);
      });
  }
}
