import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-api-success-dialog',
  templateUrl: './api-success-dialog.component.html',
  styleUrls: ['./api-success-dialog.component.scss'],
})
export class ApiSuccessDialogComponent {
  @Input() dataToPass: { text: string; title: string };

  @Output() dialogDone: EventEmitter<unknown> = new EventEmitter<unknown>();
}
