import { MenuData } from '@services/menu.service';

export const initialMenuData: MenuData = {
  surpriseMe: [],
  popularGrapes: [],
  popularRegions: [],
  grapes: [],
  regions: [],
  topQueries: [],
};
