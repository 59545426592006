import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit, Optional,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
})
export class ModalWindowComponent implements OnInit, OnDestroy {
  /* eslint-disable */
  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef | undefined ;

  componentRef: ComponentRef<any> | undefined;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ModalWindowComponent>,
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef?.createComponent<any>(factory);
    if (this.componentRef?.instance) {
      this.componentRef.instance.dataToPass = this.data.dataToPass;
      if (this.componentRef.instance.dialogDone) {
        this.componentRef.instance.dialogDone.subscribe((msg: any) => {
          this.dialogRef.close(msg);
        });
      }
    }
  }
  /* eslint-enable */

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
