import { forEach, isArray, omit } from 'lodash';

import { WINE_ARRAY_VALUES, WINE_BOOLEAN_VALUES, WINE_NUMBER_VALUES } from '@constants';
import { FilterOptionValue } from '@interfaces';

import { replaceStringForQuery } from './string.utils';

export const removeEmptyValues = (obj: any) => {
  for (const propName in obj) {
    if (!obj[propName] || obj[propName].length === 0) {
      delete obj[propName];
    } else if (typeof obj[propName] === 'object') {
      removeEmptyValues(obj[propName]);
    }
  }
  return obj;
};

export const getQueryParams = (query: string) => {
  const params = {};
  new URLSearchParams(query).forEach((value, key) => {
    let decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);
    // This key is part of an array
    if (decodedKey.endsWith('[]')) {
      decodedKey = decodedKey.replace('[]', '');
      // @ts-ignore
      params[decodedKey] || (params[decodedKey] = []);
      // @ts-ignore
      params[decodedKey].push(decodedValue);
      // Just a regular parameter
    } else {
      // @ts-ignore
      params[decodedKey] = decodedValue;
    }
  });

  return params;
};

export const getFilterRow = (object: FilterOptionValue): string => {
  return `${object.param}${object.rule === 'asc' ? '' : '-desc'}`;
};

export const filterRowToObject = (row: string): FilterOptionValue | null => {
  return row && row.length
    ? { rule: row.includes('desc') ? 'desc' : 'asc', param: row.replace('-desc', '') }
    : null;
};

export const mapWineFiltersValues = (data: any) => {
  forEach(data, (value, key) => {
    if (isArray(value) && (value as Array<unknown>).every((item: any) => !item)) {
      data = omit(data, [key]);
    } else if (!isArray(value) && WINE_ARRAY_VALUES.includes(key)) {
      data = { ...data, [key]: [replaceStringForQuery(value)] };
    }

    if (WINE_NUMBER_VALUES.includes(key) && typeof value !== 'number') {
      data = { ...data, [key]: +(value as string) };
    }

    if (WINE_BOOLEAN_VALUES.includes(key)) {
      data = { ...data, [key]: Boolean(value) };
    }
  });

  return data;
};
