import { Component, EventEmitter, Input, Output } from '@angular/core';

interface LoginDialog {
  urlAfterLogin?: string;
}
@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
  @Input() dataToPass: LoginDialog = {
    urlAfterLogin: undefined,
  };
  @Output() dialogDone: EventEmitter<unknown> = new EventEmitter<unknown>();

  closeSelf(): void {
    this.dialogDone.emit();
  }
}
