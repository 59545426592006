import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '@services/user.service';
import { getUserProfile } from '@utils';
import { ConfirmDialogComponent } from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ModalWindowComponent } from 'src/app/components/modal-window/modal-window.component';
import { LoginDialogComponent } from 'src/app/modules/user-flow/components/login-dialog/login-dialog.component';

@Component({
  selector: 'app-add-favorite',
  templateUrl: './add-favorite.component.html',
  styleUrls: ['./add-favorite.component.scss'],
})
export class AddFavoriteComponent implements OnInit {
  @Input() wineId: string;

  @Input() isFavorite: boolean;

  @Output() favoriteChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  color: string;

  private _getUserProfile: Function = getUserProfile;

  constructor(private userService: UserService, private dialog: MatDialog) {}

  ngOnInit() {
    this.color = this.isFavorite ? 'red' : 'white';
  }

  onChangeFavorite($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this._getUserProfile()) {
      this.dialog.open(ModalWindowComponent, {
        panelClass: ['custom-dialog-container', 'confirm-dialog'],
        minWidth: 600,
        data: {
          title: 'Login',
          component: LoginDialogComponent,
        },
      });
      return;
    }

    this.changeColor();

    if (this.isFavorite) {
      const dialogRef = this.dialog.open(ModalWindowComponent, {
        panelClass: ['custom-dialog-container', 'confirm-dialog'],
        maxWidth: 500,
        data: {
          title: 'Are you sure you want to remove this item from the Favorites?',
          dataToPass: {
            confirmBtn: 'Remove',
            cancelBtn: 'Cancel',
          },
          component: ConfirmDialogComponent,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'confirm') {
          this.userService.deleteFromFavorite(this.wineId).subscribe(() => {
            this.changeFavorite();
          });
        }
      });
    } else {
      this.userService.addWineToFavorites(this.wineId).subscribe(() => {
        this.changeFavorite();
      });
    }
  }

  private changeFavorite(): void {
    this.isFavorite = !this.isFavorite;
    this.favoriteChanged.emit(this.isFavorite);
    this.changeColor();
  }

  private changeColor(): void {
    this.color = this.isFavorite ? 'red' : 'white';
  }
}
