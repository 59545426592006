import { Options } from '@angular-slider/ngx-slider';

import { WINE_FILTER_RANGE_RANGER, WineFilter, WineFilterOption } from '@interfaces';
import { PRICE_RANGE_MAX, PRICE_RANGE_OPTIONS } from 'src/app/constants/price-range.constant';

export const WINE_SORTING: WineFilterOption[] = [
  { label: 'Recommended', value: { rule: 'asc', param: 'recommended' } },
  { label: 'Price: High to Low', value: { rule: 'desc', param: 'price' } },
  { label: 'Price: Low to High', value: { rule: 'asc', param: 'price' } },
  { label: 'Most popular', value: { rule: 'desc', param: 'rating_count' } },
  { label: 'Highest rating', value: { rule: 'desc', param: 'rating' } },
];

export const WINE_FILTER_TYPE_LIST = 'list';
export const WINE_FILTER_TYPE_RANGE = 'range';
export const WINE_FILTER_TYPE_STARS = 'stars';
export const WINE_FILTER_TYPE_CHECKBOX = 'checkbox';
export const WINE_FILTER_TYPE_SWITCH = 'switch';

const YEAR_RANGE_MIN = 1980;
const YEAR_RANGE_MAX = new Date().getFullYear();

export const YEAR_RANGE_OPTIONS: Options = {
  floor: YEAR_RANGE_MIN,
  ceil: YEAR_RANGE_MAX,
  hideLimitLabels: true,
  noSwitching: true,
};

export const WINE_FILTERS: WineFilter[] = [
  {
    key: 'wine_colors',
    name: 'Wine Color',
    type: WINE_FILTER_TYPE_LIST,
    items: [
      { name: 'Red', value: 'Red' },
      { name: 'White', value: 'White' },
      { name: 'Rosé', value: 'Rose' },
    ],
  },
  {
    key: 'wine_types',
    name: 'Wine Type',
    type: WINE_FILTER_TYPE_LIST,
    items: [
      { name: 'Still', value: 'Still' },
      { name: 'Sparkling', value: 'Sparkling' },
      { name: 'Fortified', value: 'Fortified' },
      { name: 'Dessert', value: 'Dessert' },
    ],
  },
  {
    key: 'is_natural',
    name: 'Only Natural Wines',
    type: WINE_FILTER_TYPE_SWITCH,
    selected: false,
    items: [],
  },
  {
    key: 'countries',
    name: 'Country',
    type: WINE_FILTER_TYPE_LIST,
    items: [],
    search: true,
    searchPlaceholder: 'Search for countries',
  },
  {
    key: 'regions',
    name: 'Regions',
    type: WINE_FILTER_TYPE_LIST,
    items: [],
    search: true,
    searchPlaceholder: 'Search for regions',
  },
  {
    key: 'grapes',
    name: 'Grape Variety',
    switcher_key: 'is_blended',
    switcher: [
      {
        key: 'single-variety',
        title: 'Single variety',
        value: false,
      },
      {
        key: 'blended',
        title: 'Blended',
        value: true,
      },
    ],
    type: WINE_FILTER_TYPE_LIST,
    items: [],
    search: true,
    searchPlaceholder: 'Search for grape names',
  },
  {
    key: 'prices',
    name: 'Price Range',
    type: WINE_FILTER_TYPE_RANGE,
    range: true,
    rangeType: WINE_FILTER_RANGE_RANGER,
    withInputs: true,
    minKey: 'price_min',
    maxKey: 'price_max',
    initValueMin: 1,
    initValueMax: PRICE_RANGE_MAX,
    filterRangeOptions: PRICE_RANGE_OPTIONS,
  },
  {
    key: 'vintage_years',
    name: 'Wine Vintage',
    type: WINE_FILTER_TYPE_RANGE,
    range: true,
    rangeType: WINE_FILTER_RANGE_RANGER,
    minKey: 'vintage_year_min',
    maxKey: 'vintage_year_max',
    filterRangeOptions: YEAR_RANGE_OPTIONS,
    initValueMin: 1980,
    initValueMax: new Date().getFullYear(),
  },
  {
    key: 'user_rating',
    name: 'User Rating',
    type: WINE_FILTER_TYPE_STARS,
    items: [
      { name: '', value: '4' },
      { name: '', value: '3' },
      { name: '', value: '2' },
      { name: '', value: '1' },
    ],
  },
  {
    key: 'expert_rating',
    name: 'Expert Rating',
    type: WINE_FILTER_TYPE_STARS,
    color: 'red',
    items: [
      { name: '', value: '4' },
      { name: '', value: '3' },
      { name: '', value: '2' },
      { name: '', value: '1' },
    ],
  },
  {
    key: 'foods',
    name: 'Food Pairings',
    type: WINE_FILTER_TYPE_LIST,
    items: [],
    search: true,
    searchPlaceholder: 'Search for food pairings',
  },
  {
    key: 'wine_styles',
    name: 'Wine Styles',
    type: WINE_FILTER_TYPE_LIST,
    items: [],
    search: true,
    searchPlaceholder: 'Search for wine styles',
  },
  {
    key: 'flavors',
    name: 'Taste Flavors',
    type: WINE_FILTER_TYPE_LIST,
    items: [],
    search: true,
    searchPlaceholder: 'Search for taste flavors',
  },
  {
    key: 'taste_profiles',
    name: 'Wine Taste Profile',
    type: WINE_FILTER_TYPE_CHECKBOX,
    items: [
      { name: 'Dry', value: 'Dry' },
      { name: 'Sweet', value: 'Sweet' },
      { name: 'Low Alcohol', value: 'Low Alcohol' },
      { name: 'Aromatic', value: 'Aromatic' },
      { name: 'Delicate', value: 'Delicate' },
      { name: 'Bold', value: 'Bold' },
      { name: 'Refreshing', value: 'Refreshing' },
      { name: 'Smooth', value: 'Smooth' },
    ],
  },
  {
    key: 'bottle_volumes',
    name: 'Size',
    type: WINE_FILTER_TYPE_CHECKBOX,
    items: [],
  },
];
