import { Action } from '@ngrx/store';

import { MenuData } from '@services/menu.service';
export const UPDATE_MENU_DATA = '[Menu] Update Menu Data';
export class UpdateMenuData implements Action {
  public readonly type = UPDATE_MENU_DATA;

  constructor(public payload: MenuData) {}
}

export type MenuDataActions = UpdateMenuData;
