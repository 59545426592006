<header class="header">
  <div class="header-wrapper">
    <div class="top-layer">
      <div class="main">
        <div class="top-layer__items">
          <ng-container *ngFor="let key of objectKeys(topLayerItems)">
            <ng-container *ngIf="!topLayerItems[key].hidden">
              <div
                class="top-layer__items--item"
                clickOutside
                (onClickOutside)="menuItemClickedOutside(key)"
              >
                <div class="item-layout" (click)="topLayerItems[key].opened = true">
                  <i class="icon icon--{{ topLayerItems[key].icon }}"></i>
                  <ng-container
                    *ngIf="!(userLocationService.locationLoading$ | async); else topItemLoading"
                  >
                    <span class="item-label">{{ topLayerItems[key].label }}</span>
                    <i
                      class="icon icon--down-arrow"
                      *ngIf="(topLayerItems[key].items | async)?.length"
                    ></i>
                  </ng-container>
                  <ng-template #topItemLoading>
                    <ngx-skeleton-loader
                      appearance="line"
                      [theme]="headerDataLoaderTheme"
                    ></ngx-skeleton-loader>
                  </ng-template>
                </div>
                <div class="user-menu-top" *ngIf="topLayerItems[key].opened">
                  <input
                    type="text"
                    *ngIf="topLayerItems[key].search"
                    [formControl]="searchControl"
                    class="search-input"
                    placeholder="Search..."
                  />
                  <ng-container
                    *ngFor="
                      let item of topLayerItems[key].items
                        | async
                        | headerFilter : searchControl.value
                    "
                  >
                    <span class="menu-item" (click)="handleSubMenuClick(key, item)">
                      {{ item.name }}
                    </span>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="user-actions" *ngIf="!user">
            <a
              class="action"
              title="login"
              [routerLink]="[urlPrefix, 'user', 'login']"
              *ngIf="!user"
            >
              <span>Login</span>
              <i class="header-icon header-icon--login"></i>
            </a>
          </div>
          <div *ngIf="user" class="menu-container" clickOutside (onClickOutside)="clickedOutside()">
            <div class="menu-toggler">
              <div class="menu-toggler--title" (click)="toggleMenu($event)">
                <div class="header-icon header-icon--profile"></div>
              </div>
              <div class="user-menu-top" *ngIf="isMenuOpen">
                <a
                  [routerLink]="[urlPrefix, 'recommendations']"
                  class="menu-item"
                  (click)="toggleMenu($event)"
                >
                  Wine search
                </a>
                <ng-container *ngFor="let item of userMenu">
                  <a
                    [routerLink]="[urlPrefix, 'profile', item.path]"
                    class="menu-item"
                    (click)="toggleMenu($event)"
                  >
                    {{ item.title }}
                  </a>
                </ng-container>
                <div class="menu-item" (click)="$event.preventDefault(); logout()">Log Out</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-menu">
      <div class="main" #mainWrapper>
        <div class="second-layer">
          <div
            class="hamburger-lines"
            [class.opened]="isMobileMenuOpen"
            (click)="mobileToggleMenu()"
          >
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
          <a
            id="header-main-logo"
            [routerLink]="[urlPrefix]"
            (click)="isMobileMenuOpen && mobileToggleMenu()"
          >
            <img class="main-logo" src="assets/vinovoss-logo.webp" alt="app logo" />
          </a>
          <div class="mobile-actions">
            <div class="user-actions">
              <a
                class="action"
                title="login"
                [routerLink]="[urlPrefix, 'user', 'login']"
                *ngIf="!user"
              >
                <span>Login</span>
                <i class="header-icon header-icon--login header-icon--login--black"></i>
              </a>
            </div>
            <div
              *ngIf="user"
              class="menu-container"
              clickOutside
              (onClickOutside)="clickedOutside()"
            >
              <div class="menu-toggler">
                <div
                  class="menu-toggler--title"
                  (click)="$event.stopPropagation(); toggleMenu($event)"
                >
                  <div class="header-icon header-icon--profile header-icon--profile--black"></div>
                </div>
                <div class="user-menu" *ngIf="isMenuOpen">
                  <a
                    [routerLink]="[urlPrefix, 'recommendations']"
                    class="menu-item"
                    (click)="toggleMenu($event)"
                  >
                    Wine search
                  </a>
                  <ng-container *ngFor="let item of userMenu">
                    <a
                      [routerLink]="[urlPrefix, 'profile', item.path]"
                      class="menu-item"
                      (click)="toggleMenu($event)"
                    >
                      {{ item.title }}
                    </a>
                  </ng-container>
                  <div class="menu-item" (click)="$event.preventDefault(); logout()">Log Out</div>
                </div>
              </div>
            </div>
          </div>
          <ul class="menu" role="menu">
            <ng-container *ngFor="let item of mainMenuItems; let i = index">
              <li
                *ngIf="!item.hidden"
                role="menuitem"
                class="header-menu {{ item.additionalData?.classList?.join(' ') }}"
                hover-class="active"
                [id]="item.additionalData?.id || ''"
                (click)="onMenuClick($event, false, item)"
              >
                <span class="active-menu" (click)="closeSubMenu($event, !item.menuItems?.length)">
                  {{ item.name }}
                </span>
                <div class="menu-info" *ngIf="item.menuItems?.length">
                  <div class="menu-info-container">
                    <div class="menu-info-container-wrapper">
                      <div class="menu-info-container--left">
                        <div class="content">
                          <ul class="content__menu">
                            <li
                              *ngFor="let subMenuItem of item.menuItems"
                              class="content__menu--item"
                            >
                              {{ subMenuItem.name }}
                              <ul class="content-submenu">
                                <a
                                  [href]="menuLinkClick(subSubMenuItem)"
                                  *ngFor="let subSubMenuItem of subMenuItem.menuItems"
                                >
                                  <li>{{ subSubMenuItem.name }}</li>
                                </a>
                              </ul>
                            </li>
                          </ul>
                          <button class="btn btn--link btn--link__red" *ngIf="false">
                            {{ item.allButton?.name || 'All Items' }}
                          </button>
                        </div>
                      </div>
                      <div class="menu-info-container--right">
                        <div class="content">
                          <span class="content__name">{{ item.additionalData?.searchTitle }}:</span>
                          <ng-container [ngSwitch]="item.key">
                            <div *ngSwitchCase="'wine-discovery'" class="search-query-wrapper">
                              <ng-container
                                *ngIf="
                                  item.additionalData!.searchItems.length;
                                  else loadingTopQueries
                                "
                              >
                                <app-header-search-query
                                  *ngFor="let query of item.additionalData!.searchItems"
                                  [urlPrefix]="urlPrefix"
                                  [color]="query.color"
                                  [text]="query.text"
                                  (onClick)="isMobileMenuOpen && mobileToggleMenu()"
                                ></app-header-search-query>
                              </ng-container>
                              <ng-template #loadingTopQueries>
                                <ngx-skeleton-loader
                                  *ngFor="let item of popularQuerySkeletonItems"
                                  appearance="line"
                                  [theme]="item"
                                ></ngx-skeleton-loader>
                              </ng-template>
                            </div>
                            <div *ngSwitchCase="'grape-varieties'">
                              <a
                                [routerLink]="[urlPrefix, 'grapes', grape.slug]"
                                (click)="isMobileMenuOpen && mobileToggleMenu()"
                                class="grape"
                                *ngFor="let grape of dayGrapes"
                              >
                                <img
                                  class="grape__image"
                                  alt="Fresh {{ grape.name }} grapes on the vine"
                                  [src]="grape.image.card"
                                  width="100"
                                  height="88"
                                />
                                <div class="grape__content">
                                  <div class="grape__content--title">{{ grape.name }}</div>
                                  <div class="grape__content--description">
                                    <p>{{ grape.description }}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div *ngSwitchCase="'regions'">
                              <div class="regions">
                                <a
                                  class="region"
                                  [routerLink]="[urlPrefix, 'regions', region.slug]"
                                  (click)="isMobileMenuOpen && mobileToggleMenu()"
                                  *ngFor="let region of dayRegions"
                                >
                                  <img
                                    class="region__image"
                                    alt="region"
                                    [src]="region.image.main"
                                  />
                                  <span class="region__name">{{ region.region_name }}</span>
                                </a>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>

            <div class="mobile-lists">
              <ng-container *ngFor="let key of objectKeys(topLayerItems)">
                <div *ngIf="!topLayerItems[key].hidden">
                  <div
                    class="mobile-lists--item"
                    [class.opened]="topLayerItems[key].opened"
                    (click)="topLayerItems[key].opened = !topLayerItems[key].opened"
                  >
                    <i class="icon icon--{{ topLayerItems[key].icon }}"></i>
                    <span class="item-label">{{ topLayerItems[key].label }}</span>
                    <i
                      class="icon icon--down-arrow"
                      *ngIf="(topLayerItems[key].items | async)?.length"
                    ></i>
                  </div>
                  <div class="user-menu-top" *ngIf="topLayerItems[key].opened">
                    <input
                      type="text"
                      [formControl]="searchControl"
                      class="search-input"
                      placeholder="Search..."
                    />
                    <ng-container
                      *ngFor="
                        let item of topLayerItems[key].items
                          | async
                          | headerFilter : searchControl.value
                      "
                    >
                      <span class="menu-item" (click)="handleSubMenuClick(key, item)">
                        {{ item.name }}
                      </span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
