import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-embedded-wine-card-holder',
  template: `
    <app-wine-card [wine]="wine" [embeddedComponent]="true"></app-wine-card>
  `,
  styleUrls: ['./embedded-wine-card.component.scss'],
})
export class EmbeddedWineCardComponent implements OnInit {
  wine: any;
  constructor(private activatedRoute: ActivatedRoute) {}
  ngOnInit() {
    this.wine = this.activatedRoute.snapshot.data['wine'];
  }
}
