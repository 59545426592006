import { makeStateKey } from '@angular/platform-browser';

import {
  BlogArticle,
  BlogPost,
  BlogPostRes,
  IVintageResponse,
  WineGrape,
  WineRegion,
  WineResponse,
  Winery,
  WinesTransferState,
} from '@interfaces';
import { MenuData } from '@services/menu.service';

export const REGION_KEY = (slug: string) => makeStateKey<WineRegion>('region-' + slug);
export const GRAPE_KEY = (name: string) => makeStateKey<WineGrape>('grape-' + name.toLowerCase());
export const WINERY_KEY = (slug: string) => makeStateKey<Winery>('winery-' + slug);
export const BLOG_ARTICLE_KEY = (id: string) => makeStateKey<BlogArticle>('article-' + id);
export const WINE_PAGE_KEY = (slug: string) => makeStateKey<IVintageResponse>('wine-' + slug);
export const EMBEDDED_WINE_PAGE_KEY = (slug: string) =>
  makeStateKey<WineResponse>('embedded-wine-' + slug);
export const WINE_PAGE_DATA_KEY = (slug: string) => makeStateKey<any>('data-wine-' + slug);
export const HOME_BLOGS_KEY = makeStateKey<BlogPost[]>('home-latest-blogs');
export const HOME_PROMO_WINES_KEY = makeStateKey<WineResponse[]>('home-promo-wines');
export const BLOG_ARTICLE_LIST_KEY = makeStateKey<BlogPostRes>('articles-list');
export const MENU_DATA_KEY = makeStateKey<MenuData>('menu-data');
export const WINE_RECOMMENDATIONS_KEY = makeStateKey<WinesTransferState>('wine-recommendations');
export const WINE_RECOMMENDATIONS_FILTER_DATA_KEY = makeStateKey<WinesTransferState>(
  'wine-recommendations-filter-data'
);
