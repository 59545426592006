import { Component } from '@angular/core';

@Component({
  selector: 'app-user-root',
  template: `
    <div class="user-root">
      <app-wine-search></app-wine-search>
      <div class="main main--initial">
        <app-breadcrumbs [hideHomePage]="true"></app-breadcrumbs>
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styles: [
    `
      app-breadcrumbs {
        display: block;
        padding-top: 62px;
      }

      .user-root {
        position: relative;
      }
    `,
  ],
})
export class UserRootComponent {}
