import { NgModule } from '@angular/core';

import { CanExpandDirective } from '@shared/directives/can-expand.directive';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { DndDirective } from '@shared/directives/dnd.directive';
import { HoverClassDirective } from '@shared/directives/hover-class.directive';
import { ShowPasswordDirective } from '@shared/directives/show-password.directive';

@NgModule({
  declarations: [
    ShowPasswordDirective,
    HoverClassDirective,
    ClickOutsideDirective,
    DndDirective,
    CanExpandDirective,
  ],
  exports: [
    ShowPasswordDirective,
    HoverClassDirective,
    ClickOutsideDirective,
    DndDirective,
    CanExpandDirective,
  ],
})
export class DirectivesModule {}
