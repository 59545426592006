import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmbeddedWineCardComponent } from '@modules/embedded/embedded-wine-card/embedded-wine-card.component';

import { EmbeddedWineCardDeactivateGuard } from './embedded-wine-card-deactivate.guard';
import { EmbeddedWineCardResolverService } from './embedded-wine-card-resolver.service';
import { SEO_PAGE_DATA, WINE_PAGE_RESOLVER } from '@constants';

const routes: Routes = [
  {
    path: '',
    component: EmbeddedWineCardComponent,
    resolve: {
      [WINE_PAGE_RESOLVER]: EmbeddedWineCardResolverService,
    },
    data: {
      breadcrumbUrl: null,
      seo: SEO_PAGE_DATA[WINE_PAGE_RESOLVER],
    },
    canDeactivate: [EmbeddedWineCardDeactivateGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [EmbeddedWineCardResolverService],
  exports: [RouterModule],
})
export class EmbeddedWineCardRoutingModule {}
