import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AddWishListData, Wishlist } from '@interfaces';
import { WishListService } from '@services/wish-list.service';

@Component({
  selector: 'app-add-wishlist-dialog',
  templateUrl: './add-wishlist-dialog.component.html',
  styles: [
    `
      .text {
        display: block;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 25px;
        margin-top: -5px;

        @media screen and (max-width: 992px) {
          text-align: center;
        }
      }
      .form {
        padding-bottom: 15px;
      }

      .btn {
        padding: 11px 30px;
      }
    `,
  ],
})
export class AddWishlistDialogComponent {
  @Input() dataToPass: AddWishListData;

  @Output() dialogDone: EventEmitter<{ wishlist: Wishlist; event: string }> = new EventEmitter<{
    wishlist: Wishlist;
    event: string;
  }>();

  wishlistName: FormControl = new FormControl('', [Validators.required]);

  constructor(private wishlistService: WishListService) {
    //
  }

  onComplete() {
    if (this.dataToPass.wishlist) {
      this.wishlistService
        .editWishlistName(this.dataToPass.wishlist.name, this.wishlistName.value)
        .subscribe({
          next: () => {
            this.dialogDone.emit({
              wishlist: {
                ...this.dataToPass.wishlist,
                name: this.wishlistName.value,
              } as Wishlist,
              event: 'api_success',
            });
          },
          error: (err) => {
            if (err.status === 417) {
              setTimeout(() => {
                this.wishlistName.setErrors({ exist: err.error.detail });
              });
            }
          },
        });

      return;
    }

    if (this.wishlistName.invalid) {
      return;
    }

    this.wishlistService.createWishlist(this.wishlistName.value).subscribe({
      next: () => {
        this.dialogDone.emit({
          wishlist: {
            name: this.wishlistName.value,
            vintage_count: 0,
          },
          event: 'api_success',
        });
      },
      error: (err) => {
        if (err.status === 417) {
          setTimeout(() => {
            this.wishlistName.setErrors({ exist: err.error.detail });
          });
        }
      },
    });
  }
}
