import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private jsonLd: any = {};
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords });
  }

  setJsonLd(rawData: any) {
    this.jsonLd = rawData;
  }

  toJson(): string {
    return JSON.stringify(this.jsonLd);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({
      name: 'og:url',
      property: 'og:url',
      content: url,
    });
  }

  updateOgTitle(ogTitle: string) {
    this.meta.updateTag({
      name: 'og:title',
      property: 'og:title',
      content: ogTitle,
    });
  }

  updateOgDescription(ogDesc: string) {
    this.meta.updateTag({
      name: 'og:description',
      property: 'og:description',
      content: ogDesc,
    });
  }

  updateOgImage(ogImg: string) {
    this.meta.updateTag({
      name: 'og:image',
      property: 'og:image',
      content: ogImg,
    });
  }

  updateFollow(index = 'index', follow = 'follow') {
    this.meta.updateTag({
      name: 'robots',
      property: 'robots',
      content: `${index}, ${follow}`,
    });
  }

  updateCanonicalLink(newCanonicalUrl: string) {
    const canonicalTag = this.meta.getTag('rel="canonical"');
    if (canonicalTag) {
      this.meta.updateTag({ rel: 'canonical', href: newCanonicalUrl });
    } else {
      this.meta.addTag({ rel: 'canonical', href: newCanonicalUrl });
    }
  }
}
