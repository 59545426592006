import { Component } from '@angular/core';

import { TopBannerService } from '@services/app/top-banner.service';

@Component({
  selector: 'app-recommendation-root',
  template: `
    <div class="recommendation-root">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .recommendation-root {
        position: relative;
        height: calc(100% - var(--recommendation-footer-height));
      }
    `,
  ],
})
export class WineRecommendationRootComponent {
  topBannerImage = '';

  constructor(private topBannerService: TopBannerService) {
    this.topBannerService.topBannerImage$.subscribe((imageName) => {
      this.topBannerImage = imageName;
    });
  }
}
