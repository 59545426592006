export const MONTH: { id: string; value: string }[] = [
  { id: '01', value: '01' },
  { id: '02', value: '02' },
  { id: '03', value: '03' },
  { id: '04', value: '04' },
  { id: '05', value: '05' },
  { id: '06', value: '06' },
  { id: '07', value: '07' },
  { id: '08', value: '08' },
  { id: '09', value: '09' },
  { id: '10', value: '10' },
  { id: '11', value: '11' },
  { id: '12', value: '12' },
];

export const DAY: { id: string; value: string }[] = [];

export const YEARS: { id: string; value: string }[] = [];

export const GENDER: { id: string; value: string; label: string }[] = [
  { id: 'male', value: 'Male', label: 'Male' },
  { id: 'female', value: 'Female', label: 'Female' },
  { id: 'other', value: 'Others', label: 'Others' },
  { id: 'not-answer', value: 'Not Specified', label: 'Prefer not to answer' },
];
