import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { BlogSharedModule } from '@modules/blog/blog.shared.module';
import { EmbeddedWineCardModule } from '@modules/embedded/embedded-wine-card/embedded-wine-card.module';
import { RegionResolverModule } from '@modules/region/region.module';
import { GoogleCallbackComponent } from '@pages/google-callback/google-callback.component';
import { HomeComponent } from '@pages/home/home.component';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { VerificationComponent } from '@pages/verification/verification.component';
import { AuthInterceptor } from '@services/interceptors/auth.interceptor';
import { CustomUrlSerializer } from '@services/custom-url.serializer';
import { HttpLoaderInterceptor } from '@services/interceptors/http-loader.interceptor';
import { HttpServerErrorInterceptor } from '@services/interceptors/http-server-error.interceptor';
import { NotFoundInterceptor } from '@services/interceptors/not-found.interceptor';
import { SharedModule } from '@shared/shared.module';
import { appReducers } from 'src/app/store/reducers/app.reducers';
import { translateLoaderFactory } from 'src/app/utils/factories/translate-loader-factory';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangeWishlistDialogComponent } from './components/add-wishlist/change-wishlist-dialog/change-wishlist-dialog.component';
import { DragAndDropDialogComponent } from './components/dialogs/drag-and-drop-dialog/drag-and-drop-dialog.component';
import { ReportQualityDialogComponent } from './components/dialogs/report-quality-dialog/report-quality-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderModule } from './components/header/header.module';
import { DOCUMENT } from '@angular/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 3000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
  },
};

const WINDOW_FACTORY = () => {
  const document = inject(DOCUMENT);
  return document.defaultView!;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VerificationComponent,
    GoogleCallbackComponent,
    ChangeWishlistDialogComponent,
    ReportQualityDialogComponent,
    FooterComponent,
    DragAndDropDialogComponent,
    PageNotFoundComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    FormsModule,
    AppRoutingModule,
    BlogSharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderModule,
    RegionResolverModule,
    EmbeddedWineCardModule,
    NgtUniversalModule,
    NotifierModule.withConfig(customNotifierOptions),
    StoreModule.forRoot(appReducers),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
