import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';

export interface MenuData {
  surpriseMe: string[];
  popularGrapes: string[];
  popularRegions: string[];
  grapes: DayGrape[];
  regions: DayRegion[];
  topQueries: string[];
}

export interface DayGrape {
  name: string;
  slug: string;
  image: {
    banner: string;
    main: string;
    card: string;
  };
  description: string;
}

export interface DayRegion {
  id: string;
  country: string;
  region_name: string;
  slug: string;
  sub_region_name: string;
  description: string;
  image: {
    banner: string;
    main: string;
    location: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  getMenuData(count = 6, country = '', state = ''): Observable<MenuData> {
    return combineLatest([
      this.menuSurpriseMe(count, country, state),
      this.menuPopularGrapes(count, country, state),
      this.menuPopularRegions(count),
      this.getGrapeOfDay(2, country, state),
      this.getRegionOfDay(2, country, state),
      this.getTopSearchQueries(4, country, state),
    ]).pipe(
      map(([surpriseMe, popularGrapes, popularRegions, grapes, regions, topQueries]) => {
        return {
          surpriseMe,
          popularGrapes,
          popularRegions,
          grapes,
          regions,
          topQueries,
        };
      })
    );
  }

  private getGrapeOfDay(count = 2, country = '', state = ''): Observable<DayGrape[]> {
    return this.http.get<DayGrape[]>(
      `${environment.API_URL}/menu/grapes_of_the_day?k=${count}&market_country=${country}&market_region=${state}`
    );
  }

  private getRegionOfDay(count = 2, country = '', state = ''): Observable<DayRegion[]> {
    return this.http.get<DayRegion[]>(
      `${environment.API_URL}/menu/regions_of_the_day?k=${count}&market_country=${country}&market_region=${state}`
    );
  }

  private menuSurpriseMe(count = 6, country = '', state = ''): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.API_URL}/menu/surprise_me?k=${count}&market_country=${country}&market_region=${state}`
    );
  }

  private menuPopularGrapes(count = 6, country = '', state = ''): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.API_URL}/menu/popular_grapes?k=${count}&market_country=${country}&market_region=${state}`
    );
  }

  private menuPopularRegions(count = 6): Observable<string[]> {
    return this.http.get<string[]>(`${environment.API_URL}/menu/popular_regions?k=${count}`);
  }

  public getTopSearchQueries(count = 4, country = '', state = ''): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.API_URL}/menu/top_searched_queries?k=${count}&market_country=${country}&market_region=${state}`
    );
  }
}
