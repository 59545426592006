import { Pipe, PipeTransform } from '@angular/core';

import { StateItem } from '@interfaces';

@Pipe({
  name: 'headerFilter',
})
export class HeaderFilterPipe implements PipeTransform {
  transform(value: StateItem[] | null, filterBy: string): StateItem[] {
    if (!value) {
      return [];
    }

    return value.filter((item) => item.name.toLowerCase().includes(filterBy.toLowerCase()));
  }
}
