<div class="selectors">
  <button
    class="selectors--item"
    *ngFor="let item of filters"
    (click)="onSelect(item)"
    [class.active]="selectedItem === item"
  >
    {{ item }}
  </button>
</div>
