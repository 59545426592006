import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { LoginDialogComponent } from '@modules/user-flow/components/login-dialog/login-dialog.component';
import { getUserProfile } from '@utils';

import { ModalWindowComponent } from '../components/modal-window/modal-window.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private sharedRoutes: string[] = [
    'browser-history',
    'wishlist',
    'favorites',
    'user-wine-celler',
    'handpick-for-you',
  ];
  private sharedTokens: string[] = ['wishlist_token', 'token'];
  private showAuthPopup: string[] = ['profile/handpick-for-you'];
  private isBrowser;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return getUserProfile() ||
      (this.sharedTokens.some((key) => !!route.queryParams[key]) &&
        this.sharedRoutes.some((key) => state.url.includes(`profile/${key}`)))
      ? true
      : this.showAuthPopup.some((key) => state.url.includes(key))
      ? this.showAuthPopupDialog()
      : this.router.parseUrl('/user/login');
  }

  private showAuthPopupDialog(): boolean | UrlTree {
    if (this.isBrowser) {
      if (this.showAuthPopup.some((key) => window.location.pathname.includes(key))) {
        return this.router.parseUrl('');
      }

      this.dialog.open(ModalWindowComponent, {
        panelClass: ['custom-dialog-container', 'confirm-dialog'],
        minWidth: 600,
        data: {
          title: 'Login',
          dataToPass: {
            urlAfterLogin: '/profile/handpick-for-you',
          },
          component: LoginDialogComponent,
        },
      });

      return false;
    }

    return true;
  }
}
