import { BLOG_POST_ITEMS_PER_PAGE, NEWS_ARTICLES_PAGE, SEO_PAGE_DATA } from '@constants';
import { BlogPost } from '@interfaces';
import { MenuData } from '@services/menu.service';
import { getNavigationBarPart } from 'src/app/utils/json-LD/navigation-bar-part.jsonld.utils';

export const getArticleListPageJsonLD = (
  url: string,
  menuData: MenuData,
  articles: BlogPost[]
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    url: `${url}`,
    name: 'News & Articles',
    description: `${SEO_PAGE_DATA[NEWS_ARTICLES_PAGE].description}`,
    numberOfItems: `${BLOG_POST_ITEMS_PER_PAGE}`,
    itemListOrder: 'http://schema.org/ItemListOrderDescending',
    ...getNavigationBarPart(menuData.surpriseMe, [], menuData.grapes, menuData.regions),
    itemListElement: articles.map((article: BlogPost, index: number) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        url: `${url}/${article.slug}`,
      };
    }),
  };
};
