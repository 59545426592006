<div
  *ngIf="country"
  class="wine-country wine-country--{{
    country.replace(', ', '-').replace(' ', '-').toLocaleLowerCase()
  }} {{ classList.join('') }}"
  [ngStyle]="{ color: color }"
  [ngClass]="{ bold: bold }"
>
  {{ country }}
</div>
