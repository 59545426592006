import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from '@angular/router';
import { select, State, Store } from '@ngrx/store';
import { forEach, map } from 'lodash';
import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader';
import { filter, of, Subject, switchMap, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserMeuType, USER_MENU, USA_COUNTRY } from '@constants';
import { User, UserLocationData } from '@interfaces';
import { UserLocationService } from '@services/app/user-location.service';
import { AuthService } from '@services/auth.service';
import { DayGrape, DayRegion } from '@services/menu.service';
import { UserService } from '@services/user.service';
import { UpdateUserLocation } from '@store/actions';
import { getMenuData, getUserLocation, getUserLocationUrlPrefix } from '@store/selectors';
import { AppState } from '@store/state';
import { clearJWTToken, clearUserProfile } from '@utils';
import {
  menuItems,
  TopLayerItems,
  topLayerItems
} from 'src/app/components/header/header.definitions';
import { STATES_LIST } from 'src/app/constants/states.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('mainWrapper') mainMenuElement: ElementRef;

  user: User | null;
  userMenu: UserMeuType[] = USER_MENU;
  isMenuOpen = false;
  isMobileMenuOpen = false;
  dayGrapes: DayGrape[];
  dayRegions: DayRegion[];
  objectKeys = Object.keys;
  userLocationData: UserLocationData;
  handleLocationComplete = false;

  urlPrefix: string;

  searchControl: FormControl = new FormControl('');

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  get popularQuerySkeletonItems(): NgxSkeletonLoaderConfigTheme[] {
    return Array(4).fill({ height: '38px', marginBottom: '8px', width: '100%' }).map((item, i) => {
      return {
        ...item,
        backgroundColor: this.topSearchQueryColors[i],
      }
    })
  }

  get isMobile(): boolean {
    return window.innerWidth <= 992;
  }

  public readonly topLayerItems: TopLayerItems = topLayerItems;

  public readonly headerDataLoaderTheme = {
    height: '22px',
    margin: '0 6px 0 8px',
    backgroundColor: '#A0233A',
  };

  public readonly mainMenuItems = menuItems;

  private readonly topSearchQueryColors: string[] = ['#DA798A', '#EAE7BB', '#F8C27B', 'rgba(206, 96, 89, 0.8)'];

  private destroy$: Subject<void> = new Subject<void>();

  private innerWidth: number;
  private isBrowser: boolean;

  constructor(
    public userLocationService: UserLocationService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private store: Store<AppState>,
    private state: State<AppState>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.store.select(getUserLocationUrlPrefix).subscribe((prefix) => this.urlPrefix = prefix);

    this.userService.userSubject$.subscribe((user) => {
      this.user = user;
    });

    if (this.isBrowser) {
      this.store.pipe(
        tap(() => this.handleLocationComplete = false),
        select(getUserLocation),
        filter((userLocationData: UserLocationData) => !!userLocationData),
        tap(this.handleLocation),
        switchMap(() => this.store.select(getMenuData)),
        takeUntil(this.destroy$),
      ).subscribe(res => {
        // @ts-ignore
        this.mainMenuItems.find(menu => menu.key === 'wine-discovery')?.menuItems?.find(item => item.key === 'surprise-me').menuItems =
          res.surpriseMe.map((item: string) => {
            return {name: item};
          });

        this.mainMenuItems.find(menu => menu.key === 'wine-discovery').additionalData.searchItems =
          res.topQueries.map((item, index) => {
            return {text: item, color: this.topSearchQueryColors[index]};
          });

        // @ts-ignore
        this.mainMenuItems.find(menu => menu.key === 'grape-varieties')?.menuItems?.find(item => item.key! === 'popular-grapes').menuItems =
          res.popularGrapes.map((item: string) => {
            return {name: item};
          });

        // @ts-ignore
        this.mainMenuItems.find(menu => menu.key === 'regions')?.menuItems?.find(item => item.key! === 'popular-regions').menuItems =
          res.popularRegions.map((item: string) => {
            return {name: item};
          });

        this.dayGrapes = res.grapes;
        this.dayRegions = res.regions;
      });
    }
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      clearJWTToken();
      clearUserProfile();
      this.userService.userSubject$.next(null);
      window.location.reload();
    });
  }

  toggleMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
    forEach(this.objectKeys(topLayerItems), (item) => {
      this.menuItemClickedOutside(item);
    })
  }

  mobileToggleMenu(): void {
    if (this.isMobile) {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      this.document.body?.classList.toggle('scroll-disabled');

      if (!this.isMobileMenuOpen) {
        this.mainMenuElement.nativeElement?.classList.remove('opened');
        this.mainMenuElement.nativeElement.querySelector('ul.menu')?.classList.remove('opened');
        this.mainMenuElement.nativeElement.querySelector('ul.menu li.active')?.classList.remove('active');
      }
    }
  }

  onMenuClick(event: MouseEvent, nameClick = false, item: any): void {
    // @ts-ignore
    const eventTarget: HTMLElement | null = event.target;

    if (!item?.menuItems?.length) {
      if (item?.routerLink) {
        this.router.navigateByUrl(this.urlPrefix + item.routerLink);
        this.mobileToggleMenu();

        return;
      }
    }

    if (!nameClick) {
      eventTarget?.classList.toggle('active');
      eventTarget?.offsetParent?.closest('.menu')?.classList.toggle('opened');
      eventTarget?.offsetParent?.closest('.main')?.classList.toggle('opened');
    } else {
      eventTarget?.offsetParent?.classList.toggle('active');
    }

    if (this.isMobile) {
      map(this.topLayerItems, (item) => {
        item.opened = false;
        return item;
      });
    }
  }

  closeSubMenu(event: MouseEvent, single = false): void {
    if (single) {
      return;
    }

    event.preventDefault();
    // @ts-ignore
    const eventTarget: HTMLElement | null = event.target;

    if (eventTarget?.offsetParent?.classList.contains('active')) {
      eventTarget?.offsetParent?.classList.remove('active');
    } else {
      this.onMenuClick(event, true, null);
    }
  }

  clickedOutside(): void {
    this.isMenuOpen = false;
  }

  menuItemClickedOutside(key: string): void {
    if (this.isMobile) {
      return;
    }

    this.topLayerItems[key].opened = false;
    this.searchControl.setValue('');
  }

  handleSubMenuClick(key: string, item: any): void {
    switch (key) {
      case 'state':
        this.store.dispatch(new UpdateUserLocation({
          region: item.name.toLowerCase() === 'all' ? '' : item.name,
          url_prefix: this.userLocationService.getUrlPrefix({
            ...this.state.getValue().location,
            region: item.name.toLowerCase() === 'all' ? '' : item.name,
          }),
        }));
        this.topLayerItems['state'].label = item.name;
        break;
      case 'location':
        this.topLayerItems['location'].label = item.name;

        this.store.dispatch(new UpdateUserLocation({
          country: item.name,
          region: '',
          url_prefix: this.userLocationService.getUrlPrefix({
            ...this.state.getValue().location,
            country: item.name,
            country_code3: item.value,
            region: '',
          }),
        }));

        break;
    }

    this.topLayerItems[key].opened = false;
  }

  menuLinkClick(menuItem: { name: string, key?: string }): string {
    return this.router.createUrlTree([this.urlPrefix, 'recommendations'], {
      queryParams: {
        ...(!menuItem.key && {query: menuItem.name}),
        ...(menuItem.key && {['page-key']: menuItem.key}),
      },
    }).toString();
  }

  private handleLocation = (userLocationData: UserLocationData): void => {
    this.userLocationData = userLocationData;
    //TODO rewrite on state management
    this.topLayerItems['location'] = {...this.topLayerItems['location'], label: userLocationData.country};
    // this.topLayerItems['city'] = {...this.topLayerItems['city'], label: userLocationData.city};

    if (userLocationData.country === USA_COUNTRY) {
      let items = [
        {
          name: userLocationData.country,
          value: userLocationData.country,
        },
      ];

      if (userLocationData.initial_country !== USA_COUNTRY) {
        items.push({
          name: userLocationData.initial_country,
          value: userLocationData.initial_country,
        });
        this.topLayerItems['location'].label = userLocationData.country;
      } else {
        items = [];
      }

      this.topLayerItems['location'] = {
        ...this.topLayerItems['location'],
        label: userLocationData.initial_country !== USA_COUNTRY ? userLocationData.country : userLocationData.initial_country,
        items: of(items),
      };

      this.topLayerItems['state'] = {
        ...this.topLayerItems['state'],
        label: userLocationData.region || 'All',
        items: of(STATES_LIST),
        hidden: false,
      };
    } else {
      this.topLayerItems['location'] = {
        ...this.topLayerItems['location'],
        items: of([
          {
            name: userLocationData.country,
            value: userLocationData.country,
          },
          {
            name: USA_COUNTRY,
            value: USA_COUNTRY,
          }
        ]),
      };

      this.topLayerItems['state'] = {
        ...this.topLayerItems['state'],
        label: userLocationData.region,
        hidden: !(userLocationData.country === USA_COUNTRY),
      };
    }

    this.handleLocationComplete = true;
  }
}
