import { IVintageResponse, SimilarWineResponse } from '@interfaces';
import { MenuData } from '@services/menu.service';

import { getTextFirstSentence } from '../string.utils';

import { getNavigationBarPart } from './navigation-bar-part.jsonld.utils';
import { updateDataWithSimilarWines } from './update-data-with-top-wines.jsonld.utils';

export const getWinePageJsonLD = (
  menuData: MenuData,
  wine: IVintageResponse,
  similarWines: SimilarWineResponse
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: `${wine.title}`,
    image: `${wine.image}`,
    description: `${getTextFirstSentence(wine.description)}`,
    brand: {
      '@type': 'Brand',
      name: `${wine.winery_name}`,
    },
    ...getNavigationBarPart(
      menuData.surpriseMe,
      menuData.topQueries,
      menuData.grapes,
      menuData.regions
    ),
    offers: {
      '@type': 'Offer',
      url: `${wine.link_to_shop || ''}`,
      priceCurrency: 'USD',
      price: `${wine.price || ''}`,
      availability: 'https://schema.org/InStock',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: `${wine.user_rating}`,
      ratingCount: `${wine.user_rating_count}`,
    },
    additionalProperty: [
      {
        '@type': 'PropertyValue',
        name: 'Region',
        value: `${wine.region}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Sub Region',
        value: `${wine.sub_region}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Country',
        value: `${wine.country}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Aroma',
        value: `${[
          ...wine.taste.flavors.map((flavour) => flavour.group),
          ...wine.taste.flavors.map((flavour) => flavour.sub_groups.join(', ')),
        ].join(', ')}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Sweetness',
        value: `${wine.taste.profiles.find((i) => i.name === 'sweetness')?.text || ''}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Alcohol',
        value: `${wine.taste.profiles.find((i) => i.name === 'alcohol')?.text || ''}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Intensity',
        value: `${wine.taste.profiles.find((i) => i.name === 'intensity')?.text || ''}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Body',
        value: `${wine.taste.profiles.find((i) => i.name === 'body')?.text || ''}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Acidity',
        value: `${wine.taste.profiles.find((i) => i.name === 'acidity')?.text || ''}`,
      },
      {
        '@type': 'PropertyValue',
        name: 'Tannin',
        value: `${wine.taste.profiles.find((i) => i.name === 'tannin')?.text || ''}`,
      },
    ],
    color: `${wine.wine_type}`,
    material: wine.grapes,
    weight: `${wine.bottle_volume || ''}`,
    productionDate: `${wine.vintage_year || 'Non-vintage'}`,
    audience: {
      audienceType: 'Audience',
      suggestedGender: 'Unisex',
      suggestedMinAge: '21',
    },
    isSimilarTo: updateDataWithSimilarWines(similarWines),
    itemCondition: 'https://schema.org/NewCondition',
    manufacturer: `${wine.winery_name}`,
  };
};
