import { WineGrape } from '@interfaces';
import { MenuData } from '@services/menu.service';

import { getTextFirstSentence } from '../string.utils';

import { mainPageEntity } from './main-page-entity.utils';
import { getNavigationBarPart } from './navigation-bar-part.jsonld.utils';

export const getGrapePageJsonLD = (url: string, menuData: MenuData, grape: WineGrape) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    ...mainPageEntity(url),
    ...getNavigationBarPart(
      menuData.surpriseMe,
      menuData.topQueries,
      menuData.grapes,
      menuData.regions
    ),
    headline: `${grape.name}: Colour, Aromas, and Taste Profile`,
    description: `${getTextFirstSentence(grape.description || '')}`,
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    mainContentOfPage: {
      '@type': 'Article',
      name: `${grape.name}`,
      headline: `${grape.name}: Colour, Aromas, and Taste Profile`,
      image: {
        '@type': 'ImageObject',
        url: `${grape.image.banner}`,
        height: '700',
        width: '400',
      },
      author: 'VinoVoss',
      articleSection: [
        'Regional Features & Food Pairings',
        `Fun Fact about ${grape.name}`,
        `Find more Wines with ${grape.name}`,
      ],
      description: `${getTextFirstSentence(grape.description || '')}`,
      about: {
        '@type': 'Wine',
        name: `${grape.name}`,
        color: `${grape.color}`,
        suggestedFood: `${grape.foods.join(', ')}`,
      },
    },
  };
};
