import { OwlOptions } from 'ngx-owl-carousel-o';

import { PromoTypes } from '@interfaces';

export const VINTAGE_SOURCE_FAVORITES = 'Favorites';
export const VINTAGE_SOURCE_SHARED_LIST = 'Shared_List';
export const VINTAGE_SOURCE_REC_AND_OFFERS = 'Recommendations_n_Offers';
export const VINTAGE_SOURCE_MY_CELLAR = 'Wine_Cellar';
export const VINTAGE_SOURCE_CELEBRITY = 'Celebrity';
export const VINTAGE_SOURCE_AWARD_WINNING = 'Award Winning';
export const VINTAGE_SOURCE_ORGANIC_BIO = 'Organic Biodynamic';
export const VINTAGE_SOURCE_ORANGE = 'Orange Wines';
export const VINTAGE_SOURCE_RARE_COLLECT = 'Rare Collectable';
export const VINTAGE_SOURCE_SOMMELIER_SELECTION = 'Sommelier Selections';
export const VINTAGE_SOURCE_XMAS = 'Xmas';

export const TOP_WINE_REGION = 'region';
export const TOP_WINE_GRAPE = 'grape';
export const TOP_WINE_STYLE = 'style';
export const TOP_WINE_WINERY = 'winery';

export const TOP_WINE_FILTERS: Array<PromoTypes> = [
  'Most Popular',
  'Highest Rating',
  'Best Value',
  'Premium',
];
export const MAIN_PAGE_RECOMMEND_WINES_FILTERS = [
  'Most Popular',
  'Highest Rating',
  'Premium',
  'Best Value',
];

export const WINE_CAROUSEL_FOOD_PAIR = 'food-pair';
export const WINE_CAROUSE_GRAPE = 'grape';

export const WINE_TYPE_STILL = 'Still';
export const WINE_TYPE_SPARKLING = 'Sparkling';
export const WINE_TYPE_FORTIFIED = 'Fortified';
export const WINE_TYPE_DESSERT = 'Dessert';
export const WINE_TYPE_NOT_WINE = 'Not Wine';

export const WINE_COLOR_RED = 'Red';
export const WINE_COLOR_WHITE = 'White';
export const WINE_COLOR_ROSE = 'Rose';
export const WINE_COLOR_OTHER = 'Other';

export enum WineRatingRankType {
  RARELY_REVIEWED = 1,
  SOMETIMES_REVIEWED,
  REGULARLY_REVIEWED,
  HIGHLY_REVIEWED,
  MOST_REVIEWED,
}

export const WINE_RARING_RANKS: { [key: string]: string } = {
  [WineRatingRankType.RARELY_REVIEWED]: 'Rarely Reviewed',
  [WineRatingRankType.SOMETIMES_REVIEWED]: 'Sometimes Reviewed',
  [WineRatingRankType.REGULARLY_REVIEWED]: 'Regularly Reviewed',
  [WineRatingRankType.HIGHLY_REVIEWED]: 'Highly Reviewed',
  [WineRatingRankType.MOST_REVIEWED]: 'Most Reviewed',
};

export const FLAVOR_GROUPS: { key: string; name: string }[] = [
  {
    key: 'citrus',
    name: 'Citrus fruit',
  },
  {
    key: 'savoury',
    name: 'Savoury',
  },
  {
    key: 'dry herbs',
    name: 'Dry herbs',
  },
  {
    key: 'fresh herbs',
    name: 'Fresh herbs',
  },
  {
    key: 'dark fruit',
    name: 'Black fruit',
  },
  {
    key: 'spice',
    name: 'Spices',
  },
  {
    key: 'earthy',
    name: 'Earth',
  },
  {
    key: 'caramel',
    name: 'Caramel',
  },
  {
    key: 'red fruit',
    name: 'Red fruit',
  },
  {
    key: 'flower',
    name: 'Flower',
  },
  {
    key: 'toasted wood',
    name: 'Woody',
  },
  {
    key: 'nuts',
    name: 'Natty',
  },
  {
    key: 'fruit',
    name: 'Fruit',
  },
  {
    key: 'non_oak',
    name: 'Non oak',
  },
  {
    key: 'dried fruit',
    name: 'Dried fruit',
  },
  {
    key: 'microbio',
    name: 'Microbio',
  },
  {
    key: 'vegetables',
    name: 'Vegetal',
  },
  {
    key: 'tree_fruit',
    name: 'Tree fruit',
  },
  {
    key: 'tropical fruit',
    name: 'Tropical fruit',
  },
  {
    key: 'oak',
    name: 'Oak',
  },
  {
    key: 'animal',
    name: 'Animal',
  },
  {
    key: 'bacon',
    name: 'Bacon',
  },
  {
    key: 'band-aid',
    name: 'Band-aid',
  },
  {
    key: 'barnyard',
    name: 'Barnyard',
  },
  {
    key: 'biscuit',
    name: 'Biscuit',
  },
  {
    key: 'bread',
    name: 'Bread',
  },
  {
    key: 'chocolate',
    name: 'Chocolate',
  },
  {
    key: 'coffee',
    name: 'Coffee',
  },
  {
    key: 'cooked fruit',
    name: 'Cooked fruit',
  },
  {
    key: 'cooked vegetables',
    name: 'Cooked vegetables',
  },
  {
    key: 'crackers',
    name: 'Crackers',
  },
  {
    key: 'diesel',
    name: 'Diesel',
  },
  {
    key: 'funky',
    name: 'Funky',
  },
  {
    key: 'graphite',
    name: 'Graphite',
  },
  {
    key: 'honey',
    name: 'Honey',
  },
  {
    key: 'iodine',
    name: 'Iodine',
  },
  {
    key: 'jammy fruit',
    name: 'Jammy fruit',
  },
  {
    key: 'laсtic',
    name: 'Laсtic',
  },
  {
    key: 'leather',
    name: 'Leather',
  },
  {
    key: 'leaves',
    name: 'Leaves',
  },
  {
    key: 'meat',
    name: 'Meat',
  },
  {
    key: 'medicinal',
    name: 'Medicinal',
  },
  {
    key: 'melon',
    name: 'Melon',
  },
  {
    key: 'metal',
    name: 'Metal',
  },
  {
    key: 'mold',
    name: 'Mold',
  },
  {
    key: 'mushroom',
    name: 'Mushroom',
  },
  {
    key: 'new leather',
    name: 'New leather',
  },
  {
    key: 'oily',
    name: 'Oily',
  },
  {
    key: 'overripe fruit',
    name: 'Overripe fruit',
  },
  {
    key: 'pencil lead',
    name: 'Pencil lead',
  },
  {
    key: 'petroleum',
    name: 'Petroleum',
  },
  {
    key: 'popcorn',
    name: 'Popcorn',
  },
  {
    key: 'pungent',
    name: 'Pungent',
  },
  {
    key: 'resinous',
    name: 'Resinous',
  },
  {
    key: 'rocks',
    name: 'Rocks',
  },
  {
    key: 'salty',
    name: 'Salty',
  },
  {
    key: 'seeded fruit',
    name: 'Seeded fruit',
  },
  {
    key: 'sherry aromas',
    name: 'Sherry aromas',
  },
  {
    key: 'soy sauce',
    name: 'Soy sauce',
  },
  {
    key: 'stone fruit',
    name: 'Stone fruit',
  },
  {
    key: 'sulfides',
    name: 'Sulfides',
  },
  {
    key: 'tobacco',
    name: 'Tobacco',
  },
  {
    key: 'white flowers',
    name: 'White flowers',
  },
];

export const CUSTOM_OPTIONS: OwlOptions = {
  mouseDrag: true,
  touchDrag: true,
  pullDrag: false,
  dots: true,
  navSpeed: 700,
  slideBy: 3,
  nav: true,
  navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
  responsive: {
    0: {
      items: 1,
      margin: 0,
      dots: true,
      loop: true,
      slideBy: 1,
    },
    500: {
      items: 2,
      margin: 10,
      dots: true,
      loop: true,
      slideBy: 2,
    },
    920: {
      items: 3,
      margin: 30,
      dots: true,
      nav: true,
      slideBy: 3,
    },
    1120: {
      items: 3,
      margin: 30,
      dots: true,
      nav: true,
      slideBy: 3,
    },
  },
};

export const WINE_ARRAY_VALUES = [
  'foods',
  'styles',
  'taste_profiles',
  'bottle_volumes',
  'wine_types',
  'wine_colors',
  'countries',
  'regions',
  'grapes',
  'flavors',
];

export const WINE_NUMBER_VALUES = [
  'price_min',
  'price_max',
  'vintage_year_min',
  'vintage_year_max',
];

export const WINE_BOOLEAN_VALUES = ['is_natural'];

export const WINE_PROFILES: { [key: string]: string }[] = [
  {
    key: 'sweetness',
    name: 'Sweetness',
    description:
      'Wine sweetness is a measure of the amount of sugar present in a wine. Sweetness in wine can range from bone dry (no sugar present) to lusciously sweet (high sugar content). Sweetness is usually determined by the variety and ripeness of the grapes used, as well as the winemaking process.',
  },
  {
    key: 'alcohol',
    name: 'Alcohol',
    description:
      'The alcohol level in wine is a measure of the amount of alcohol present in the beverage. The degree of heat or ‘burn’ at the back of the throat is roughly proportional to the amount of alcohol consumed. It ranges from Very Low to Very High.',
  },
  {
    key: 'intensity',
    name: 'Intensity',
    description:
      'Wine intensity is a measure of the concentration of flavor and aroma compounds in a wine.The stronger and more noticeable the flavors and aromas are, the higher the intensity of the wine. Intensity in wine can range from neutral (not particularly intense) to pronounced (high intensity).',
  },
  {
    key: 'body',
    name: 'Body',
    description:
      'Wine body refers to the weight and texture of wine on the palate. In other words, it is the degree of body a wine has, ranging from light-bodied to full-bodied. Wines with higher body appear to have a thicker texture and can sometimes coat the palate.',
  },
  {
    key: 'acidity',
    name: 'Acidity',
    description:
      'Wine acidity refers to the tartness of a wine, which gives a tingling sensation on the side of the tongue. A metallic-like sharp taste is also associated with high acidity. Sometimes a high level of sweetness, such as in luscious dessert wines, can disguise a high level of acidity. Acidity can range from soft to searing.',
  },
  {
    key: 'tannin',
    name: 'Tannin',
    description:
      'Wine tannin gives wine its textural mouthfeel and astringency. Tannin is a natural compound found in grape skins, seeds, and stems. It contributes to the structure and helps to balance out the sweetness of the fruit. Tannins are usually discussed when it comes to red wines, even though some white and rosé wines with certain wine making techniques can also contain tannins.',
  },
];

export const WINE_FAKE_LINES_THEME_DATA: any[] = [
  { width: '50%' },
  {
    width: '90%',
    items: [{ width: '100px' }, { width: '100px', margin: '0 24px' }, { width: '100px' }],
  },
  { width: '90%', height: '40px' },
  { width: '90%' },
  {
    width: '90%',
    items: [{ width: '100px' }, { width: '100px', margin: '0 24px' }, { width: '100px' }],
  },
];

export const WINE_FAKE_LINES_VERTICAL_THEME_DATA: any[] = [
  { width: '100%', height: '40px' },
  { width: '70%', marginTop: '3px' },
  { width: '70%', height: '20px' },
];
