import { UserFlowPageData } from '@interfaces';

import {
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_MODAL,
  NEW_PASSWORD,
  RESENT_VERIFICATION,
  SIGNUP,
  VERIFICATION,
} from './user-flow-pages.constant';

export const USER_FLOW_PAGE_DATA: { [key: string]: UserFlowPageData } = {
  [LOGIN]: {
    title: 'Log in',
    description:
      'Please fill in all the fields below to log in or choose the option to sign up first below.',
    showButtons: true,
  },
  [LOGIN_MODAL]: {
    title: '',
    description:
      'Please fill in all the fields below to log in or choose the option to sign up first below.',
    showButtons: true,
    modal: true,
  },
  [SIGNUP]: {
    title: 'Sign up',
    description:
      'Please fill in all the fields below to sign up or choose one of the options below.',
    showButtons: true,
  },
  [FORGOT_PASSWORD]: {
    title: 'Forgot your password?',
    description:
      'Please enter the email address you’d like us to send reset password instructions to',
    showButtons: false,
  },
  [VERIFICATION]: {
    title: 'Email sent!',
    description: 'Check your inbox, we’ve sent you instructions on how to reset your password.',
    showButtons: false,
  },
  [RESENT_VERIFICATION]: {
    title: 'Email resent!',
    description: 'Check your inbox, we’ve sent you instructions on how to reset your password.',
    showButtons: false,
  },
  [NEW_PASSWORD]: {
    title: 'Create New Password',
    description: '',
    showButtons: false,
  },
};
