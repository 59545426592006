import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BreadcrumbInterface } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  updateBreadcrumb$: Subject<BreadcrumbInterface> = new Subject<BreadcrumbInterface>();
}
