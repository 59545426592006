import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: [
    `
      .text {
        text-align: center;
        margin-bottom: 40px;
      }
    `,
  ],
})
export class ConfirmDialogComponent {
  @Input() dataToPass: {
    text: string;
    confirmBtn: string;
    cancelBtn: string;
  };

  @Output() dialogDone: EventEmitter<unknown> = new EventEmitter<unknown>();

  onComplete() {
    this.dialogDone.emit('confirm');
  }
}
