import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LoginDialogComponent } from '@modules/user-flow/components/login-dialog/login-dialog.component';
import { UserService } from '@services/user.service';
import { getUserProfile } from '@utils';
import { ModalWindowComponent } from 'src/app/components/modal-window/modal-window.component';

@Component({
  selector: 'app-add-cellar',
  templateUrl: './add-cellar.component.html',
  styleUrls: ['./add-cellar.component.scss'],
})
export class AddCellarComponent {
  @Input() isInWineCellar = false;

  @Input() wineId: string;

  @Output() stateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _getUserProfile: Function = getUserProfile;

  constructor(private userService: UserService, private dialog: MatDialog) {}

  onChangeState($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this._getUserProfile()) {
      this.dialog.open(ModalWindowComponent, {
        panelClass: ['custom-dialog-container', 'confirm-dialog'],
        minWidth: 600,
        data: {
          title: 'Login',
          component: LoginDialogComponent,
        },
      });
      return;
    }

    if (this.isInWineCellar) {
      this.userService.deleteFromWineCellar(this.wineId).subscribe(() => {
        this.changeState();
      });
    } else {
      this.userService.addWineToCellar(this.wineId).subscribe(() => {
        this.changeState();
      });
    }
  }

  private changeState(): void {
    this.isInWineCellar = !this.isInWineCellar;
    this.stateChanged.emit(this.isInWineCellar);
  }
}
