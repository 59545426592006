import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BlogPost } from '@interfaces';
import { convertBlogDateToISO } from '@utils';
import { AppState, getUserLocationUrlPrefix } from 'src/app/store';

@Component({
  selector: 'app-news-article-card',
  templateUrl: './news-article-card.component.html',
  styleUrls: ['./news-article-card.component.scss'],
})
export class NewsArticleCardComponent {
  @Input() post: BlogPost;

  urlPrefix$: Observable<string | null>;

  constructor(private store: Store<AppState>) {
    this.urlPrefix$ = this.store.select(getUserLocationUrlPrefix);
  }

  getDate(date: string): string {
    return convertBlogDateToISO(date);
  }
}
