import { Options } from '@angular-slider/ngx-slider';

import {
  WINE_FILTER_TYPE_CHECKBOX,
  WINE_FILTER_TYPE_LIST,
  WINE_FILTER_TYPE_RANGE,
  WINE_FILTER_TYPE_STARS,
  WINE_FILTER_TYPE_SWITCH,
} from '@constants';

export type WineFilterType =
  | typeof WINE_FILTER_TYPE_LIST
  | typeof WINE_FILTER_TYPE_RANGE
  | typeof WINE_FILTER_TYPE_STARS
  | typeof WINE_FILTER_TYPE_CHECKBOX
  | typeof WINE_FILTER_TYPE_SWITCH;

export interface IFilterItem {
  name: string;
  value: any;
  selected?: boolean;
}

export const WINE_FILTER_RANGE_RANGER = 'range-ranger';

export type WineFilterRangeType = typeof WINE_FILTER_RANGE_RANGER;

export interface WineFilter {
  key: string;
  name: string;
  type: WineFilterType;
  switcher?: FilterSwitcherItem[];
  switcher_key?: string;
  items?: IFilterItem[];
  search?: boolean;
  searchPlaceholder?: string;
  dirty?: boolean;
  range?: boolean;
  withInputs?: boolean;
  rangeType?: WineFilterRangeType;
  minKey?: string;
  maxKey?: string;
  rangeMinValue?: number;
  rangeMaxValue?: number;
  initValueMin?: number;
  initValueMax?: number;
  histogram?: number[];
  filterRangeOptions?: Options;
  color?: 'red' | 'yellow';
  selected?: boolean;
}

export interface WineFilterChange {
  key: string;
  dirty: boolean;
  changed: IFilterItem;
}

export interface WineFilterRangeChange extends Pick<WineFilterChange, 'key' | 'dirty'> {
  min: number | null;
  max: number | null;
}

export interface WineFilterSwitchChange extends Pick<WineFilter, 'key'> {
  selected: boolean | null;
  filter_off?: boolean;
}

export interface FilterMetadata {
  bottle_volumes: number[];
  countries: string[];
  foods: string[];
  grapes: string[];
  prices: {
    min_value: number;
    max_value: number;
    histogram: number[];
  };
  regions: string[];
  vintage_years: {
    min_value: number;
    max_value: number;
    histogram: number[];
  };
  selected_filters: SelectedFilter[];
  wine_styles: string[];
  wine_types: string[];
  wine_colors: string[];
}

export interface SelectedFilter {
  type: string;
  values: SelectedFilterArray | boolean;
}

export type SelectedFilterArray = (string | number | number[])[];

export interface PricesHistogram {
  min_value: number;
  max_value: number;
  histogram: number[];
}

export interface RemovedFilters {
  [key: string]: any;
}

export interface FilterSwitcherItem {
  key: string;
  title: string;
  value: unknown;
}

export interface FiltersMetadataTransferState {
  metadata: FilterMetadata;
}
