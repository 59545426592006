import { createSelector } from '@ngrx/store';

import { UserLocationData } from '@interfaces';
import { AppState } from 'src/app/store/state';

const selectConfig = (state: AppState) => state.location;
const selectLocationUrlPrefix = (state: AppState) => state.location.url_prefix;

export const getUserLocation = createSelector(selectConfig, (state: UserLocationData) => state);
export const getUserLocationCountryRegion = createSelector(
  selectConfig,
  (state: UserLocationData) => ({
    country: state.country,
    region: state.region,
  })
);

export const getUserLocationUrlPrefix = createSelector(
  selectLocationUrlPrefix,
  (state: string) => state
);
