import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ReportQualityDialogComponent } from 'src/app/components/dialogs/report-quality-dialog/report-quality-dialog.component';
import { ModalWindowComponent } from 'src/app/components/modal-window/modal-window.component';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.scss'],
})
export class ReportDataComponent {
  @Input() data: { type: string; slug: string };

  constructor(private dialog: MatDialog) {}

  onReportData(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ModalWindowComponent, {
      panelClass: ['custom-dialog-container', 'confirm-dialog'],
      data: {
        title: 'Report quality issue',
        dataToPass: {
          type: this.data.type,
          resourceSlug: this.data.slug,
          message:
            'Your feedback will help us to improve our data quality. Please describe the issue you found in detail.',
        },
        component: ReportQualityDialogComponent,
      },
    });
  }
}
