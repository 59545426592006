import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { DirectivesModule } from '@shared/directives.module';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes.module';
import { AddCellarComponent } from 'src/app/components/add-cellar/add-cellar.component';
import { BreadcrumbsComponent } from 'src/app/components/breadcrumbs/breadcrumbs.component';
import { CustomNgxSliderComponent } from 'src/app/components/custom-ngx-slider/custom-ngx-slider.component';
import { ApiSuccessDialogComponent } from 'src/app/components/dialogs/api-success-dialog/api-success-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { FakeWineCardsLoadingComponent } from 'src/app/components/fake-wine-cards-loading/fake-wine-cards-loading.component';
import { LikeButtonComponent } from 'src/app/components/like-button/like-button.component';
import { ModalWindowComponent } from 'src/app/components/modal-window/modal-window.component';
import { WineImageComponent } from 'src/app/components/wine-image/wine-image.component';
import { WineSearchSwitcherComponent } from 'src/app/components/wine-search-switcher/wine-search-switcher.component';
import { WineSearchComponent } from 'src/app/components/wine-search/wine-search.component';

import { AddFavoriteComponent } from '../components/add-favorite/add-favorite.component';
import { AddWishlistComponent } from '../components/add-wishlist/add-wishlist.component';
import { AutocompleteInputComponent } from '../components/autocomplete-input/autocomplete-input.component';
import { FilterPageComponent } from '../components/filter-page/filter-page.component';
import { FilterComponent } from '../components/filter/filter.component';
import { HeaderModule } from '../components/header/header.module';
import { LoaderComponent } from '../components/loader/loader.component';
import { ReportDataComponent } from '../components/report-data/report-data.component';
import { ShareButtonModule } from '../components/share-button/share-button.module';
import { WineCardComponent } from '../components/wine-card/wine-card.component';
import { WineCountryComponent } from '../components/wine-country/wine-country.component';
import { WineRatingComponent } from '../components/wine-rating/wine-rating.component';
import { WineTasteComponent } from '../components/wine-taste/wine-taste.component';
import { WineTypeComponent } from '../components/wine-type/wine-type.component';
import { InfoTipComponent } from 'src/app/components/info-tip/info-tip.component';

@NgModule({
  declarations: [
    WineImageComponent,
    WineCardComponent,
    WineTypeComponent,
    WineCountryComponent,
    WineRatingComponent,
    WineTasteComponent,
    LoaderComponent,
    FilterPageComponent,
    FilterComponent,
    BreadcrumbsComponent,
    WineSearchComponent,
    LikeButtonComponent,
    ModalWindowComponent,
    ApiSuccessDialogComponent,
    AddFavoriteComponent,
    AddWishlistComponent,
    ReportDataComponent,
    ConfirmDialogComponent,
    AddCellarComponent,
    CustomNgxSliderComponent,
    FakeWineCardsLoadingComponent,
    WineSearchSwitcherComponent,
    AutocompleteInputComponent,
    InfoTipComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSliderModule,
    NgxPaginationModule,
    MaterialModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
    ShareButtonModule,
    PipesModule,
    HeaderModule,
    DirectivesModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    DirectivesModule,
    NgSelectModule,
    NgxSliderModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    PipesModule,
    TranslateModule,

    WineImageComponent,
    WineCardComponent,
    WineTypeComponent,
    WineCountryComponent,
    WineRatingComponent,
    WineTasteComponent,
    LoaderComponent,
    BreadcrumbsComponent,
    WineSearchComponent,
    FilterPageComponent,
    FilterComponent,
    LikeButtonComponent,
    ModalWindowComponent,
    ApiSuccessDialogComponent,
    AddFavoriteComponent,
    AddWishlistComponent,
    ReportDataComponent,
    ConfirmDialogComponent,
    AddCellarComponent,
    CustomNgxSliderComponent,
    FakeWineCardsLoadingComponent,
    WineSearchSwitcherComponent,
    AutocompleteInputComponent,
    InfoTipComponent,
  ],
})
export class SharedModule {}
