import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EmbeddedWineCardRoutingModule } from '@modules/embedded/embedded-wine-card/embedded-wine-card-routing.module';
import { SharedModule } from '@shared/shared.module';

import { EmbeddedWineCardComponent } from './embedded-wine-card.component';

@NgModule({
  declarations: [EmbeddedWineCardComponent, EmbeddedWineCardComponent],
  exports: [EmbeddedWineCardComponent],
  imports: [CommonModule, SharedModule, EmbeddedWineCardRoutingModule],
})
export class EmbeddedWineCardModule {}
