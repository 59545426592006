import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
  private excludedUrls = ['/profile/wishlist/'];
  override parse(url: string): UrlTree {
    if (this.excludedUrls.some((_url) => url.includes(_url))) {
      return super.parse(url);
    }

    return super.parse(url.replace(/%20/g, '-').replace(/%2F/g, '/'));
  }

  override serialize(tree: UrlTree): string {
    if (this.excludedUrls.some((_url) => tree.toString().includes(_url))) {
      return super.serialize(tree);
    }

    return super.serialize(tree).replace(/%20/g, '-');
  }
}
