import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '@environment';
import {
  MyRating,
  RecWinesRes,
  User,
  UserLocationData,
  WineCellarStatistic,
  WineResponse,
  WinesListWithoutFilters,
} from '@interfaces';
import { AppState, getUserLocation } from '@store';
import { getUrlWithParams } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userSubject$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  me(): Observable<User> {
    return this.http.get<User>(`${environment.API_URL}/users/me`);
  }

  patchUsername(value: string): Observable<User> {
    return this.http.patch<User>(`${environment.API_URL}/users/me`, { username: value });
  }

  requestVerificationToken(email: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/auth/request-verify-token`, { email });
  }

  verifyToken(token: string): Observable<User> {
    return this.http.post<User>(`${environment.API_URL}/auth/verify`, { token });
  }

  patchGender(value: string): Observable<User> {
    return this.http.patch<User>(`${environment.API_URL}/users/me`, { gender: value });
  }

  patchDateOfBirthday(value: string): Observable<User> {
    return this.http.patch<User>(`${environment.API_URL}/users/me`, { dob: value });
  }

  patchPassword(value: string): Observable<User> {
    return this.http.patch<User>(`${environment.API_URL}/users/me`, { password: value });
  }

  getMyBrowserHistory(params: any): Observable<RecWinesRes> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) => {
        return this.http
          .get<any>(
            getUrlWithParams(
              {
                ...params,
                market_country: userLocationData.country,
                market_region: userLocationData.region,
              },
              '/users-profile/history'
            )
          )
          .pipe(map(this.mapWithoutFiltersResponse));
      })
    );
  }

  getHandpickForMe(params: any): Observable<RecWinesRes> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) => {
        return this.http
          .get<any>(
            getUrlWithParams(
              {
                ...params,
                market_country: userLocationData.country,
                market_region: userLocationData.region,
              },
              '/users-profile/handpicked'
            )
          )
          .pipe(map(this.mapWithoutFiltersResponse));
      })
    );
  }

  addWineToFavorites(vintage_id: string): Observable<any> {
    return this.http.put(`${environment.API_URL}/users-profile/favorites`, { vintage_id });
  }

  deleteFromFavorite(vintage_id: string): Observable<unknown> {
    return this.http.delete(
      `${environment.API_URL}/users-profile/favorites?vintage_id=${vintage_id}`
    );
  }

  getMyRatings(): Observable<MyRating[]> {
    return this.http.get<MyRating[]>(`${environment.API_URL}/users-profile/ratings`);
  }

  getMyWineCellarStatistic(): Observable<WineCellarStatistic> {
    return this.http.get<WineCellarStatistic>(
      `${environment.API_URL}/users-profile/wine_cellar/statistic`
    );
  }

  addWineToCellar(id: string): Observable<unknown> {
    return this.http.put(`${environment.API_URL}/users-profile/wine_cellar/vintages`, {
      vintage_id: id,
    });
  }

  deleteFromWineCellar(id: string): Observable<unknown> {
    return this.http.delete(
      `${environment.API_URL}/users-profile/wine_cellar/vintages?vintage_id=${id}`
    );
  }

  deleteReview(id: string): Observable<unknown> {
    return this.http.delete(`${environment.API_URL}/users-profile/ratings?rating_id=${id}`);
  }

  uploadAvatar(data: FormData): Observable<{ direct_url: string }> {
    return this.http.post<{ direct_url: string }>(`${environment.API_URL}/users/avatar`, data);
  }

  private mapWithoutFiltersResponse(response: WinesListWithoutFilters): RecWinesRes {
    return {
      ...response,
      items: response.items.map((item: WineResponse) => {
        return {
          data: { ...item, wishlists: [] },
          data_type: 'vintage',
        };
      }),
    };
  }
}
