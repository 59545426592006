import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocalizeRouterPipe } from '@gilsdav/ngx-translate-router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { DirectivesModule } from '@shared/directives.module';
import { PipesModule } from '@shared/pipes.module';
import { HeaderComponent } from 'src/app/components/header/header.component';

import { HeaderSearchQueryComponent } from './components/header-search-query/header-search-query.component';
import { HeaderFilterPipe } from './header-filter.pipe';

@NgModule({
  declarations: [HeaderComponent, HeaderSearchQueryComponent, HeaderFilterPipe],
  imports: [
    RouterModule,
    CommonModule,
    DirectivesModule,
    NgxSkeletonLoaderModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  providers: [LocalizeRouterPipe],
  exports: [HeaderComponent, HeaderSearchQueryComponent],
})
export class HeaderModule {}
