import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component';

import { ShareButtonComponent } from './share-button.component';

@NgModule({
  declarations: [ShareButtonComponent, ShareDialogComponent],
  imports: [CommonModule, FormsModule],
  exports: [ShareButtonComponent],
})
export class ShareButtonModule {}
