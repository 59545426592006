import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[hover-class]',
})
export class HoverClassDirective {
  constructor(public elementRef: ElementRef) {}

  @Input('hover-class') hoverClass: any;

  @HostListener('mouseenter') onMouseEnter() {
    if (window.innerWidth >= 768) {
      this.elementRef.nativeElement.classList.add(this.hoverClass);
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (window.innerWidth >= 768) {
      this.elementRef.nativeElement.classList.remove(this.hoverClass);
    }
  }
}
