import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';

export interface ContactUsForm {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

export interface PartnershipForm {
  full_name: string;
  email: string;
  phone: string;
  company_name: string;
  company_website: string;
  country: string;
  industry_sector: string;
  project_description: string;
  collaboration_type: string;
  collaboration_goals: string;
  how_did_you_hear_about_us: string;
  is_there_anything_else_you_would_like_us_to_know: string;
}

@Injectable({
  providedIn: 'root',
})
export class OtherEndpointsService {
  constructor(private http: HttpClient) {
    //
  }

  sendContactUsForm(data: ContactUsForm): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/contact_us`, data);
  }

  sendPartnershipForm(data: PartnershipForm): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/partnership`, data);
  }

  subscribeOnNewsletter(email: string): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/newsletter_subscription`, { email });
  }
}
