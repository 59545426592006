import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ApiSuccessDialogComponent } from 'src/app/components/dialogs/api-success-dialog/api-success-dialog.component';
import { ModalWindowComponent } from 'src/app/components/modal-window/modal-window.component';

@Injectable({
  providedIn: 'root',
})
export class ModalNotificationsService {
  constructor(private dialog: MatDialog) {
    //
  }

  showSuccessPopup(title: string, successMessage: string): MatDialogRef<any> {
    return this.dialog.open(ModalWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title: '',
        dataToPass: {
          title,
          text: successMessage,
        },
        component: ApiSuccessDialogComponent,
      },
    });
  }
}
