import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { WineRatingRankType } from '@constants';
import { numberWithCommas } from 'src/app/utils/string.utils';
import { getWineRatingRank } from 'src/app/utils/wine.utils';

@Component({
  selector: 'app-wine-rating',
  templateUrl: './wine-rating.component.html',
  styleUrls: ['./wine-rating.component.scss'],
})
export class WineRatingComponent implements OnInit {
  @Input() rating = 0;
  @Input() votes = 0;
  @Input() starsOnly = false;
  @Input() ratingRank: WineRatingRankType;
  @Input() color: 'red' | 'yellow' = 'yellow';
  @Input() size = 19; //width in pixels;
  @Input() clickable = false;
  @Input() isString = false;
  @Input() oneStar = false;

  @Output() ratingChanged: EventEmitter<number> = new EventEmitter<number>();

  starCount = [...new Array(5)];

  get ratingRankMap(): string {
    return getWineRatingRank(this.ratingRank);
  }

  ngOnInit() {
    if (this.oneStar) {
      this.starCount = [1];
    }
  }

  getStarWidth(star: number): string {
    return star <= this.rating
      ? '100%'
      : star === Math.trunc(this.rating + 1)
      ? `${this.size * (this.rating % 1)}px`
      : '0px';
  }

  onStarClick(index: number) {
    if (this.clickable) {
      this.rating = index;
      this.ratingChanged.emit(this.rating);
    }
  }

  getNumberWithCommas(votes: number): string {
    return numberWithCommas(votes);
  }
}
