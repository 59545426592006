import { SimilarWineResponse } from '@interfaces';
import { getTextFirstSentence } from '@utils';

export const updateDataWithTopWinesJsonldUtils = (parent: any, wines: SimilarWineResponse) => {
  return {
    ...parent,
    mainContentOfPage: {
      ...parent['mainContentOfPage'],
      mentions: updateDataWithSimilarWines(wines),
    },
  };
};

export const updateDataWithSimilarWines = (wines: SimilarWineResponse) => {
  return wines.items.map((wine) => {
    return {
      '@type': 'Wine',
      name: `${wine.title}`,
      description: `${getTextFirstSentence(wine.description)}`,
      suggestedFood: wine.foods || [],
      image: `${wine.image}`,
      producer: {
        '@type': 'Winery',
        name: `${wine.winery_name}`,
      },
      alcoholContent: `${wine.bottle_volume}`,
      wineRegion: `${wine.region}`,
      countryOfOrigin: `${wine.country}`,
      userRating: `${wine.user_rating}`,
      expertRating: `${wine.expert_rating}`,
      price: `${wine.price}`,
    };
  });
};
