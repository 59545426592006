import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ChangeWishlistDialogDone, Wishlist } from '@interfaces';
import { LoginDialogComponent } from '@modules/user-flow/components/login-dialog/login-dialog.component';
import { AddWishlistDialogComponent } from '@modules/user/pages/wishlists/components/add-wishlist-dialog/add-wishlist-dialog.component';
import { WishListService } from '@services/wish-list.service';
import { getUserProfile } from '@utils';
import { ChangeWishlistDialogComponent } from 'src/app/components/add-wishlist/change-wishlist-dialog/change-wishlist-dialog.component';
import { ModalWindowComponent } from 'src/app/components/modal-window/modal-window.component';

@Component({
  selector: 'app-add-wishlist',
  templateUrl: './add-wishlist.component.html',
  styleUrls: ['./add-wishlist.component.scss'],
})
export class AddWishlistComponent {
  @Input() wishlists: Wishlist[] | undefined = [];

  @Input() wineId: string;

  @Output() wishlistsChanged: EventEmitter<Wishlist[]> = new EventEmitter<Wishlist[]>();

  private _getUserProfile: Function = getUserProfile;

  constructor(private dialog: MatDialog, private wishlistService: WishListService) {
    //
  }

  click($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this._getUserProfile()) {
      this.dialog.open(ModalWindowComponent, {
        panelClass: ['custom-dialog-container', 'confirm-dialog'],
        minWidth: 600,
        data: {
          title: 'Login',
          component: LoginDialogComponent,
        },
      });
      return;
    }

    const dialogRef = this.openChangeWishlistDialog();

    dialogRef.afterClosed().subscribe((res: ChangeWishlistDialogDone) => {
      if (!res) {
        return;
      }

      this.afterChangeWishlistDialogClosed(res);

      if (res.event === 'create_new') {
        const addDialogRef = this.dialog.open(ModalWindowComponent, {
          panelClass: 'custom-dialog-container',
          data: {
            title: 'Add a new Wishlist',
            dataToPass: {
              text: 'You need to set up a name for your new Wishlist',
            },
            component: AddWishlistDialogComponent,
          },
        });

        addDialogRef.afterClosed().subscribe(() => {
          this.openChangeWishlistDialog()
            .afterClosed()
            .subscribe((res: ChangeWishlistDialogDone) => {
              this.afterChangeWishlistDialogClosed(res);
            });
        });
      }
    });
  }

  private afterChangeWishlistDialogClosed(res: ChangeWishlistDialogDone): void {
    if (res && res.event === 'api_success') {
      this.wishlists = this.wishlists?.filter(
        (item) => !res.wishlistsForDelete.some((i) => i.name === item.name)
      );
      res.wishlistsForAdd.forEach((item) => this.wishlists?.push(item));

      res.wishlistsForAdd.forEach((item) => {
        this.wishlistService
          .addVintageToWishlist({ name: item.name, vintage_id: this.wineId })
          .subscribe();
      });

      res.wishlistsForDelete.forEach((item) => {
        this.wishlistService.deleteVintageFromWishlist(item.name, this.wineId).subscribe();
      });

      this.wishlistsChanged.emit(this.wishlists);
    }
  };

  private openChangeWishlistDialog(): MatDialogRef<ModalWindowComponent> {
    return this.dialog.open(ModalWindowComponent, {
      panelClass: ['custom-dialog-container', 'confirm-dialog', 'wishlists-dialog'],
      data: {
        title: this.getDialogTitle(),
        dataToPass: {
          message: !this.wishlists?.length
            ? 'It seems that you have no Wishlists. Create your first one!'
            : '',
          passedWishlists: this.wishlists,
        },
        component: ChangeWishlistDialogComponent,
      },
    });
  }

  private getDialogTitle(): string {
    if (this.wishlists && this.wishlists.length) {
      return 'Which list do you want to move it?';
    }

    return 'Which list do you want to add to?';
  }
}
