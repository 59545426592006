import { Options } from '@angular-slider/ngx-slider';

export const PRICE_RANGE_MIN = 1;
export const PRICE_RANGE_MAX = 200;

export const PRICE_RANGE_OPTIONS: Options = {
  floor: PRICE_RANGE_MIN,
  ceil: PRICE_RANGE_MAX,
  noSwitching: true,
  hideLimitLabels: true,
  translate: (value: number): string => {
    if (value >= PRICE_RANGE_MAX) {
      return `$${PRICE_RANGE_MAX}+`;
    }

    return '$' + value;
  },
};

export const PAGE_PRICE_RANGE_OPTIONS: Options = {
  floor: PRICE_RANGE_MIN,
  ceil: PRICE_RANGE_MAX,
  noSwitching: true,
  hideLimitLabels: true,
};
