import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { clone, forEach, isArray, isEqual, omit } from 'lodash';
import { filter, Observable, of, switchMap, combineLatest } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { environment } from '@environment';
import {
  AddRatingRequest,
  FilterMetadata,
  IVintageResponse,
  RecWinesRequest,
  RecWinesRes,
  SimilarWineResponse,
  TopWineSearchRequest,
  UserLocationData,
  WineDrinkingWindow,
  WineGrape,
  WinePageData,
  WinePrice,
  WineRatingsRes,
  WineRegion,
  WineResponse,
  WineSearchMetadata,
  WineSearchMetaRequest,
  WineSibling,
  WineUserPreferences,
  Wishlist,
} from '@interfaces';
import { AppState, getUserLocation } from '@store';
import { getUrlWithParams, getUserProfile, mapWineFiltersValues, removeEmptyValues } from '@utils';
import { capitalizeFirstLetter, replaceStringForQuery } from 'src/app/utils/string.utils';

@Injectable({
  providedIn: 'root',
})
export class WineService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  uploadWineImage(data: FormData, id: string): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/vintage/${id}/image`, data);
  }

  getPromoWines(promo_type: string): Observable<WineResponse[]> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) =>
        this.http.get<WineResponse[]>(
          getUrlWithParams(
            {
              promo_type,
              market_country: userLocationData.country,
              market_region: userLocationData.region,
            },
            `/vintages/promo`
          )
        )
      )
    );
  }

  getFilterData(
    data: Partial<RecWinesRequest>,
    histogram_steps = 25,
    histogram_scale = 10
  ): Observable<FilterMetadata> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) => {
        return this.http.post<FilterMetadata>(`${environment.API_URL}/vintages/search/meta`, {
          ...this.prepareMetaRequest(data),
          skip_spell_check: data.skip_spell_check,
          market_country: userLocationData.country,
          market_region: userLocationData.region,
          histogram_steps,
          histogram_scale,
        });
      })
    );
  }

  searchWines(data: Partial<RecWinesRequest>): Observable<RecWinesRes> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) => {
        return this.http
          .post<RecWinesRes>(
            getUrlWithParams(
              {
                page: data.page,
                size: data.size,
              },
              '/vintages/search'
            ),
            {
              ...this.prepareMetaRequest(data),
              market_country: userLocationData.country,
              market_region: userLocationData.region,
              skip_spell_check: data.skip_spell_check,
            },
            {
              observe: 'response',
            }
          )
          .pipe(
            map((res) => {
              return {
                ...res.body,
                items: res.body
                  ? res.body.items.map((item) => {
                      return item.data_type === 'vintage'
                        ? { ...item, data: { ...item.data, wishlists: [] } }
                        : item;
                    })
                  : [],
                ...(res.headers.get('X-Search-Id')
                  ? { xSearchId: res.headers.get('X-Search-Id') }
                  : {}),
              };
            }),
            catchError(() =>
              of({
                items: [],
              })
            )
          );
      })
    );
  }

  getWineDrinkingWindow(id: string): Observable<WineDrinkingWindow> {
    return this.http.get<WineDrinkingWindow>(
      `${environment.API_URL}/vintage/${id}/drinking_window`
    );
  }

  getWineSibling(id: string): Observable<WineSibling[]> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) =>
        this.http.get<WineSibling[]>(
          getUrlWithParams(
            {
              market_country: userLocationData.country,
              market_region: userLocationData.region,
            },
            `/vintage/${id}/sibling`
          )
        )
      )
    );
  }

  getWineRegionDescription(region_slug: string): Observable<string> {
    return this.http.get<string>(`${environment.API_URL}/region/${region_slug}/description`).pipe(
      map((description) => description || ''),
      catchError(() => {
        return of('');
      })
    );
  }

  getEmbeddedWineBySlug(slug: string): Observable<WineResponse> {
    return this.http.get<WineResponse>(`${environment.API_URL}/compact_vintage/${slug}`).pipe(
      switchMap((wine) => {
        return combineLatest([of(wine), this.getWinePrices(wine.id)]).pipe(
          map(([wine, prices]) => {
            return {
              ...wine,
              price: prices[0]?.amount ?? 0,
              bottle_volume: prices[0]?.volume_ml ?? 0,
              link_to_shop: prices[0]?.link_to_shop || '',
              available_vintages_count: prices.filter((price) => !!price.volume_ml).length || 0,
            };
          })
        );
      })
    );
  }

  getWineById(
    slug: string,
    prioritized_foods: string[] | undefined = undefined
  ): Observable<WinePageData> {
    let endpoint = `${environment.API_URL}/vintage/${slug}`;

    if (prioritized_foods) {
      endpoint = `${endpoint}?prioritized_foods=${prioritized_foods}`;
    }

    return this.http.get<IVintageResponse>(endpoint).pipe(
      switchMap((wine) => {
        return combineLatest([
          of(wine),
          !getUserProfile() ? of([]) : this.checkVintageWishlist(wine.id),
          !getUserProfile()
            ? of({
                is_favorite: false,
                is_in_wine_cellar: false,
                is_recommended: false,
                is_offered: false,
              })
            : this.getWineUserPreferences(wine.id),
        ]).pipe(
          map(([wine, wishlists, userPrefs]) => {
            return {
              ...wine,
              wishlists,
              ...userPrefs,
            } as WinePageData;
          })
        );
      })
    );
  }

  getWinePrices(id: string): Observable<WinePrice[]> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) =>
        this.http.get<WinePrice[]>(
          getUrlWithParams(
            {
              market_country: userLocationData.country,
              market_region: userLocationData.region,
            },
            `/vintage/${id}/prices`
          )
        )
      )
    );
  }

  getWineUserPreferences(id: string): Observable<WineUserPreferences> {
    return this.http.get<WineUserPreferences>(
      `${environment.API_URL}/vintage/${id}/user_preferences`
    );
  }

  spellCheckQuery(query: string): Observable<string> {
    return this.http.get<string>(
      `${environment.API_URL}/vintages/search/query/spell_check?query=${replaceStringForQuery(
        query
      )}`
    );
  }

  getSimilarById(id: string, page: number, size = 3): Observable<SimilarWineResponse> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) =>
        this.http.get<SimilarWineResponse>(
          getUrlWithParams(
            {
              page,
              size,
              market_country: userLocationData.country,
              market_region: userLocationData.region,
            },
            `/vintage/${id}/similar_vintages`
          )
        )
      )
    );
  }

  checkVintageWishlist(id: string): Observable<Wishlist[]> {
    return this.http.get<Wishlist[]>(
      `${environment.API_URL}/users-profile/wishlists?vintage_id=${id}`
    );
  }

  getWineRatings(id: string): Observable<WineRatingsRes> {
    return this.http.get<WineRatingsRes>(`${environment.API_URL}/ratings?vintage_id=${id}`);
  }

  addReview(data: AddRatingRequest): Observable<unknown> {
    return this.http.post(`${environment.API_URL}/ratings`, data);
  }

  getSearchQueryExamples(count: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.API_URL}/vintages/search/query/examples?k=${count}`
    );
  }

  getRatingStatistic(id: string): Observable<{ distributions: number[] }> {
    return this.http.get<{ distributions: number[] }>(
      `${environment.API_URL}/ratings/statistic?vintage_id=${id}`
    );
  }

  getRegionById(slug: string): Observable<WineRegion> {
    return this.http.get<WineRegion>(`${environment.API_URL}/region/${slug}`);
  }

  getSearchWineMetadata(data: WineSearchMetaRequest): Observable<WineSearchMetadata> {
    return this.http.post<WineSearchMetadata>(
      `${environment.API_URL}/top_vintages/search_meta`,
      data
    );
  }

  getGrapes(grapeNames: string[]): Observable<WineGrape[]> {
    return this.http.get<WineGrape[]>(`${environment.API_URL}/grapes`, {
      params: { names: grapeNames },
    });
  }

  getGrapeBySlug(slug: string): Observable<WineGrape> {
    return this.http.get<WineGrape>(`${environment.API_URL}/grape/${slug}`);
  }

  getTopWines(data: TopWineSearchRequest, page: number, size = 5): Observable<SimilarWineResponse> {
    return this.http.post<SimilarWineResponse>(
      `${environment.API_URL}/top_vintages/search?page=${page}&size=${size}`,
      data
    );
  }

  getHomepageFilterMeta(data: any): Observable<FilterMetadata> {
    return this.http.post<FilterMetadata>(`${environment.API_URL}/meta/homepage`, data);
  }

  private prepareMetaRequest(data: Partial<RecWinesRequest>): Partial<RecWinesRequest> {
    let _data = clone(data);
    if (!_data.vintage_source?.length) {
      _data = omit(_data, ['vintage_source']);
    }

    _data.params = omit(_data.params, ['size', 'sort_by', 'page-key']);

    _data.params = mapWineFiltersValues(_data.params);

    if (isArray(_data.params?.['wine_types'])) {
      _data = {
        ..._data,
        params: {
          ..._data.params,
          wine_types: _data.params?.['wine_types'].map((type: string) =>
            capitalizeFirstLetter(type)
          ),
        },
      };
    }

    if (isArray(_data.params?.['wine_colors'])) {
      _data = {
        ..._data,
        params: {
          ..._data.params,
          wine_colors: _data.params?.['wine_colors'].map((type: string) =>
            capitalizeFirstLetter(type)
          ),
        },
      };
    }

    if (_data.params?.['bottle_volumes']?.length) {
      _data = {
        ..._data,
        params: {
          ..._data.params,
          bottle_volumes: _data.params?.['bottle_volumes']?.map((item: string) => +item),
        },
      };
    }

    const mappedFilters: any = {};
    const priceMin = _data.params?.['price_min'];

    forEach(_data.params, (value, key) => {
      if (key === 'price_min' || key === 'price_max') {
        if (!mappedFilters.price_min) {
          mappedFilters.price_min = priceMin >= 1 ? priceMin : 0 || null;
        }

        if (!mappedFilters.price_max) {
          mappedFilters.price_max = _data.params?.['price_max'] || null;
        }
        return;
      }

      if (key === 'vintage_year_min' || key === 'vintage_year_max') {
        if (!mappedFilters.vintage_year_min) {
          mappedFilters.vintage_year_min = _data.params?.['vintage_year_min'] || null;
        }

        if (!mappedFilters.vintage_year_max) {
          mappedFilters.vintage_year_max = _data.params?.['vintage_year_max'] || null;
        }
        return;
      }

      mappedFilters[key] = value;
    });

    _data.filters = mappedFilters;

    forEach(_data.removed_filters, (value, key) => {
      if (isArray(value)) {
        value = value.map((item) =>
          typeof item === 'string' ? replaceStringForQuery(capitalizeFirstLetter(item)) : item
        );
      } else if (typeof value === 'string') {
        value = replaceStringForQuery(capitalizeFirstLetter(value));
      }

      _data.removed_filters[key] = value;

      if (_data.filters[key] && isEqual(_data.filters[key], value)) {
        _data.removed_filters[key] = null;
      }
    });

    return {
      ...removeEmptyValues({
        filters: omit(_data.filters, ['query', 'is_blended_off', 'token']),
        removed_filters: removeEmptyValues(_data.removed_filters),
        vintage_source: data.vintage_source,
        sort_by: data.sort_by,
      }),
      query: replaceStringForQuery(data.query?.trim() || ''),
    };
  }
}
