import { Action } from '@ngrx/store';

import { UserLocationData } from '@interfaces';

export const UPDATE_USER_LOCATION = '[UserLocation] Update User Location';

export class UpdateUserLocation implements Action {
  public readonly type = UPDATE_USER_LOCATION;

  constructor(public payload: Partial<UserLocationData>) {}
}

export type UserLocationActions = UpdateUserLocation;
