import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MenuData, MenuService } from '@services/menu.service';
import { AppState, UpdateMenuData } from 'src/app/store';
import { getLocationFromUrl } from 'src/app/utils/string.utils';
import { REQUEST_URL } from 'src/tokens';

@Injectable({
  providedIn: 'any',
})
export class RegionResolver implements Resolve<any> {
  constructor(
    private store: Store<AppState>,
    private transferState: TransferState,
    private menuService: MenuService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(REQUEST_URL) private requestUrl: string
  ) {}

  resolve(): Observable<any> {
    const MENU_DATA_KEY = makeStateKey<MenuData>('menu-data');

    if (this.transferState.hasKey(MENU_DATA_KEY)) {
      // @ts-ignore
      const menuData = this.transferState.get<MenuData>(MENU_DATA_KEY, null);

      this.store.dispatch(new UpdateMenuData(menuData));

      return of(menuData);
    } else {
      let location = { country: '', region: '' };

      if (isPlatformServer(this.platformId)) {
        location = getLocationFromUrl(this.requestUrl);
      }

      return this.menuService.getMenuData(6, location.country, location.region).pipe(
        first(),
        tap((menuData) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(MENU_DATA_KEY, menuData);
          } else {
            this.store.dispatch(new UpdateMenuData(menuData));
          }
        })
      );
    }
  }
}
