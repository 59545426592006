import { ActionReducerMap } from '@ngrx/store';

import { userLocationReducers } from 'src/app/store/reducers/location.reducers';
import { menuDataReducers } from 'src/app/store/reducers/menu.reducers';
import { AppState } from 'src/app/store/state';

// eslint-disable-next-line
export const appReducers: ActionReducerMap<AppState, any> = {
  location: userLocationReducers,
  menuData: menuDataReducers,
};
