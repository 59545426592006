<ng-container>
  <span class="text" *ngIf="dataToPass.message">{{ dataToPass.message }}</span>
  <app-wine-card *ngIf="dataToPass.wine" [wine]="dataToPass.wine" [modal]="true"></app-wine-card>
  <div class="rating-inputs">
    <div class="form rating-input">
      <div class="form-group">
        <label class="label" for="note">Add your report here:</label>
        <textarea
          class="input"
          placeholder="Describe the issue you found..."
          rows="7"
          id="note"
          [formControl]="noteControl"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="action-buttons">
    <button class="btn btn--red" (click)="onComplete()">Submit</button>
    <button class="btn btn--gray" (click)="dialogDone.emit()">Cancel</button>
  </div>
</ng-container>
