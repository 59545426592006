import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LOGIN_BAD_CREDENTIALS } from '@constants';
import { User } from '@interfaces';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { getUserLocationUrlPrefix } from '@store/selectors';
import { AppState } from '@store/state';
import { getJWTToken, setJWTToken, setUserProfile } from '@utils';

@Component({
  selector: 'app-google-callback',
  template: '',
})
export class GoogleCallbackComponent {
  private isBrowser: boolean;
  private urlPrefix = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store<AppState>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      this.store.select(getUserLocationUrlPrefix).subscribe((prefix) => {
        this.urlPrefix = prefix;
      });

      this.activatedRoute.queryParams
        .pipe(
          switchMap((params) => {
            return this.authService.googleCallback(params);
          }),
          tap((params) => {
            if (params['access_token']) {
              setJWTToken(params['access_token']);
            }
          }),
          switchMap(() => {
            return getJWTToken() ? this.userService.me() : EMPTY;
          })
        )
        .subscribe({
          next: (user: User) => {
            setUserProfile(user);
            this.userService.userSubject$.next(user);
            if (localStorage.getItem('beforeAuthPage')) {
              this.router.navigateByUrl(localStorage.getItem('beforeAuthPage') as string);
              localStorage.removeItem('beforeAuthPage');
            } else {
              this.router.navigate(['']);
            }
          },
          error: (err: any) => {
            switch (err.error.detail) {
              case LOGIN_BAD_CREDENTIALS:
                this.router.navigate([this.urlPrefix, 'user', 'login']);
                break;
              default:
                console.error(err.error);
            }
          },
        });
    }
  }
}
