import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/* eslint-disable */
@Injectable()
export class HttpServerErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        return throwError(() => this.errorFlows(httpError));
      }),
    );
  }

  errorFlows(httpError: HttpErrorResponse): HttpErrorResponse {
    switch (httpError.status) {
      case 400:
      case 409:
        // httpError.error.errors = this.buildValidationErrors(httpError);
        break;
      case 404:
        this.router.navigate(['/page-not-found']);
        break;
      case 500:
        if (httpError.error.code) {
          console.error(httpError.error.code)
          break;
        }

        console.error('Something went wrong on server')
        break;
      default:
        console.error('Unknown Server Error')
    }

    return httpError;
  }
}

/* eslint-enable */
