import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NewsArticleCardComponent } from '@modules/blog/components/news-article-card/news-article-card.component';
import { PipesModule } from '@shared/pipes.module';

@NgModule({
  declarations: [NewsArticleCardComponent],
  imports: [RouterModule, PipesModule, CommonModule],
  exports: [NewsArticleCardComponent],
})
export class BlogSharedModule {}
