import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import {
  QuickFeedbackSearchQuality,
  ReportDataQualityRequest,
  ReportGrapePageDataQuality,
  ReportRegionPageDataQuality,
  ReportSearchQualityRequest,
  ReportWineryPageDataQuality,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportQualityService {
  constructor(private http: HttpClient) {}

  public reportSearchQuality(data: ReportSearchQualityRequest): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/issues/vintage/search_quality`, data);
  }

  public reportDataQuality(data: ReportDataQualityRequest): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/issues/vintage/data-quality`, data);
  }

  public quickFeedbackForSearchQuality(data: QuickFeedbackSearchQuality): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/issues/vintage/search_feedback`, data);
  }

  public reportGrapePageDataQuality(data: ReportGrapePageDataQuality): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/issues/grape/data-quality`, data);
  }

  public reportWineryPageDataQuality(data: ReportWineryPageDataQuality): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/issues/winery/data-quality`, data);
  }

  public reportRegionPageDataQuality(data: ReportRegionPageDataQuality): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/issues/region/data-quality`, data);
  }
}
