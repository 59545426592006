<div id="main-wrapper" class="main main--initial">
  <div class="content">
    <div class="wrapper">
      <div class="page-title-wrapper">
        <h1>404</h1>
      </div>
      <div class="caption-wrapper">
        <p class="caption">Sorry, we couldn't find this page.</p>
        <p class="sub-caption">
          But don't worry, you can find plenty of other things on our homepage.
        </p>
      </div>
      <div class="button-wrapper">
        <button type="button" class="btn btn--red" (click)="goToHomePage()">
          Back to homepage
        </button>
      </div>
    </div>
  </div>
</div>
