<ng-container *ngIf="_wine; else loading">
  <div
    [ngClass]="{ 'wine-card--vertical': vertical, 'wine-card--modal': modal }"
    (click)="onWineClick(wine, $event)"
    class="wine-card"
    *ngIf="wine"
  >
    <a
      [routerLink]="[this.urlPrefix$ | async, 'wine', _wine.slug]"
      [state]="{ breadcrumb: _wine.title }"
    >
      <div class="wine-card-wrapper wine-card-wrapper--vertical" *ngIf="vertical; else horizontal">
        <div *ngIf="deleteText" class="remove-card" (click)="onDeleteClick($event)"></div>
        <div
          *ngIf="_wine.hasOwnProperty('promo_type')"
          class="wine-label wine-label--{{ _wine.wine_type?.toLowerCase() }}"
        >
          {{ _wine.promo_type }}
        </div>
        <app-wine-image
          [url]="_wine.image"
          [size]="verticalWineImageSize"
          [alt]="'Featured wine bottle from ' + _wine.title"
        ></app-wine-image>
        <div class="wine-name" [class.styled]="moreInfoType !== ''">
          {{ _wine.title }}
        </div>
        <ng-container *ngIf="moreInfoType">
          <ng-container [ngSwitch]="moreInfoType">
            <ng-container *ngSwitchCase="'cellar'">
              <app-wine-rating
                [rating]="_wine.user_rating"
                [votes]="_wine.user_rating_count"
                [oneStar]="true"
                [ratingRank]="_wine.user_rating_rank"
              ></app-wine-rating>
              <div class="content-pairings" *ngIf="!modal">
                <div class="pair-title">Food Pairing:</div>
                <div class="food-pairs">
                  <ng-container *ngIf="_wine.foods?.length; else noPairs">
                    <div class="food-pair" *ngFor="let pair of _wine.foods">{{ pair }}</div>
                  </ng-container>
                  <ng-template #noPairs>
                    <div>{{ '—\t' }}</div>
                  </ng-template>
                </div>
              </div>
              <div class="wine-price">
                {{
                  _wine.price ? '$' + getPriceWithComma(_wine.price.toFixed(2)) : 'Not Available'
                }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'home'">
              <div class="content-rating">
                <div class="content-rating__user">
                  <div class="rating-text">User Rating:</div>
                  <app-wine-rating
                    [rating]="_wine.user_rating"
                    [votes]="_wine.user_rating_count"
                    [oneStar]="true"
                    [ratingRank]="_wine.user_rating_rank"
                  ></app-wine-rating>
                </div>
                <div class="slash"></div>
                <div class="content-rating__expert">
                  <div class="rating-text">Expert Rating:</div>
                  <app-wine-rating
                    [rating]="_wine.expert_rating"
                    [color]="'red'"
                    [oneStar]="true"
                  ></app-wine-rating>
                </div>
              </div>
              <div class="wine-price" *ngIf="_wine.price">
                {{
                  _wine.price ? '$' + getPriceWithComma(_wine.price.toFixed(2)) : 'Not Available'
                }}
              </div>
              <a
                (click)="goToShopClicked($event)"
                [href]="_wine.link_to_shop"
                target="_blank"
                id="wine-card-go-to-shop-button"
                class="btn btn--red go-btn"
                [class.btn--disabled]="!_wine.link_to_shop"
              >
                <div class="shop-icon"></div>
                Go to Shop
              </a>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </a>
    <ng-template #horizontal>
      <div class="wine-card-wrapper">
        <div class="special-labels">
          <div class="special-label special-label--rec" *ngIf="_wine.is_recommended">
            Recommended for you
          </div>
          <div class="special-label special-label--offer" *ngIf="_wine.is_offered">
            Special Offer
          </div>
        </div>
        <div class="card-content">
          <app-wine-image
            [url]="_wine.image"
            [size]="horizontalWineImageSize"
            [alt]="'Bottle of ' + _wine.title + ' from search results'"
          ></app-wine-image>
          <div class="card-content__wrapper">
            <div>
              <h2 class="content-title">
                {{ _wine.title }}
                <span class="natural" *ngIf="_wine.is_natural">Natural</span>
                <!--              <span class="natural fine-wine" *ngIf="_wine.is_natural">FINE WINE</span>-->
              </h2>
              <h3 class="content-subtitle">
                <!--                  [class.link]="_wine.winery_id"-->
                <!--                  (click)="$event.preventDefault(); $event.stopPropagation()">-->
                <!--                <a [routerLink]="[this.urlPrefix$ | async, 'wine', _wine.slug, 'wineries', wine.winery_slug]">-->
                {{ _wine.winery_name }}
                <!--                </a>-->
              </h3>
              <div class="content-details">
                <app-wine-type
                  [type]="{ type: _wine.wine_type, color: _wine.wine_color }"
                ></app-wine-type>
                <app-wine-country
                  [country]="_wine.country"
                  [classList]="['small']"
                ></app-wine-country>
                <a
                  [routerLink]="[
                    this.urlPrefix$ | async,
                    'wine',
                    _wine.slug,
                    'regions',
                    wine.region_slug
                  ]"
                  class="region-name"
                  (click)="onRegionClick($event)"
                >
                  {{ _wine.region }}
                </a>
                <div class="volume" *ngIf="_wine.bottle_volume">Vol: {{ wineVolume }}</div>
              </div>
              <div class="content-rating">
                <div class="content-rating__user">
                  <div class="rating-text">User Rating:</div>
                  <app-wine-rating
                    [rating]="_wine.user_rating"
                    [votes]="_wine.user_rating_count"
                    [ratingRank]="_wine.user_rating_rank"
                  ></app-wine-rating>
                </div>
                <div class="content-rating__expert">
                  <div class="rating-text">Expert Rating:</div>
                  <app-wine-rating [rating]="_wine.expert_rating" [color]="'red'"></app-wine-rating>
                </div>
              </div>
              <div
                class="description"
                (click)="$event.stopPropagation(); $event.preventDefault()"
                [ngClass]="{ 'description-wrap': isMore }"
              >
                <div
                  [class.full-width]="deleteText.length"
                  [ngClass]="{ 'content-description': !isMore }"
                >
                  {{ _wine.description }}
                </div>
                <span
                  *ngIf="_wine.description"
                  class="btn btn--link btn--link__red btn-more"
                  (click)="showMoreDescription($event)"
                >
                  {{ isMore ? 'Show less' : 'Read more' }}
                </span>
              </div>
              <div class="content-pairings" *ngIf="!modal">
                <div class="pair-title">Food Pairing:</div>
                <div class="food-pairs">
                  <ng-container *ngIf="_wine.foods?.length; else noPairs">
                    <div class="food-pair" *ngFor="let pair of _wine.foods">{{ pair }}</div>
                  </ng-container>
                  <ng-template #noPairs>
                    <div>{{ '—\t' }}</div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="content-btns wine-action-buttons" *ngIf="!modal && !embeddedComponent">
              <app-add-favorite
                [wineId]="_wine.id"
                [isFavorite]="_wine.is_favorite"
                (favoriteChanged)="favoriteChanged($event)"
              ></app-add-favorite>
              <app-add-wishlist
                [wishlists]="_wine.wishlists"
                [wineId]="_wine.id"
                (wishlistsChanged)="wishlistsChanged($event)"
              ></app-add-wishlist>
              <app-add-cellar
                [isInWineCellar]="_wine.is_in_wine_cellar"
                [wineId]="_wine.id"
                (stateChanged)="cellarChanged($event)"
              ></app-add-cellar>
            </div>
            <a
              *ngIf="!modal"
              class="btn btn--link btn--link__red learn-more"
              [routerLink]="[this.urlPrefix$ | async, 'wine', _wine.slug]"
              [state]="{ breadcrumb: wine.title }"
              [class.with-delete]="deleteText.length"
            >
              Learn More
            </a>
          </div>
          <div class="card-second-content">
            <div class="card-second-content__top" *ngIf="!modal">
              <div class="wine-price" [class.not-available]="!_wine.price">
                {{
                  _wine.price ? '$' + getPriceWithComma(_wine.price.toFixed(2)) : 'Not Available'
                }}
              </div>
              <ng-container *ngIf="_wine.link_to_shop">
                <a
                  (click)="goToShopClicked($event)"
                  [href]="_wine.link_to_shop"
                  target="_blank"
                  id="wine-card-go-to-shop-button"
                  class="btn btn--red go-btn"
                  [class.btn--disabled]="!_wine.link_to_shop"
                >
                  <div class="shop-icon"></div>
                  Go to Shop
                </a>
                <span class="vintages-available">{{ vintageAvailabilityText }}</span>
              </ng-container>
            </div>
            <a
              *ngIf="deleteText.length"
              class="btn btn--link btn--link__red delete-btn"
              (click)="onDeleteClick($event)"
            >
              {{ deleteText }}
            </a>
            <!--          <span class="visit-date" *ngIf="_wine.view_date">{{ convertISOToDate(_wine.view_date) }}</span>-->
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #loading>
  <app-fake-wine-cards-loading *ngIf="isBrowser"></app-fake-wine-cards-loading>
</ng-template>
