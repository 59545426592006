export const GOOGLE_ANALYTICS_EVENTS = {
  HOME_PAGE: {
    RECOMMENDED_FILTER_SELECTED: 'home_page_recommended_filter_selected',
    SEARCH_START: 'home_page_search_start', //
  },
  FOOTER: {
    LINK_TAPPED: 'footer_link_tapped',
    SUBMIT_FORM: 'footer_news_subscribe_form_submit',
  },
  WINE_PAGE: {
    WINE_DESCRIPTION_READ_MORE_TAPPED: 'wine_page_wine_description_read_more_tapped',
    PROS_CONS_READ_MORE_TAPPED: 'wine_page_pros_cons_read_more_tapped',
    EXPERT_NOTE_READ_MORE_TAPPED: 'wine_page_expert_note_read_more_tapped',
    SIMILAR_WINE_CARD_TAPPED: 'wine_page_similar_wine_card_tapped',
    ADD_TO_FAVORITE_BUTTON_TAPPED: 'wine_page_add_to_favorite_button_tapped',
    ADD_TO_WISHLIST_BUTTON_TAPPED: 'wine_page_add_to_wishlist_button_tapped',
    ADD_TO_CELLAR_BUTTON_TAPPED: 'wine_page_add_to_cellar_button_tapped',
    GO_TO_SHOP_BUTTON_TAPPED: 'wine_page_go_to_shop_button_tapped',
    REPORT_DATA_QUALITY_SUBMITTED: 'wine_page_report_data_quality_submitted',
    SHARE_BUTTON_TAPPED: 'wine_page_share_button_tapped',
    UPLOAD_IMAGE: 'wine_page_upload_image',
  },
  GRAPE_PAGE: {
    TOP_WINES_FILTER_SELECTED: 'grape_page_top_wines_filter_selected',
  },
  WINE_CARD: {
    GO_TO_SHOP_BUTTON_TAPPED: 'wine_card_go_to_shop_button_tapped',
  },
  FIND_MORE_FORM: {
    SUBMIT_FORM_TAPPED: 'find_more_form_submit_tapped',
    WINE_COLOR_CHANGED: 'find_more_form_color_changed',
    PRICE_RANGE_CHANGED: 'find_more_form_price_range_changed',
  },
};
