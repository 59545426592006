import { Component, Input } from '@angular/core';

import { UserFlowPageData } from '@interfaces';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'user-flow-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() data: UserFlowPageData | undefined;

  constructor(private authService: AuthService) {}

  googleLogin() {
    if (!window.location.pathname.includes('user/login')) {
      localStorage.setItem(
        'beforeAuthPage',
        this.data?.urlAfterLogin
          ? `${this.data.urlAfterLogin}`
          : `${window.location.pathname}${window.location.search}`
      );
    }
    this.authService.googleLogin().subscribe((res) => {
      window.location.href = res.authorization_url;
    });
  }
}
