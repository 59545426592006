<ng-container *ngIf="_items !== null && wishlists !== null && !isLoading; else wishlistsLoading">
  <span class="text">{{ isAddNew ? dataToPass.message : 'Create Wishlist' }}</span>
  <ng-container *ngIf="isAddNew && wishlists.length; else input">
    <div class="search-input-wrapper">
      <input class="search-input" placeholder="Search wishlist" [formControl]="searchControl" />
    </div>
    <div class="wishlists-wrapper">
      <div
        class="wishlist"
        *ngFor="let item of _items"
        [class.selected]="item.selected"
        (click)="selectWishlist(item)"
      >
        <div class="wishlist-content">
          <div class="wishlist-info">
            <div class="wishlist-info--name">{{ item.name }}</div>
            <div class="wishlist-info--items-count">
              {{
                item.vintage_count
                  ? item.vintage_count + ' items in the list'
                  : 'No saved items in this list yet'
              }}
            </div>
          </div>
          <div class="wishlist-circle outer">
            <div class="wishlist-circle inner"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!_items.length && searchControl.value.length">
    <span class="not-found-items">No lists found with this name</span>
  </ng-container>
  <ng-template #input>
    <div class="form" (keydown.enter)="onComplete()">
      <div class="form-group">
        <label class="label" for="wishlist-name">Wishlist Name</label>
        <input
          class="input"
          type="text"
          id="wishlist-name"
          placeholder="Enter your new wishlist name"
          [formControl]="wishlistName"
        />
        <div class="validation-error" *ngIf="wishlistName.hasError('exist')">
          {{ wishlistName.errors?.['exist'] || '' }}
        </div>
        <div class="validation-error" *ngIf="wishlistName.hasError('required')">
          {{ wishlistName.errors?.['required'] || '' }}
        </div>
      </div>
    </div>
  </ng-template>
  <ng-container>
    <div class="add-new-wishlist">
      <button
        type="button"
        class="btn btn--link btn--link__red btn--link__red--plus"
        (click)="toggleNewWishlist()"
        [class.btn--link__red--plus-cross]="!isAddNew"
      >
        {{ isAddNew ? 'Add new Wishlist' : 'Select Wishlist' }}
      </button>
    </div>
  </ng-container>
  <div class="action-buttons">
    <button
      class="btn btn--red {{ !wishlists.length ? 'btn--icon btn--icon__plus' : '' }}"
      (click)="onComplete()"
    >
      {{ wishlists.length && isAddNew ? 'Add' : 'Create Wishlist' }}
    </button>
    <button class="btn btn--gray" (click)="dialogDone.emit()">Cancel</button>
  </div>
</ng-container>

<ng-template #wishlistsLoading>
  <app-loader [forceShow]="true"></app-loader>
</ng-template>
