import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {
  //TODO rewrite regexp
  routesForIntercept: RegExp[] = [
    // /\/region\/(\S+|\W)[^/description]$/gi,
    // /\/grape\?name=(\S+|\W)$/gi,
  ];

  constructor(private router: Router) {
    //
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.routesForIntercept.some((str) => new RegExp(str).test(req.url))) {
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body === null) {
              this.router.navigate(['/page-not-found']);
            }
          }

          return event;
        })
      );
    }

    return next.handle(req);
  }
}
