import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { UserLocationData } from '@interfaces';
import { AppState, getUserLocation } from '@store';

@Injectable({
  providedIn: 'root',
})
export class ApiEventsService {
  private userLocation: UserLocationData;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(getUserLocation).subscribe((userLocation) => {
      this.userLocation = userLocation;
    });
  }

  countGoToShopButtonClicks(
    vintage_id: string,
    shop_url: string,
    xSearchId: string | null | undefined = undefined
  ): Observable<unknown> {
    const market_location = {
      market_country: this.userLocation.country,
      market_region: this.userLocation.region,
    };

    return this.http.post(`${environment.API_URL}/events/shop-click`, {
      vintage_id,
      shop_url,
      market_location,
      ...(xSearchId && xSearchId.length ? { search_id: xSearchId } : {}),
    });
  }

  countWineShareButtonClicks(vintage_id: string) {
    const market_location = {
      market_country: this.userLocation.country,
      market_region: this.userLocation.region,
    };

    return this.http.post(`${environment.API_URL}/events/wine-share`, {
      vintage_id,
      market_location,
    });
  }

  countSearchResultClicks(vintage_id: string, result_index: number, xSearchId: string) {
    const market_location = {
      market_country: this.userLocation.country,
      market_region: this.userLocation.region,
    };

    const search_id = xSearchId;

    return this.http.post(`${environment.API_URL}/events/search-result-click`, {
      vintage_id,
      result_index,
      market_location,
      search_id,
    });
  }
}
