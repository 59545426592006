import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  push(event: string, data: { [key: string]: any } = {}): void {
    window.dataLayer.push({ event, ...data });
  }
}
