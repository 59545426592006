import { UserLocationData } from '@interfaces';
import { MenuData } from '@services/menu.service';
import { initialUserLocationData } from 'src/app/store/state/location.state';
import { initialMenuData } from 'src/app/store/state/menu.state';

export interface AppState {
  location: UserLocationData;
  menuData: MenuData;
}

export const initialAppState: AppState = {
  location: initialUserLocationData,
  menuData: initialMenuData,
};
