import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { GOOGLE_ANALYTICS_EVENTS } from '@constants';
import { WineResponse } from '@interfaces';
import { GoogleAnalyticsService } from '@services/app/google-analytics.service';
import { ModalNotificationsService } from '@services/app/modal-notifications.service';
import { ReportQualityService } from '@services/report-quality.service';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-report-quality-dialog',
  templateUrl: './report-quality-dialog.component.html',
  styleUrls: ['./report-quality-dialog.component.scss'],
})
export class ReportQualityDialogComponent {
  @Input() dataToPass: {
    message?: string;
    wine?: WineResponse;
    type?: string;
    resourceSlug?: string;
    ids?: string[];
  };

  @Output() dialogDone: EventEmitter<unknown> = new EventEmitter<unknown>();

  noteControl: FormControl = new FormControl();

  constructor(
    private reportService: ReportQualityService,
    private modalNotificationService: ModalNotificationsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(WINDOW) private window: Window
  ) {}

  onComplete() {
    if (this.dataToPass.wine) {
      this.reportService
        .reportDataQuality({
          vintage_slug: this.dataToPass.wine.slug,
          note: this.noteControl.value,
        })
        .subscribe(() => {
          this.successResponse();
          this.googleAnalyticsService.push(
            GOOGLE_ANALYTICS_EVENTS.WINE_PAGE.REPORT_DATA_QUALITY_SUBMITTED
          );
        });
    } else {
      switch (this.dataToPass.type) {
        case 'region': {
          this.reportService
            .reportRegionPageDataQuality({
              comment: this.noteControl.value,
              region_slug: this.dataToPass.resourceSlug ?? '',
            })
            .subscribe(() => {
              this.successResponse();
            });
          break;
        }
        case 'grape': {
          this.reportService
            .reportGrapePageDataQuality({
              comment: this.noteControl.value,
              grape_slug: this.dataToPass.resourceSlug ?? '',
            })
            .subscribe(() => {
              this.successResponse();
            });
          break;
        }
        case 'winery': {
          this.reportService
            .reportWineryPageDataQuality({
              comment: this.noteControl.value,
              winery_slug: this.dataToPass.resourceSlug ?? '',
            })
            .subscribe(() => {
              this.successResponse();
            });
          break;
        }
        default: {
          this.reportService
            .reportSearchQuality({
              search_parameters: this.window.location.search,
              comment: this.noteControl.value,
              vintage_ids: this.dataToPass.ids || [],
            })
            .subscribe(() => {
              this.successResponse();
            });
        }
      }
    }
  }

  private successResponse(): void {
    this.dialogDone.emit('confirm');
    this.modalNotificationService.showSuccessPopup(
      'Thank you!',
      'We will take the report into an account.'
    );
  }
}
