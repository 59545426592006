import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { BlogArticle, BlogPostRes } from '@interfaces';
import { BlogPostCardImageSize } from 'src/app/constants/image-size.constant';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private http: HttpClient) {}
  /**
   * Returns collection of blogs from API
   * @param {number} limit limitation of results, set to -1 exclude limitation
   * @param {number} page pagination page
   * @param {number} size count of returning items
   * @param {string} exclude for exclude blog by slug from results
   * @returns {Observable<BlogPostRes>}
   */
  getBlogPosts(limit: number, page: number, size: number, exclude = ''): Observable<BlogPostRes> {
    let requestUrl = `${environment.API_URL}/blog/articles/latest?page=${page}&size=${size}&exclude=${exclude}`;

    if (limit > 0) {
      requestUrl = `${requestUrl}&limit=${limit}`;
    }

    return this.http.get<BlogPostRes>(requestUrl).pipe(
      map((res) => ({
        ...res,
        items: res.items.map((item) => ({ ...item, size: BlogPostCardImageSize })),
      }))
    );
  }

  getArticleBySlug(slug: string): Observable<BlogArticle> {
    return this.http.get<BlogArticle>(`${environment.API_URL}/blog/article/${slug}`);
  }
}
