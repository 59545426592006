import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { first, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { WineResponse } from '@interfaces';
import { LoaderService } from '@services/app/loader.service';
import { WineService } from '@services/wine.service';
import { EMBEDDED_WINE_PAGE_KEY } from 'src/transfer-state.keys';

@Injectable()
export class EmbeddedWineCardResolverService implements Resolve<WineResponse> {
  constructor(
    private wineService: WineService,
    @Inject(PLATFORM_ID) private platformId: object,
    private transferState: TransferState,
    private loaderService: LoaderService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<WineResponse> {
    const slug: string | null = route.paramMap.get('slug');

    const WINE_KEY = EMBEDDED_WINE_PAGE_KEY(slug || '');

    if (this.transferState.hasKey(WINE_KEY)) {
      // @ts-ignore
      const wine = this.transferState.get<WineResponse>(WINE_KEY, null);

      return of(wine);
    } else {
      this.loaderService.isLoading$.next(true);
      return this.wineService.getEmbeddedWineBySlug(slug || '').pipe(
        first(),
        tap((wine) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(WINE_KEY, wine);
          }
        })
      );
    }
  }
}
