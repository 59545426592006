import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WineSearchService {
  searchStringChanged: Subject<string> = new Subject<string>();

  wineSearchValue = '';

  actualAPIMetaFoodsValues: string[] | undefined = undefined;
}
