import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, Observable, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '@environment';
import { UserLocationData, WineResponse, Wishlist } from '@interfaces';
import { AppState, getUserLocation } from '@store';
import { getUrlWithParams } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class WishListService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  getWishlistList(): Observable<Wishlist[]> {
    return this.http.get<Wishlist[]>(`${environment.API_URL}/users-profile/wishlists`);
  }

  editWishlistName(name: string, new_name: string): Observable<any> {
    return this.http.put(`${environment.API_URL}/users-profile/wishlists`, { name, new_name });
  }

  createWishlist(name: string): Observable<any> {
    return this.http.post(`${environment.API_URL}/users-profile/wishlists`, { name });
  }

  deleteWishlist(name: string): Observable<any> {
    return this.http.delete(
      `${environment.API_URL}/users-profile/wishlists?name=${encodeURIComponent(name)}`
    );
  }

  getWishlistVintages(name: string): Observable<WineResponse[]> {
    return this.store.pipe(
      select(getUserLocation),
      filter((userLocationData: UserLocationData) => !!userLocationData),
      take(1),
      switchMap((userLocationData: UserLocationData) => {
        return this.http.get<WineResponse[]>(
          getUrlWithParams(
            {
              name,
              market_country: userLocationData.country,
              market_region: userLocationData.region,
            },
            `/users-profile/wishlists/vintages`
          )
        );
      })
    );
  }

  addVintageToWishlist(data: { name: string; vintage_id: string }): Observable<unknown> {
    return this.http.put(`${environment.API_URL}/users-profile/wishlists/vintages`, data);
  }

  deleteVintageFromWishlist(name: string, vintageId: string): Observable<unknown> {
    return this.http.request('DELETE', `${environment.API_URL}/users-profile/wishlists/vintages`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        name,
        vintage_id: vintageId,
      },
    });
  }

  getSharedToken(name: string): Observable<{ shared_token: string }> {
    return this.http.get<{ shared_token: string }>(
      `${environment.API_URL}/users-profile/wishlists/sharing-token?name=${encodeURIComponent(
        name
      )}`
    );
  }

  getSharedWishlistByToken(token: string): Observable<WineResponse[]> {
    return this.http
      .get<{ name: string; vintages: WineResponse[] }>(
        `${environment.API_URL}/shared_wishlists/${token}`
      )
      .pipe(
        map((res) => {
          return res.vintages;
        })
      );
  }
}
