import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  template: ``,
})
export class VerificationComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const token = params['token'];

      if (token) {
        //TODO recheck
        this.router.navigate(['/user/new-password'], { queryParams: { token } });
      } else {
        this.router.navigate(['/user/login']);
      }
    });
  }
}
