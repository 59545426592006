import { MenuData } from '@services/menu.service';
import { MenuDataActions, UPDATE_MENU_DATA } from 'src/app/store/actions';
import { initialMenuData } from 'src/app/store/state';

export const menuDataReducers = (state = initialMenuData, action: MenuDataActions): MenuData => {
  switch (action.type) {
    case UPDATE_MENU_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
