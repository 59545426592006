import { UrlSegment } from '@angular/router';

import { isRouteState } from 'src/app/utils/string.utils';

export class URLMatcher {
  languageMatcher = (url: UrlSegment[]) => {
    if (!url.length) {
      return { consumed: [] };
    }

    if (url[1] && isRouteState(`${url[0].path}/${url[1].path}`)) {
      const consumed = [url[0], url[1]];
      const posParams = { urlPrefix: new UrlSegment(`${url[0].path}/${url[1].path}`, {}) };
      return { consumed, posParams };
    }

    return { consumed: [] };
  };
}

export const CustomURLMatcher = new URLMatcher();
