import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-search-query',
  templateUrl: './header-search-query.component.html',
  styleUrls: ['./header-search-query.component.scss'],
})
export class HeaderSearchQueryComponent {
  @Input() text = '';
  @Input() color = '';
  @Input() urlPrefix = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
}
