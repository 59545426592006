import { isPlatformBrowser } from '@angular/common';
import { AfterContentInit, Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { delay, filter, Observable, startWith, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { STATE_REPLACEMENT_STRING } from '@constants';
import { environment } from '@environment';
import { SeoData } from '@interfaces';
import { SEOService } from '@services/app/seo.service';
import { TopBannerService } from '@services/app/top-banner.service';
import { UserLocationService } from '@services/app/user-location.service';
import { UserService } from '@services/user.service';
import {
  getJWTToken,
  getUserProfile,
  getLocationFromUrl,
  replaceStringForQuery,
  clearArticlePrevPage,
  isNYTheme,
  getTitleRegex,
} from '@utils';
import { REQUEST_URL } from 'src/tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  title = 'wine-recommender-website';
  navigationEvents$: Observable<NavigationEnd>;
  showBreadcrumbs$: Observable<boolean>;
  isEmbeddedView$: Observable<boolean>;
  topBannerImage: string;
  isShowingRouteLoadIndicator: boolean;

  //TODO recheck and refactor. Affecting many pages
  showBreadcrumbsRoutes: string[] = [
    'careers',
    'about-us',
    'terms-of-use',
    'privacy-policy',
    'content-policy',
    'recommendations',
    'partnership',
    '/grape',
    '/region',
    'news-and-articles',
    'sitemap',
    '/wine',
  ];

  shouldRememberPrevPageRoutes: string[] = ['news-and-articles'];

  get isNYThemeAvailable(): boolean {
    return isNYTheme();
  }

  private isBrowser: boolean;

  constructor(
    public topBannerService: TopBannerService,
    private userService: UserService,
    private router: Router,
    private userLocationService: UserLocationService,
    private route: ActivatedRoute,
    private seoService: SEOService,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(REQUEST_URL) private requestUrl: string
  ) {
    this.translateService.currentLang = 'en';
    this.translateService.use('en');
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (getJWTToken() && getUserProfile()) {
      this.userService.me().subscribe((user) => {
        this.userService.userSubject$.next(user);
      });
    }

    this.isShowingRouteLoadIndicator = false;

    if (this.isBrowser) {
      this.userLocationService.getUserLocationData();
    } else {
      if (environment.production) {
        this.seoService.updateFollow();
      }
    }

    this.navigationEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd)
    );

    this.showBreadcrumbs$ = this.navigationEvents$.pipe(
      map((event) => this.showBreadcrumbsRoutes.some((item) => event.url.includes(item)))
    );

    this.isEmbeddedView$ = this.navigationEvents$.pipe(
      map((event) => event.url.includes('/embedded/')),
      tap((isEmbedded) => {
        if (isEmbedded) {
          this.seoService.updateFollow('noindex', 'nofollow');
        }
      })
    );
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((route) => {
          const url = (route as RouterEvent).url;
          const isKeepPrevPage = this.shouldRememberPrevPageRoutes.some((item) =>
            url.includes(item)
          );
          if (!isKeepPrevPage && this.isBrowser) {
            clearArticlePrevPage();
          }
        }),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data)
      )
      .subscribe((event: Data) => {
        if (environment.production) {
          this.seoService.updateFollow();
        }

        const seoParams: SeoData | undefined = event['seo'];

        if (seoParams) {
          let re = new RegExp('');
          let serverMeta;

          if (seoParams.resolver) {
            re = getTitleRegex(seoParams.resolver);
            serverMeta = event[seoParams.resolver]?.['meta'];
          }

          if (serverMeta) {
            serverMeta['title'] && this.seoService.updateTitle(serverMeta['title']);
            serverMeta['description'] &&
              this.seoService.updateDescription(serverMeta['description']);
            serverMeta['keywords'] && this.seoService.updateKeywords(serverMeta['keywords']);
          } else {
            let title = replaceStringForQuery(
              `${
                seoParams.resolver && seoParams.title.includes(`{${seoParams.resolver}}`)
                  ? seoParams.title?.replace(
                      re,
                      seoParams.optionalSubString
                        ? seoParams.optionalSubString(event[seoParams.resolver].title)
                        : event[seoParams.resolver].title
                    )
                  : seoParams.title
              }`
            ).trim();

            const region = getLocationFromUrl(
              this.isBrowser ? window.location.pathname : this.requestUrl
            ).region;

            title = title
              .replace(STATE_REPLACEMENT_STRING, region.length ? ` - ${region}` : '')
              .trim();

            this.seoService.updateTitle(title);

            this.seoService.updateDescription(
              seoParams.resolver && seoParams.description.includes(`{${seoParams.resolver}}`)
                ? seoParams.description?.replace(
                    re,
                    replaceStringForQuery(event[seoParams.resolver].title)
                  )
                : seoParams.description
            );

            seoParams.keywords &&
              this.seoService.updateKeywords(
                seoParams.resolver && seoParams.keywords.includes(`{${seoParams.resolver}}`)
                  ? seoParams.keywords?.replace(
                      re,
                      replaceStringForQuery(event[seoParams.resolver].title)
                    )
                  : seoParams.keywords
              );

            if (seoParams.disableIndex) {
              this.seoService.updateFollow('noindex');
            }
          }
        }
      });
  }

  ngAfterContentInit() {
    this.topBannerService.topBannerImage$
      .pipe(
        startWith(''),
        delay(0),
        tap((src) => (this.topBannerImage = src))
      )
      .subscribe();
  }
}
