<div class="home-page">
  <section class="top-section">
    <div class="main">
      <div class="home-page-wrapper">
        <div class="page-header">
          <h1>Let’s find your Favorite Wine!</h1>
          <div class="page-description">
            Please fill in one or more fields below and describe in detail your perfect wine that
            you want to find.
          </div>
        </div>
        <div class="search-section">
          <div class="search-section-wrapper">
            <form class="form" [formGroup]="searchForm" (keydown.enter)="goToRecommendation()">
              <app-autocomplete-input
                [label]="'Describe your wine'"
                [placeholder]="'Describe your perfect wine...'"
                [formGroup]="searchForm"
                [fcName]="'query'"
                (onKeyEnter)="goToRecommendation()"
              ></app-autocomplete-input>

              <div class="form-group--row space-between">
                <div class="form-group wine-type">
                  <label class="label" for="wine-color">Wine Color</label>
                  <ng-select
                    formControlName="wine_colors"
                    [labelForId]="'wine-color'"
                    [placeholder]="'Any'"
                    [searchable]="false"
                    [clearable]="true"
                    id="wine-color"
                    class="wine-color wine_{{ getWineColorClass() }}"
                  >
                    <ng-option *ngFor="let option of wineColors" [value]="option.value">
                      {{ option.label }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group wine-type">
                  <label class="label" for="wine-type">Wine Type</label>
                  <ng-select
                    formControlName="wine_types"
                    [labelForId]="'wine-type'"
                    [placeholder]="'Any'"
                    [searchable]="false"
                    [clearable]="true"
                    id="wine-type"
                    class="wine-color wine_{{ getWineTypeClass() }}"
                  >
                    <ng-option *ngFor="let option of wineTypes" [value]="option.value">
                      {{ option.label }}
                    </ng-option>
                  </ng-select>
                </div>

                <div class="form-group">
                  <label class="label" for="country">Country</label>
                  <ng-select
                    formControlName="countries"
                    [labelForId]="'country'"
                    [placeholder]="'Any'"
                    [clearable]="false"
                    id="country"
                  >
                    <ng-container *ngFor="let country of countries">
                      <ng-option [value]="country">{{ country }}</ng-option>
                    </ng-container>
                  </ng-select>
                </div>

                <div class="form-group" *ngIf="rangeData.histogram">
                  <label class="label">Price Range</label>
                  <app-custom-ngx-slider
                    [histogram]="rangeData.histogram || []"
                    [max]="rangeData.rangeMaxValue!"
                    [min]="rangeData.rangeMinValue!"
                    [rangeOptions]="rangeData.filterRangeOptions"
                    [initValues]="rangeData.rangeInitValues"
                    (onRangeChanged)="onRangeChanged($event)"
                  ></app-custom-ngx-slider>
                </div>

                <button
                  type="button"
                  class="btn btn--red mt-5 ml-10"
                  (click)="goToRecommendation()"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
        <a
          *ngIf="isNYThemeAvailable"
          [routerLink]="[urlPrefix$ | async, 'recommendations']"
          [queryParams]="{ 'page-key': XMAS_VINTAGE_SOURCE | seoUrlFormat }"
          class="new-year-banner"
        >
          <div class="new-year-banner__content">
            <span class="new-year-banner__text">VinoVoss Christmas Top Wine Selection</span>
            <button type="button" class="btn btn--red">Explore</button>
          </div>
        </a>
        <div class="how-works">
          <h2>How VinoVoss works:</h2>
          <div class="cards">
            <div class="card-item" *ngFor="let item of howWorksItems">
              <i class="card-item__icon card-item__icon--{{ item.icon }}"></i>
              <span class="card-item__name">{{ item.title }}</span>
              <ul class="card-item__list">
                <li *ngFor="let searchItem of item.dataName | async">
                  <a
                    [routerLink]="[urlPrefix$ | async, 'recommendations']"
                    [queryParams]="{ query: searchItem | seoUrlFormat : false }"
                    [class.clickable]="item.clickable"
                    [class.example]="item.title === 'Examples'"
                    class="works-item"
                  >
                    {{ searchItem }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="secondary-section">
    <div class="main main--initial">
      <section class="section">
        <h2>Recommended Wines {{ locationInfo }}</h2>
        <app-wine-search-switcher
          [filters]="recommendedWineFilters"
          (select)="onSelectRecommended($event)"
        ></app-wine-search-switcher>
        <div class="recommends recommends--vertical">
          <ng-container *ngIf="promoWines && promoWines.length; else noResult">
            <ng-container *ngFor="let wine of promoWines">
              <app-wine-card
                [routerLink]="[this.urlPrefix$ | async, 'wine', wine.slug]"
                [wine]="wine"
                [moreInfoType]="'home'"
                [vertical]="true"
              ></app-wine-card>
            </ng-container>
          </ng-container>
          <ng-template #noResult>
            <span *ngIf="!(loaderService.isLoading$ | async)" class="no-result">No results</span>
          </ng-template>
        </div>
      </section>
      <section class="section">
        <h2>News & Articles</h2>

        <div class="news-and-articles" *ngIf="blogPosts.length">
          <app-news-article-card
            *ngFor="let post of blogPosts"
            [post]="post"
          ></app-news-article-card>
        </div>
      </section>
    </div>
  </div>
</div>
