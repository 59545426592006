<div class="wrapper">
  <div class="rating-wrapper">
    <div
      *ngFor="let item of starCount; let i = index"
      class="star"
      [style]="'--stars-width:' + size + 'px'"
      [class.clickable]="clickable"
      (click)="onStarClick(i + 1)"
    >
      <span class="{{ color }}" [style.width]="!oneStar ? getStarWidth(i + 1) : '100%'"></span>
    </div>
  </div>
  <div class="rating {{ color }}" *ngIf="!starsOnly">
    {{ rating?.toFixed(1) || 0 }}
  </div>
  <div class="votes" *ngIf="!starsOnly && votes && !ratingRank">
    {{
      !isString ? '(' + getNumberWithCommas(votes) + ')' : getNumberWithCommas(votes) + ' Reviews'
    }}
  </div>
  <div class="votes" *ngIf="ratingRank">
    {{ ratingRankMap }}
  </div>
</div>
