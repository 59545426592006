import { STATES_LIST } from 'src/app/constants/states.constant';

export const numberWithCommas = (x: string | number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceStringForQuery = (string: string): string => {
  return string.replace(/-/g, ' ').trim();
};

export const isRouteState = (string: string): boolean => {
  return /^([a-z]{2}(?:-[a-zA-Z]{2})?\/[a-z]{2})/.test(string);
};

export const getLocationFromUrl = (pathname: string): { country: string; region: string } => {
  const match = pathname.split('/').filter((item) => item.length);

  if (isRouteState(`${match[0]}/${match[1]}`)) {
    if (match[0].toLowerCase().includes('us')) {
      const matchSplit = match[0].split('-');
      const state = matchSplit[1] ? match[0].split('-')[1].toLowerCase() : '';

      return {
        country: 'USA',
        region: STATES_LIST.find((item) => item.value.toLowerCase() === state)?.name || '',
      };
    } else {
      return {
        country: match[0].toLowerCase(),
        region: '',
      };
    }
  }

  return {
    country: '',
    region: '',
  };
};

export const convertBlogDateToISO = (date: string): string => {
  const d = new Date(date);
  const monthWithDate = `${d.toLocaleString('default', { month: 'short' })} ${d.getDate()}`;

  if (d.getFullYear() === new Date().getFullYear()) {
    return monthWithDate;
  }

  return `${monthWithDate}, ${d.getFullYear()}`;
};

export const convertValueToWineVolume = (value: number): string => {
  return value / 1000 >= 1 ? `${value / 1000} L` : `${value} mL`;
};

export const addCommaSeparator = (value: string | number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getRoseWineTypeMap = (type: string): string => {
  return type.toLowerCase() === 'rose' ? 'Rosé' : type;
};

export const getTextFirstSentence = (text: string): string => {
  return text ? text.split('. ', 1)[0] : text;
};
