export type UserMeuType = {
  id: string;
  title: string;
  path: string;
};

export const USER_MENU: UserMeuType[] = [
  { id: '1', title: 'Favorites', path: 'favorites' },
  { id: '3', title: 'Reviews & Ratings', path: 'user-review-and-ratings' },
  { id: '4', title: 'Wishlists', path: 'wishlist' },
  { id: '5', title: 'My Cellar', path: 'user-wine-celler' },
  { id: '6', title: 'Browse History', path: 'browser-history' },
  { id: '7', title: 'Account Settings', path: 'account' },
];
