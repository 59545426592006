import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { WINE_TYPE_STILL } from '@constants';
import { WineTypeColor } from '@interfaces';
import { getRoseWineTypeMap, getWineClassByColorType } from '@utils';

@Component({
  selector: 'app-wine-type',
  templateUrl: './wine-type.component.html',
  styleUrls: ['./wine-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WineTypeComponent {
  @Input() type: WineTypeColor = {
    type: null,
    color: null,
  };
  @Input() bold = false;
  @Input() semiBold = false;
  @Input() color = '#686868';

  getWineTypeName(type: WineTypeColor): string {
    const wineType = type.color && type.type === WINE_TYPE_STILL ? '' : type.type;

    return `${getRoseWineTypeMap(type.color || '')} ${wineType || ''} Wine`
      .replace(/\s+/g, ' ')
      .trim();
  }

  getTypeClass(type: WineTypeColor): string {
    return getWineClassByColorType(type);
  }
}
