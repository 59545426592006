import { Component, Input } from '@angular/core';

import { WINE_FAKE_LINES_THEME_DATA, WINE_FAKE_LINES_VERTICAL_THEME_DATA } from '@constants';
import { FakeCardAlignment } from '@interfaces';

@Component({
  selector: 'app-fake-wine-cards-loading',
  templateUrl: './fake-wine-cards-loading.component.html',
  styleUrls: ['./fake-wine-cards-loading.component.scss'],
})
export class FakeWineCardsLoadingComponent {
  @Input() alignment: FakeCardAlignment = 'horizontal';
  get isHorizontal(): boolean {
    return this.alignment === 'horizontal';
  }

  fakeLinesThemeData = WINE_FAKE_LINES_THEME_DATA;
  fakeLinesVerticalThemeData = WINE_FAKE_LINES_VERTICAL_THEME_DATA;
}
