<div class="user-flow-card-wrapper" [class.modal]="modal">
  <div class="user-flow-card-content">
    <user-flow-page-header [data]="pageData"></user-flow-page-header>

    <form class="form" [formGroup]="loginForm" (keydown.enter)="login()">
      <div class="form-group">
        <label class="label" for="email">E-mail address</label>
        <input
          class="input"
          type="text"
          id="email"
          placeholder="Enter your e-mail address"
          formControlName="username"
        />
        <div class="validation-error" *ngIf="loginForm.get('username')?.hasError('required')">
          This field must be filled
        </div>
        <div class="validation-error" *ngIf="loginForm.get('username')?.hasError('email')">
          Invalid email format
        </div>
      </div>
      <div class="form-group">
        <label class="label" for="password">Password</label>
        <input
          class="input"
          type="password"
          id="password"
          placeholder="Enter your password"
          formControlName="password"
        />
        <span class="eye-icon" appShowPassword></span>
        <div class="validation-error" *ngIf="loginForm.get('password')?.hasError('required')">
          This field must be filled
        </div>
        <div class="validation-error" *ngIf="loginForm.get('password')?.hasError('badCredentials')">
          Invalid email or password
        </div>
      </div>
      <div class="form-group">
        <div class="checkbox-group">
          <input class="checkbox" type="checkbox" id="subscribe" formControlName="stay_login" />
          <label class="checkbox--label" for="subscribe">Keep me logged in</label>
        </div>
      </div>
    </form>
    <div class="forgot-password">
      <a class="btn btn--link btn--link__red" (click)="goToForgotPassword()">
        Forgot your password?
      </a>
    </div>
    <div class="submit-button">
      <button type="button" class="btn btn--red" (click)="login()">Log in</button>
    </div>
    <div class="additional-action">
      <span class="action-text">Don’t have an account?</span>
      <a class="btn btn--link" (click)="goToSignUp()">Sign up</a>
    </div>
  </div>
</div>
