import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { EmbeddedWineCardComponent } from '@modules/embedded/embedded-wine-card/embedded-wine-card.component';

@Injectable({ providedIn: 'root' })
export class EmbeddedWineCardDeactivateGuard implements CanDeactivate<EmbeddedWineCardComponent> {
  canDeactivate(): Observable<boolean> | boolean {
    return false;
  }
}
