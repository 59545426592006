import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { ChangeWishlistData, ChangeWishlistDialogDone, Wishlist } from '@interfaces';
import { WishListService } from '@services/wish-list.service';

export interface IItem extends Wishlist {
  selected?: boolean;
}

@Component({
  selector: 'app-change-wishlist-dialog',
  templateUrl: './change-wishlist-dialog.component.html',
  styleUrls: ['./change-wishlist-dialog.component.scss'],
})
export class ChangeWishlistDialogComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() dataToPass: ChangeWishlistData;

  @Output() dialogDone: EventEmitter<ChangeWishlistDialogDone | undefined> = new EventEmitter<
    ChangeWishlistDialogDone | undefined
  >();

  wishlists: Wishlist[] | null = null;

  _items: IItem[] | null = null;

  searchControl: FormControl = new FormControl('');

  isLoading = false;

  isAddNew = true;

  wishlistName: FormControl = new FormControl('');

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private wishlistService: WishListService, private cdr: ChangeDetectorRef) {
    this.searchControl.valueChanges.subscribe((string) => {
      this._items =
        this.wishlists?.filter((wishlist) => wishlist.name.includes(string)) ?? this.wishlists;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.wishlistService.getWishlistList().subscribe({
      next: (res) => {
        this.wishlists = res;
        this._items = res.map((item) => {
          return {
            ...item,
            selected: !!this.dataToPass.passedWishlists.find(
              (wishlist) => wishlist.name === item.name
            ),
          };
        });

        if (this.wishlists.length) {
          this.dataToPass.message = '';
        }
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  onComplete() {
    if (!this._items?.length) {
      this.createWishlist(true);

      return;
    }

    if (!this.isAddNew) {
      this.createWishlist();

      return;
    }

    const wishlistsForDelete =
      this.dataToPass.passedWishlists.filter((wishlist) => {
        return !this._items?.find((i) => i.name === wishlist.name)?.selected;
      }) ?? [];

    const wishlistsForAdd =
      this._items?.filter((wishlist) => {
        return (
          wishlist.selected &&
          !this.dataToPass.passedWishlists.find((i) => i.name === wishlist.name)
        );
      }) ?? [];

    this.dialogDone.emit({
      wishlistsForAdd,
      wishlistsForDelete,
      event: 'api_success',
    });
  }

  selectWishlist(wishlist: Wishlist): void {
    this._items =
      this._items?.map((item) => {
        return item.name === wishlist.name ? { ...item, selected: !item.selected } : item;
      }) ?? [];
  }

  toggleNewWishlist(): void {
    this.isAddNew = !this.isAddNew;
    this.searchControl.reset('');
  }

  createWishlist(disableToggle = false): void {
    if (!this.wishlistName.value.length) {
      this.wishlistName.setErrors({ required: 'Wishlist name is required.' });
      return;
    }

    this.wishlistService
      .createWishlist(this.wishlistName.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const createdWishlist = {
            name: this.wishlistName.value,
            vintage_count: 0,
          };

          this._items = [...(this._items as IItem[]), createdWishlist];

          if (!this.wishlists) {
            this.wishlists = [];
          }

          this.wishlists.push(createdWishlist);
          this.wishlistName.reset();

          if (!disableToggle) {
            this.toggleNewWishlist();
          }

          this.isLoading = false;
        },
        error: (err) => {
          if (err.status === 417) {
            setTimeout(() => {
              this.wishlistName.setErrors({ exist: err.error.detail });
            });
          }

          this.isLoading = false;
        },
      });
  }
}
