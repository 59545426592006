import { environment } from '@environment';

export const getUrlWithParams = (query: any, endpoint: string): string => {
  const url = new URL(`${environment.API_URL}${endpoint}`);
  const params = new URLSearchParams(url.search.slice(1));
  Object.keys(query).forEach((key) => {
    params.append(key, query[key]);
  });

  return url.href + '?' + params.toString();
};
