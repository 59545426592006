import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { mergeMap, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '@environment';
import {
  LoginRequest,
  LoginResponse,
  NewPasswordRequest,
  RefreshToken,
  RegisterRequest,
  TokensResponse,
  User,
} from '@interfaces';
import {
  clearJWTToken,
  clearRefreshToken,
  clearUserProfile,
  getBrowserStorageUserLocation,
  getRefreshToken,
  getUserProfile,
  setRefreshToken,
} from '@utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*',
  });

  private options = { headers: this.headers };

  constructor(
    private http: HttpClient,
    private notifierService: NotifierService,
    private router: Router
  ) {}

  login(data: LoginRequest): Observable<TokensResponse> {
    const body = new HttpParams({
      fromObject: {
        username: data.username,
        password: data.password,
      },
    });

    return this.http
      .post<LoginResponse>(`${environment.API_URL}/auth/jwt/login`, body.toString(), this.options)
      .pipe(
        mergeMap((loginData) =>
          this.getRefreshToken(loginData).pipe(
            map((response) => ({ ...loginData, refresh_token: response.token }))
          )
        )
      );
  }

  getRefreshToken(loginData: LoginResponse): Observable<RefreshToken> {
    return this.http
      .post<RefreshToken>(
        `${environment.API_URL}/auth/refresh/new`,
        {},
        { headers: new HttpHeaders({ Authorization: `Bearer ${loginData.access_token}` }) }
      )
      .pipe(
        tap((response) => {
          setRefreshToken(response.token);
        })
      );
  }

  updateAccessToken(): Observable<TokensResponse> {
    return this.http
      .post<TokensResponse>(`${environment.API_URL}/auth/refresh/use`, {
        token: getRefreshToken(),
        email: getUserProfile().email,
      })
      .pipe(
        catchError((error) => {
          this.logout();
          clearUserProfile();
          clearJWTToken();
          clearRefreshToken();
          this.notifierService.notify('warning', 'Session expired. You are logged out.');
          this.router.navigate([getBrowserStorageUserLocation().url_prefix, 'user', 'login']);
          return throwError(() => error);
        })
      );
  }

  logout(): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/auth/jwt/logout`, {});
  }

  googleLogin(): Observable<{ authorization_url: string }> {
    return this.http.get<{ authorization_url: string }>(
      `${environment.API_URL}/auth/google/authorize?scopes=email&scopes=profile`
    );
  }

  googleCallback(params: any): Observable<TokensResponse> {
    return this.http
      .get<LoginResponse>(
        `${environment.API_URL}/auth/google/callback?code=${params.code}&state=${params.state}`
      )
      .pipe(
        mergeMap((loginData) =>
          this.getRefreshToken(loginData).pipe(
            map((response) => ({ ...loginData, refresh_token: response.token }))
          )
        )
      );
  }

  register(data: RegisterRequest): Observable<User> {
    return this.http.post<User>(`${environment.API_URL}/auth/register`, data);
  }

  forgotPassword(email: string): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/auth/forgot-password`, { email });
  }

  resetPassword(data: NewPasswordRequest): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}/auth/reset-password`, data);
  }
}
