import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter, Subject, switchMap, takeUntil } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ITEMS_PER_PAGE } from '@constants';
import { MenuService } from '@services/menu.service';
import { QueryAutoCompleteService } from '@services/query-auto-complete.service';
import { getUserLocationUrlPrefix } from '@store/selectors';
import { AppState } from '@store/state';
import { setSearchQueryKey } from '@utils';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;

  @Input() label = '';
  @Input() placeholder = '';
  @Input() formGroup: FormGroup;
  @Input() fcName = '';

  @Output() onKeyEnter: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClearClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  wineTitles: string[] | undefined;
  topQueriesItems: any;
  urlPrefix = '';
  autocompleteHidden = true;
  autocompleteLoading = false;

  private readonly topSearchQueryColors: string[] = ['#DA798A', '#EAE7BB', '#F8C27B'];

  private destroy$: Subject<void> = new Subject<void>();

  get inputValue(): string {
    return this.formGroup?.get(this.fcName)?.value;
  }

  get arrayForSkeleton(): unknown[] {
    return [...Array(3).keys()];
  }
  constructor(
    private queryAutocompleteService: QueryAutoCompleteService,
    private menuService: MenuService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.select(getUserLocationUrlPrefix).subscribe((prefix) => (this.urlPrefix = prefix));

    this.formGroup
      .get(this.fcName)
      ?.valueChanges.pipe(
        tap((searchQuery) => {
          if (!searchQuery.length) {
            this.wineTitles = [];
            this.autocompleteHidden = true;
          }

          this.autocompleteLoading = true;
        }),
        filter((searchQuery) => searchQuery.length),
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        switchMap((searchQuery) => this.queryAutocompleteService.searchAutoSuggest(searchQuery)),
        map((result) => result.wine_title)
      )
      .subscribe({
        next: (result) => {
          this.wineTitles = result.map((item) => item.text);
          this.autocompleteHidden = false;
          this.autocompleteLoading = false;
          setTimeout(() => this.cdr.detectChanges());
        },
        error: () => (this.autocompleteLoading = false),
      });

    this.menuService
      .getTopSearchQueries(3)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.topQueriesItems = res.map((item, index) => {
          return { text: item, color: this.topSearchQueryColors[index] };
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clearInput(event: MouseEvent): void {
    this.formGroup.get(this.fcName)?.setValue('');
    this.onClearClick.emit(event);
  }

  toggleAutoComplete(forceHide = false): void {
    if (forceHide) {
      this.autocompleteHidden = true;
      return;
    }

    this.autocompleteHidden = !this.autocompleteHidden;

    if (!this.wineTitles) {
      this.formGroup.get(this.fcName)?.patchValue(this.inputValue, { emitEvent: true });
    }
  }

  handlePressEnter(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleAutoComplete(true);

    this.onKeyEnter.emit();
  }

  handleLinkClick(event: MouseEvent, text = ''): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleAutoComplete(true);

    if (text.length) {
      this.formGroup.get(this.fcName)?.patchValue(text, { emitEvent: false });
      this.onKeyEnter.emit();
    }
  }

  handleSeeAllClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleAutoComplete(true);

    this.router
      .navigate([this.urlPrefix, 'recommendations'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          query: this.inputValue,
          size: ITEMS_PER_PAGE,
        },
      })
      .then(() => setSearchQueryKey(window.location.search));
  }
}
