import { SeoPageData } from '@interfaces';
import {
  CURRENT_WISHLIST_PAGE_RESOLVER,
  GRAPE_PAGE_RESOLVER,
  HOME_PAGE_RESOLVER,
  NOT_FOUND_PAGE_RESOLVER,
  REGION_PAGE_RESOLVER,
  SEARCH_RESULT_PAGE_RESOLVER,
  WINE_PAGE_RESOLVER,
  WINERY_PAGE_RESOLVER,
} from 'src/app/constants/resolvers.constant';

export const ABOUT_US_PAGE = 'about-us-page';
export const CONTACT_US_PAGE = 'contact-us-page';
export const CAREERS_PAGE = 'careers-page';
export const NEWS_ARTICLES_PAGE = 'news-articles-page';
export const PARTNERSHIP_PAGE = 'partnership-page';
export const TERMS_OF_USE_PAGE = 'terms-of-use-page';
export const PRIVACY_POLICY_PAGE = 'privacy-policy-page';
export const CONTENT_POLICY_PAGE = 'content-policy-page';
export const SITEMAP_PAGE = 'sitemap-page';
export const LOGIN_PAGE = 'login-page';
export const SIGNUP_PAGE = 'sign-up-page';
export const FORGOT_PASSWORD_PAGE = 'forgot-password-page';
export const NEW_PASSWORD_PAGE = 'new-password-page';
export const ACCOUNT_SETTINGS_PAGE = 'account-settings-page';
export const BROWSER_HISTORY_PAGE = 'browser-history-page';
export const FAVORITES_WINES_PAGE = 'favorites-wines-page';
export const REVIEWED_AND_RATED_WINES_PAGE = 'review-and-ratings-page';
export const WINE_CELLAR_PAGE = 'wine-cellar-page';
export const HANDPICK_FOR_YOU_PAGE = 'handpick-page';
export const WISHLISTS_PAGE = 'wishlists-page';

export const STATE_REPLACEMENT_STRING = '{state}';

export const PROD_LOCATION_ORIGIN = 'https://vinovoss.com';

export const SEO_PAGE_DATA: SeoPageData = {
  [HOME_PAGE_RESOLVER]: {
    title: `Discover & Buy Wines Online | VinoVoss ${STATE_REPLACEMENT_STRING}`,
    description:
      'Explore a vast selection of wines with VinoVoss. Discover and buy the best wines online, tailored to your taste. Your journey to the perfect wine starts here.',
    keywords: 'wine, buy wine online, best wines, wine recommendations, VinoVoss',
    resolver: HOME_PAGE_RESOLVER,
  },
  [WINE_PAGE_RESOLVER]: {
    title: `{${WINE_PAGE_RESOLVER}} | VinoVoss ${STATE_REPLACEMENT_STRING}`,
    description: `Explore {${WINE_PAGE_RESOLVER}} in detail on VinoVoss. Dive into our tasting notes, detailed descriptions, and weigh the pros and cons to make an informed choice for your palate.`,
    keywords: `{${WINE_PAGE_RESOLVER}}, wine tasting notes, wine pairings, wine prices, VinoVoss`,
    resolver: WINE_PAGE_RESOLVER,
  },
  [GRAPE_PAGE_RESOLVER]: {
    title: `{${GRAPE_PAGE_RESOLVER}} Varieties | VinoVoss ${STATE_REPLACEMENT_STRING}`,
    description: `All about {${GRAPE_PAGE_RESOLVER}}. Understand the characteristics, taste profiles, and best wines made from this grape. VinoVoss is your guide to grape varieties.`,
    keywords: `{${GRAPE_PAGE_RESOLVER}}, grape characteristics, wine grape types, best wines, VinoVoss`,
    resolver: GRAPE_PAGE_RESOLVER,
  },
  [REGION_PAGE_RESOLVER]: {
    title: `{${REGION_PAGE_RESOLVER}} Wines | VinoVoss ${STATE_REPLACEMENT_STRING}`,
    description: `Discover the essence of {${REGION_PAGE_RESOLVER}} wines. Explore the history, popular varieties, and unique characteristics of wines from this region on VinoVoss.`,
    keywords: `{${REGION_PAGE_RESOLVER}}, wine regions, regional wine varieties, wine history`,
    resolver: REGION_PAGE_RESOLVER,
  },
  [WINERY_PAGE_RESOLVER]: {
    title: `{${WINERY_PAGE_RESOLVER}} Varieties | VinoVoss ${STATE_REPLACEMENT_STRING}`,
    description: `Delve into the rich history and offerings of {${WINERY_PAGE_RESOLVER}}. From their signature wines to their unique story, get acquainted with {${WINERY_PAGE_RESOLVER}} on VinoVoss.`,
    keywords: `{${WINERY_PAGE_RESOLVER}}, winery history, winery wines, popular wineries, VinoVoss`,
    resolver: WINERY_PAGE_RESOLVER,
  },
  [SEARCH_RESULT_PAGE_RESOLVER]: {
    title: `{${SEARCH_RESULT_PAGE_RESOLVER}} | VinoVoss ${STATE_REPLACEMENT_STRING}`,
    description: `Results for {${SEARCH_RESULT_PAGE_RESOLVER}}. Dive into a curated selection of wines that match your search. Find, compare, and purchase with ease at VinoVoss.`,
    keywords: `wine search, {${SEARCH_RESULT_PAGE_RESOLVER}}, wine results, find wine, wine finder`,
    resolver: SEARCH_RESULT_PAGE_RESOLVER,
    optionalSubString: (query: string) => {
      return query.length ? query : 'Wine Results';
    },
  },
  [ABOUT_US_PAGE]: {
    title: `About VinoVoss - The Ultimate Wine Discovery`,
    description: `Dive into the story of VinoVoss. Discover our passion for wine, our journey, and meet the experts who make it all possible.`,
    keywords: `About VinoVoss, VinoVoss history, wine journey, wine experts, our team`,
  },
  [CONTACT_US_PAGE]: {
    title: `Contact VinoVoss - Reach Out to Our Team`,
    description: `Need assistance. Get in touch with the VinoVoss team today.`,
    keywords: `Contact VinoVoss, wine queries, VinoVoss help, support, location`,
  },
  [CAREERS_PAGE]: {
    title: `VinoVoss Careers - Join Our Passionate Team`,
    description: `Become part of the VinoVoss family. Explore exciting career opportunities in the world of AI and wine.`,
    keywords: `VinoVoss careers, wine jobs, join VinoVoss, wine industry careers, Technology, AI`,
  },
  [NEWS_ARTICLES_PAGE]: {
    title: 'Latest Wine News & Articles | VinoVoss',
    keywords: 'wine news, wine articles, latest wine updates, wine insights, VinoVoss',
    description:
      'Stay updated with the latest in the wine world. From industry news to insightful articles, VinoVoss brings you timely and relevant wine content.',
  },
  [PARTNERSHIP_PAGE]: {
    title: 'Partner with VinoVoss - Collaborate in the World of Wines',
    keywords: 'VinoVoss partnership, wine collaborations, wine merchants, affiliate',
    description:
      'Enhance your business by partnering with VinoVoss. Explore collaboration and growth opportunities in the wine industry.',
  },
  [TERMS_OF_USE_PAGE]: {
    title: 'VinoVoss Terms of Use - Usage Policies & Guidelines',
    keywords: 'VinoVoss terms, usage guidelines, website policies',
    description: "Understand the terms and conditions of using VinoVoss's platform.",
    disableIndex: true,
  },
  [PRIVACY_POLICY_PAGE]: {
    title: 'VinoVoss Privacy Policy - Your Data & Our Commitment',
    keywords: 'VinoVoss privacy, data protection, user data, cookies',
    description:
      'At VinoVoss, we value your privacy. Learn about our policies and how we protect and utilize your data.',
    disableIndex: true,
  },
  [CONTENT_POLICY_PAGE]: {
    title: 'VinoVoss Content Policy - Guidelines for Our Community',
    keywords: 'VinoVoss content, posting guidelines, community rules',
    description:
      "Ensure a positive wine discovery experience for all. Familiarize yourself with VinoVoss's content guidelines and community standards.",
    disableIndex: true,
  },
  [SITEMAP_PAGE]: {
    title: 'VinoVoss Sitemap - Navigate Our Wine World',
    keywords: 'VinoVoss sitemap, website navigation, page listings',
    description:
      "Find your way around VinoVoss. Browse our sitemap for a detailed overview of our platform's sections and pages.",
  },
  [LOGIN_PAGE]: {
    title: 'Log In | VinoVoss',
    keywords: 'VinoVoss login, login VinoVoss, authorize VinoVoss',
    description: 'Login into VinoVoss system',
  },
  [SIGNUP_PAGE]: {
    title: 'Sign Up | VinoVoss',
    keywords: 'VinoVoss sign up, VinoVoss registration, create user VinoVoss',
    description: 'Register with VinoVoss',
  },
  [FORGOT_PASSWORD_PAGE]: {
    title: 'Password Reset | VinoVoss',
    keywords: 'VinoVoss forgot password, password',
    description: 'Restore password of VinoVoss account',
  },
  [NEW_PASSWORD_PAGE]: {
    title: 'Update Password | VinoVoss',
    keywords: 'VinoVoss update password, reset password VinoVoss',
    description: 'Update password of VinoVoss account',
  },
  [ACCOUNT_SETTINGS_PAGE]: {
    title: 'Account Settings | VinoVoss',
    keywords: 'VinoVoss account, VinoVoss settings',
    description: 'Change Account settings of VinoVoss account',
  },
  [BROWSER_HISTORY_PAGE]: {
    title: 'Browsing History | VinoVoss',
    keywords: 'history, visited wines, wines VinoVoss',
    description: 'Check visited wines pages of VinoVoss website',
  },
  [FAVORITES_WINES_PAGE]: {
    title: 'My Favorite Wines | VinoVoss',
    keywords: 'favourites, wines, saved wines, VinoVoss',
    description: 'My favorite and saved wines in VinoVoss website',
  },
  [REVIEWED_AND_RATED_WINES_PAGE]: {
    title: 'My Reviewed Wines | VinoVoss',
    keywords: 'review, wines, reviewed wines, VinoVoss',
    description: 'My reviewed wines in VinoVoss website',
  },
  [WINE_CELLAR_PAGE]: {
    title: 'My Wine Cellar | VinoVoss',
    keywords: 'cellar, wine, VinoVoss',
    description: 'My Wine Cellar in VinoVoss website',
  },
  [WISHLISTS_PAGE]: {
    title: 'My Wishlists | VinoVoss',
    keywords: 'wishlists, create wines wishlist, VinoVoss',
    description: 'Wishlists with wines in VinoVoss website',
  },
  [CURRENT_WISHLIST_PAGE_RESOLVER]: {
    title: `{${CURRENT_WISHLIST_PAGE_RESOLVER}} Wishlist | VinoVoss`,
    description: `My wishlist with wines in VinoVoss website`,
    keywords: `wishlists, create wines wishlist, VinoVoss`,
    resolver: CURRENT_WISHLIST_PAGE_RESOLVER,
  },
  [NOT_FOUND_PAGE_RESOLVER]: {
    title: `Page not found | VinoVoss`,
    description:
      'Explore a vast selection of wines with VinoVoss. Discover and buy the best wines online, tailored to your taste. Your journey to the perfect wine starts here.',
    keywords: 'wine, buy wine online, best wines, wine recommendations, VinoVoss',
    resolver: NOT_FOUND_PAGE_RESOLVER,
  },
  [HANDPICK_FOR_YOU_PAGE]: {
    title: `Handpicks for You | VinoVoss`,
    description: "Let's uncork your personalized wine adventure today!",
    keywords: 'handpicks, VinoVoss',
  },
};
