import { ImageSize } from 'src/app/interfaces/common';

export const VerticalCardImageSize: ImageSize = {
  width: 120,
  height: 160,
};

export const BlogPostCardImageSize: ImageSize = {
  width: 368,
  height: 240,
};

export const HorizontalCardImageSize: ImageSize = {
  width: 90,
  height: 250,
};

export const WinePageWineImageSize: ImageSize = {
  width: 110,
  height: 350,
};
