import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, takeUntil } from 'rxjs';

import {
  GOOGLE_ANALYTICS_EVENTS,
  HorizontalCardImageSize,
  VerticalCardImageSize,
} from '@constants';
import { VerticalCardType, WineResponse, WineUserPreferences, Wishlist } from '@interfaces';
import { ApiEventsService } from '@services/api-events.service';
import { GoogleAnalyticsService } from '@services/app/google-analytics.service';
import { WineService } from '@services/wine.service';
import { AppState, getUserLocationUrlPrefix } from '@store';
import { getUserProfile, sendMessageFromEmbedded } from '@utils';
import { EMBEDDED_WINE_CARD_MESSAGES } from 'src/app/constants/embedded-components.constant';
import { addCommaSeparator, convertValueToWineVolume } from 'src/app/utils/string.utils';

type WineCard = WineResponse & WineUserPreferences & { wishlists: Wishlist[]; price?: number };

@Component({
  selector: 'app-wine-card',
  templateUrl: './wine-card.component.html',
  styleUrls: ['./wine-card.component.scss'],
})
export class WineCardComponent implements OnInit, OnDestroy {
  @Input() vertical = false;
  @Input() modal = false;
  @Input() wine: WineResponse | undefined;
  @Input() embeddedComponent = false;
  @Input() moreInfoType: VerticalCardType = '';
  @Input() preventLink = false;
  @Input() xSearchId: string | null | undefined = undefined;

  @Input() deleteText = '';

  @Input() changed$: Subject<void> = new Subject<void>();

  @Output() wineClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() wineWishlistsChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

  isMore = false;

  _wine: WineCard;

  isBrowser: boolean;

  urlPrefix$: Observable<string | null>;

  readonly verticalWineImageSize = VerticalCardImageSize;
  readonly horizontalWineImageSize = HorizontalCardImageSize;

  private destroy$: Subject<void> = new Subject<void>();

  get user(): boolean {
    return !!getUserProfile();
  }

  get wineVolume(): string {
    return this._wine.bottle_volume ? convertValueToWineVolume(this._wine.bottle_volume) : '';
  }

  get vintageAvailabilityText(): string {
    const slugArray = this._wine.slug?.split('-') || [];
    let NV = '';
    if (slugArray.length && slugArray[slugArray.length - 1] === String(0)) {
      NV = 'NV';
    }

    return `${this._wine.available_vintage_count || 0} ${NV} Vintage${
      this._wine.available_vintage_count > 1 ? 's' : ''
    } Available`;
  }

  constructor(
    private wineService: WineService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store<AppState>,
    private apiEventsService: ApiEventsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.urlPrefix$ = this.store.select(getUserLocationUrlPrefix);

    if (!this.vertical && !this.modal && this.wine) {
      combineLatest([
        !getUserProfile() ? of({}) : this.wineService.getWineUserPreferences(this.wine.id),
        !getUserProfile() ? of([]) : this.wineService.checkVintageWishlist(this.wine.id),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([userPreferences, wishlists]) => {
          this._wine = {
            ...this.wine,
            ...userPreferences,
            wishlists,
          } as WineCard;
        });
    } else {
      this._wine = this.wine as any;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onWineClick(wine: any, event: MouseEvent): void {
    if (this.preventLink || this.embeddedComponent) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.embeddedComponent) {
      this.embeddedComponentGoToWine();
      return;
    }

    this.wineClicked.emit(wine);
  }

  onRegionClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.embeddedComponent) {
      sendMessageFromEmbedded<{ wine_slug: string; region_slug: string }>({
        message: EMBEDDED_WINE_CARD_MESSAGES['GO_TO_REGION'],
        data: { wine_slug: this.wine?.slug || '', region_slug: this.wine?.region_slug || '' },
      });
    }
  }

  onDeleteClick(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();

    this.deleteClicked.emit(this.wine?.id);
  }

  getPriceWithComma(value: string): string {
    return addCommaSeparator(value);
  }

  favoriteChanged(flag: boolean): void {
    this._wine.is_favorite = flag;
  }

  cellarChanged(flag: boolean): void {
    this._wine.is_in_wine_cellar = flag;
  }

  wishlistsChanged(wishlists: Wishlist[]): void {
    (this.wine as WineResponse).wishlists = wishlists;
    this.wineWishlistsChanged.emit();
  }

  showMoreDescription(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.embeddedComponent) {
      this.embeddedComponentGoToWine();
      return;
    }

    this.isMore = !this.isMore;
  }

  goToShopClicked(event: MouseEvent): void {
    window.open(this._wine.link_to_shop, '_blank');
    this.googleAnalyticsService.push(GOOGLE_ANALYTICS_EVENTS.WINE_CARD.GO_TO_SHOP_BUTTON_TAPPED);
    this.apiEventsService
      .countGoToShopButtonClicks(this._wine.id || '', this._wine.link_to_shop, this.xSearchId)
      .subscribe();
    event.preventDefault();
    event.stopPropagation();
  }

  private embeddedComponentGoToWine(): void {
    sendMessageFromEmbedded<{ slug: string }>({
      message: EMBEDDED_WINE_CARD_MESSAGES['GO_TO_WINE'],
      data: { slug: this._wine.slug },
    });
  }
}
