import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  NavigationStart,
  NavigationCancel,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { BreadcrumbInterface } from '@interfaces';
import { BreadcrumbsService } from '@services/app/breadcrumbs.service';
import { getQueryParams, getSearchQueryKey, removeEmptyValues } from '@utils';

import { AppState, getUserLocationUrlPrefix } from '@store';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
  @Input() hideHomePage = false;

  public breadcrumbs: BreadcrumbInterface[];
  public showReportData = false;
  public reportData: any;
  showSpinnerSubject$ = new BehaviorSubject<boolean>(false);
  showSpinnerAction$ = this.showSpinnerSubject$.asObservable();
  urlPrefix$: Observable<string | null>;
  navigationEvent = this.router.events;
  navigationEventEnd = this.navigationEvent.pipe(
    filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel),
    tap(() => this.showSpinnerSubject$.next(false)),
    tap(() => {
      this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
    })
  );
  navigationEventStart = this.navigationEvent.pipe(
    filter((event) => event instanceof NavigationStart),
    tap(() => this.showSpinnerSubject$.next(true))
  );
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private breadcrumbsService: BreadcrumbsService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>
  ) {
    this.breadcrumbs = [];

    this.urlPrefix$ = this.store.select(getUserLocationUrlPrefix);

    this.breadcrumbsService.updateBreadcrumb$.subscribe((data: BreadcrumbInterface) => {
      this.breadcrumbs = this.breadcrumbs.map((breadcrumb) => {
        if (
          data.matchParam &&
          breadcrumb.params[data.matchParam] === data.prevParams?.[data.matchParam]
        ) {
          return {
            label: data.label,
            params: data.params,
            url: data.url,
            queryParams: data.queryParams ?? {},
          };
        }

        if (data.updateByUrl && data.url === breadcrumb.url) {
          return {
            ...breadcrumb,
            ...removeEmptyValues(data),
          };
        }

        return breadcrumb;
      });

      this.cdr.detectChanges();
    });

    this.location.getState();
  }

  ngOnInit() {
    this.breadcrumbs = this.getBreadcrumbs(this.router.routerState.root);
    this.navigationEventStart.subscribe();
    this.navigationEventEnd.subscribe();
  }

  getSegments(prefix: any, url: string): string[] {
    return [prefix, ...url.split('/').filter((segment) => !!segment.length)];
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: BreadcrumbInterface[] = []
  ): BreadcrumbInterface[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      const brData = route.snapshot.data['dynamicBreadcrumbName'];
      const homePageDynamicBreadcrumbs = ['home-region', 'home-grape'];
      if (brData && homePageDynamicBreadcrumbs.includes(brData)) {
        return [
          ...breadcrumbs,
          {
            label:
              brData && !route.snapshot.data['skip']
                ? route.snapshot.data?.[brData].title
                : route.snapshot.data[ROUTE_DATA_BREADCRUMB],
            params: route.snapshot.params,
            queryParams: route.snapshot.data[ROUTE_DATA_BREADCRUMB],
            url,
          },
        ];
      }

      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      url += `/${routeURL}`;

      const dynamicBRData = child.snapshot.data['dynamicBreadcrumbName'];

      this.showReportData = false;

      if (child.snapshot.data['showReportButton']) {
        this.showReportData = true;
        this.reportData = {
          type: dynamicBRData,
          slug: child.snapshot.data?.[dynamicBRData].slug ?? '',
        };
      }

      const breadcrumb: BreadcrumbInterface = {
        label:
          dynamicBRData && !child.snapshot.data['skip']
            ? child.snapshot.data?.[dynamicBRData].title
            : child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        queryParams:
          child.snapshot.data[ROUTE_DATA_BREADCRUMB] &&
          child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Wine Recommendations'
            ? getQueryParams(getSearchQueryKey() || '')
            : child.snapshot.data['queryParam']
            ? {
                [child.snapshot.data['queryParam']]:
                  child.snapshot.queryParams[child.snapshot.data['queryParam']],
              }
            : {},
        url: child.snapshot.data['breadcrumbUrl'] ? child.snapshot.data['breadcrumbUrl'] : url,
      };

      if (breadcrumb.label) {
        breadcrumbs.push(breadcrumb);
      }

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return [];
  }
}
