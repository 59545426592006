<nav class="breadcrumbs-root" aria-label="breadcrumb">
  <ol class="breadcrumb" *ngIf="breadcrumbs.length; else dashboard">
    <li class="breadcrumb-item" *ngIf="!hideHomePage">
      <a aria-label="Home page" routerLinkActive="active" [routerLink]="['/']">
        <span class="icon-home">Home Page</span>
      </a>
    </li>
    <ng-template #breadcrumbLi>
      <li
        *ngFor="let breadcrumb of breadcrumbs.slice(0, breadcrumbs.length - 1); let i = index"
        class="breadcrumb-item"
      >
        <a
          routerLinkActive="active"
          [routerLink]="['/']"
          *ngIf="breadcrumb.label === 'Home'; else nonHomeLink"
        >
          <span class="icon-home"></span>
        </a>
        <ng-template #nonHomeLink>
          <a
            [routerLink]="getSegments(urlPrefix$ | async, breadcrumb.url)"
            [queryParams]="breadcrumb.queryParams || breadcrumb.queryParams"
            routerLinkActive="active"
            *ngIf="breadcrumb.label.length > 1"
            [class.active]="breadcrumb.queryParams || breadcrumb.queryParams"
          >
            {{ breadcrumb.label }}
          </a>
        </ng-template>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ breadcrumbs[breadcrumbs.length - 1].label }}
      </li>
    </ng-template>

    <li class="breadcrumb-item" *ngIf="showSpinnerAction$ | async; else breadcrumbLi">
      <a class="loading">{{ breadcrumbs[breadcrumbs.length - 1].label }}</a>
    </li>
  </ol>
  <ng-template #dashboard>
    <ol class="breadcrumb bg-dark">
      <li class="breadcrumb-item active">
        <span class="icon-home"></span>
      </li>
      <li *ngIf="showSpinnerAction$ | async">
        <span class="breadcrumb-item icon-spin3 animate-spin"></span>
      </li>
    </ol>
  </ng-template>
  <app-report-data *ngIf="showReportData" [data]="reportData"></app-report-data>
</nav>
