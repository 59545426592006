<a class="article" [routerLink]="[this.urlPrefix$ | async, 'news-and-articles', post.slug]">
  <div class="article-container">
    <img
      class="image"
      [src]="post.cover_image"
      [alt]="post.title"
      [width]="post.size.width"
      [height]="post.size.height"
    />
    <div class="info-section">
      <h2 class="title">{{ post.title }}</h2>
      <span class="description">{{ post.meta_description }}</span>
    </div>
    <div class="bottom-section">
      <div class="user-info">
        <img
          class="user-info__avatar"
          src="assets/icons/publisher-logo.jpg"
          alt="post-auth-avatar"
        />
        <div class="user-info__data">
          <span>{{ post.author || 'VinoVoss Publications' }}</span>
          <div class="user-info-post-data">
            <span class="date">{{ getDate(post.published_at) }}</span>
            <span class="how-many-read">{{ post.read_time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
