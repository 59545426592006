import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RegionResolverRoutingModule } from '@modules/region/region-routing.module';

import { RegionResolverComponent } from './region.component';

@NgModule({
  declarations: [RegionResolverComponent],
  imports: [CommonModule, RegionResolverRoutingModule],
  exports: [RegionResolverComponent],
})
export class RegionResolverModule {}
