import { Options } from '@angular-slider/ngx-slider';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-ngx-slider',
  templateUrl: './custom-ngx-slider.component.html',
  styleUrls: ['./custom-ngx-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNgxSliderComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() max = 100;

  @Input() min = 0;

  @Input() rangeOptions: Options;

  @Input() histogram: number[] = [];

  @Input() initValues: (number | undefined)[];

  @Output() onRangeChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

  public steps = 25;
  public maxHeight = 50;
  public rangeForm: FormGroup;
  public isBrowser: boolean;

  bucket = {
    range: {
      min: 0,
      max: 0,
    },
  };

  constructor(
    private doms: DomSanitizer,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.rangeOptions = {
      ...this.rangeOptions,
      translate: (value: number): string => {
        if (value >= this.max) {
          return `${value}+`;
        }

        return `${value}`;
      },
    };

    this.rangeForm = this.fb.group({
      range: [this.initValues],
    });
  }

  onUserChanging(event: any): void {
    this.bucket.range.min = parseInt(event.value);
    this.bucket.range.max = parseInt(event.highValue);
  }

  onUserChange(event: any): void {
    this.onRangeChanged.emit([event.value, event.highValue]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['initValues'] && !changes['initValues'].firstChange) {
      this.bucket.range.min = parseInt(changes['initValues'].currentValue[0]);
      this.bucket.range.max = parseInt(changes['initValues'].currentValue[1]);
    }

    if (changes && changes['rangeOptions'] && !changes['rangeOptions'].firstChange) {
      this.rangeOptions = {
        ...this.rangeOptions,
        ...changes['rangeOptions'].currentValue,
      };

      setTimeout(() => {
        this.rangeForm.patchValue({
          range: changes['initValues'].currentValue,
        });
      });
    }
  }

  ngAfterContentInit() {
    this.bucket.range.min = parseInt(this.rangeForm.get('range')?.value[0]);
    this.bucket.range.max = parseInt(this.rangeForm.get('range')?.value[1]);
  }

  safeCss(style: string): SafeStyle {
    return this.doms.bypassSecurityTrustStyle(style);
  }

  safeHtml(html: string): SafeHtml {
    return this.doms.bypassSecurityTrustHtml(html);
  }

  chart() {
    const barHeight = Math.floor(this.maxHeight / Math.max(...this.values()));
    const bars: string[] = [];
    const factorMin = Math.abs(((this.bucket.range.min - this.min) / (this.max - this.min)) * 100);
    const factorMax = Math.abs(((this.bucket.range.max - this.min) / (this.max - this.min)) * 100);

    this.values().forEach((v: number, k: number) => {
      const div = this.document.createElement('div');
      div.classList.add('bar');
      div.classList.add('inactive');

      const stepPercentage = (k / this.steps) * 100;

      if (stepPercentage > factorMin && stepPercentage < factorMax)
        div.classList.remove('inactive');

      if (k === 0 && this.bucket.range.min <= this.min) {
        div.classList.remove('inactive');
      }

      div.style.height = Math.floor(v * barHeight) + 'px';
      bars.push(div.outerHTML);
    });

    return bars.join('');
  }

  values(): number[] {
    return this.shortenAvgArray(this.histogram, this.steps);
  }

  shortenAvgArray(arr: any[], outLength: number): number[] {
    if (arr.length === 0) return [];

    while (arr.length < outLength) {
      const newArr: any[] = [];
      arr.forEach((i) => {
        newArr.push(i);
        newArr.push(i);
      });
      arr = newArr;
    }

    const n = arr.length / outLength;
    const result = [];

    let c = 0;
    while (result.length < outLength) {
      let sum = 0;
      for (let i = 0; i < n; i++) {
        sum += arr[c + i] || 0;
      }
      result.push(sum / n);
      c++;
    }

    return result;
  }
}
