import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environment';
import { WineSearchResults } from '@interfaces';
@Injectable({
  providedIn: 'root',
})
export class QueryAutoCompleteService {
  constructor(private http: HttpClient) {}

  searchAutoSuggest(text: string, count = 5): Observable<WineSearchResults> {
    return this.http
      .get<WineSearchResults>(
        `${environment.AUTO_SUGGEST_API_URL}/query/${text}?max_results=${count}`
      )
      .pipe(
        catchError(() => {
          return of({ wine_title: [] });
        })
      );
  }
}
