<ng-container *ngIf="!(isEmbeddedView$ | async); else embedded">
  <div class="layout" [class.new-year]="isNYThemeAvailable">
    <app-header></app-header>

    <main class="main-wrapper">
      <ng-container *ngIf="showBreadcrumbs$ | async">
        <app-wine-search></app-wine-search>
        <img
          *ngIf="topBannerImage?.length"
          [src]="topBannerImage"
          class="info-banner"
          width="1440"
          height="400"
          alt="top-banner"
        />
        <div class="main main--initial">
          <app-breadcrumbs
            [ngStyle]="{ 'padding-top': topBannerImage?.length ? '0' : '62px' }"
          ></app-breadcrumbs>
        </div>
      </ng-container>
      <router-outlet></router-outlet>
    </main>

    <app-footer></app-footer>
  </div>
  <app-loader></app-loader>
  <notifier-container></notifier-container>
</ng-container>

<ng-template #embedded>
  <div class="layout" [class.new-year]="isNYThemeAvailable">
    <router-outlet></router-outlet>
  </div>
</ng-template>
