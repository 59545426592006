import { BlogArticle } from '@interfaces';
import { MenuData } from '@services/menu.service';

import { mainPageEntity } from './main-page-entity.utils';
import { getNavigationBarPart } from './navigation-bar-part.jsonld.utils';

export const getArticlePageJsonLD = (
  url: string,
  menuData: MenuData,
  blog: BlogArticle
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    ...mainPageEntity(url),
    ...getNavigationBarPart(menuData.surpriseMe, [], menuData.grapes, menuData.regions),
    headline: `${blog.title}`,
    description: `${blog.meta_description}`,
    image: {
      '@type': 'ImageObject',
      url: `${blog.cover_image}`,
      height: '622',
      width: '920',
    },
    author: {
      '@type': 'Person',
      url: 'https://vinovoss.com/news-and-articles',
      name: `${blog.author || 'VinoVoss Publications'}`,
    },
    publisher: {
      '@type': 'Organization',
      name: 'VinoVoss Publication',
      logo: {
        '@type': 'ImageObject',
        url: 'https://vinovoss.com/assets/icons/publisher-logo.jpg',
        width: '36',
        height: '36',
      },
    },
    datePublished: `${blog.published_at}`,
    timeRequired: `${blog.read_time}`,
  };
};
