export const COUNTRIES = [
  'International',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Bosnia and Herzegovina',
  'Brazil',
  'Bulgaria',
  'Canada',
  'Chile',
  'China',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Egypt',
  'England',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Hungary',
  'India',
  'Israel',
  'Italy',
  'Kazakhstan',
  'Lebanon',
  'Luxembourg',
  'Macedonia',
  'Mexico',
  'Moldova',
  'Montenegro',
  'Morocco',
  'New Zealand',
  'North Macedonia',
  'Peru',
  'Portugal',
  'Romania',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'Spain',
  'Switzerland',
  'Turkey',
  'US',
  'Ukraine',
  'United Kingdom',
  'United States',
  'Uruguay',
];

export const USA_COUNTRY = 'United States';

export const DEFAULT_COUNTRY = 'United States';
export const DEFAULT_REGION = '';
