<ng-container>
  <span class="text">{{ dataToPass.text }}</span>
  <div class="form" (keydown.enter)="onComplete()">
    <div class="form-group">
      <label class="label" for="wishlist-name">Wishlist Name</label>
      <input
        class="input"
        type="text"
        id="wishlist-name"
        placeholder="Enter your new wishlist name"
        [formControl]="wishlistName"
      />
      <div class="validation-error" *ngIf="wishlistName.hasError('required')">
        Wishlist name is required.
      </div>
      <div class="validation-error" *ngIf="wishlistName.hasError('exist')">
        {{ wishlistName.errors?.['exist'] || '' }}
      </div>
    </div>
  </div>
  <div class="action-buttons">
    <button class="btn btn--red" (click)="onComplete()">Save</button>
    <button class="btn btn--gray" (click)="dialogDone.emit()">Cancel</button>
  </div>
</ng-container>
