import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TOP_WINE_FILTERS } from '@constants';
import { PromoTypes } from '@interfaces';

@Component({
  selector: 'app-wine-search-switcher',
  templateUrl: './wine-search-switcher.component.html',
  styleUrls: ['./wine-search-switcher.component.scss'],
})
export class WineSearchSwitcherComponent {
  @Input() filters: Array<PromoTypes> | string[] = [];

  @Output() select: EventEmitter<PromoTypes | string> = new EventEmitter<PromoTypes | string>();

  selectedItem: string = TOP_WINE_FILTERS[0];

  onSelect(item: PromoTypes | string): void {
    this.selectedItem = item;
    this.select.emit(item);
  }
}
