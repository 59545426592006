import { Options } from '@angular-slider/ngx-slider';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TransferState } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil, combineLatest, startWith, skip, filter } from 'rxjs';

import {
  GOOGLE_ANALYTICS_EVENTS,
  ITEMS_PER_PAGE,
  MAIN_PAGE_RECOMMEND_WINES_FILTERS,
  PRICE_RANGE_OPTIONS,
  VINTAGE_SOURCE_XMAS,
} from '@constants';
import { BlogPost, ISelectItem, UserLocationData, WineResponse } from '@interfaces';
import { GoogleAnalyticsService } from '@services/app/google-analytics.service';
import { LoaderService } from '@services/app/loader.service';
import { SEOService } from '@services/app/seo.service';
import { BlogService } from '@services/blog.service';
import { WineService } from '@services/wine.service';
import { AppState, getUserLocation, getUserLocationUrlPrefix } from '@store';
import {
  getHomePageJsonLD,
  isNYTheme,
  mapWineFiltersValues,
  removeEmptyValues,
  setSearchQueryKey,
} from '@utils';
import { getWineClassByColor, getWineClassByType } from 'src/app/utils/wine.utils';
import { HOME_BLOGS_KEY, HOME_PROMO_WINES_KEY } from 'src/transfer-state.keys';

interface HowWorksItem {
  icon: string;
  title: string;
  dataName: Observable<string[]>;
  clickable: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public wineColors: ISelectItem[] = [];
  public wineTypes: ISelectItem[] = [];

  public readonly howWorksItems: HowWorksItem[] = [
    { icon: 'wine-search', title: 'Examples', dataName: of([]), clickable: true },
    { icon: 'wine-idea', title: 'System Abilities', dataName: of([]), clickable: false },
  ];

  public readonly recommendedWineFilters: string[] = MAIN_PAGE_RECOMMEND_WINES_FILTERS;

  public readonly XMAS_VINTAGE_SOURCE: string = VINTAGE_SOURCE_XMAS;

  public rangeOptions: Options = PRICE_RANGE_OPTIONS;

  urlPrefix$: Observable<string | null>;

  _urlPrefix: string;

  searchForm: FormGroup;

  countries: string[] = [];

  promoWines: any[];

  rangeData: {
    histogram: number[];
    rangeMaxValue: number;
    rangeMinValue: number;
    rangeInitValues: (number | undefined)[];
    filterRangeOptions: Options;
  } = {
    histogram: [],
    rangeMaxValue: 200,
    rangeMinValue: 0,
    rangeInitValues: [0, 200],
    filterRangeOptions: PRICE_RANGE_OPTIONS,
  };

  searchSystemAbilities$: Observable<string[]> = of([
    'Search for wines by grape variety, region or price',
    'Get personalized wines based on taste preferences',
    'Compare wines to find a perfect match for a meal',
    'Track your wine collection and create a wishlist',
    'Search for organic wines ',
  ]);

  userLocationData: UserLocationData | undefined;

  blogPosts: BlogPost[] = [];

  isBrowser: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  get locationInfo(): string {
    if (this.userLocationData?.country !== 'USA') {
      return '';
    }

    return `near ${this.userLocationData?.region}`;
  }

  get isNYThemeAvailable(): boolean {
    return isNYTheme();
  }

  constructor(
    public loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private wineService: WineService,
    private blogService: BlogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store<AppState>,
    private transferState: TransferState,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.searchForm = this.formBuilder.group({
      query: [''],
      countries: [null],
      price_range: [this.rangeData.rangeInitValues],
      wine_types: [],
      wine_colors: [],
    });

    if (this.isBrowser) {
      this.howWorksItems[0].dataName = this.wineService.getSearchQueryExamples(5);

      const posts = this.transferState.get(HOME_BLOGS_KEY, []);
      const promoWines = this.transferState.get(HOME_PROMO_WINES_KEY, []);

      if (posts.length && promoWines.length) {
        this.blogPosts = posts;
        this.promoWines = promoWines;
        this.transferState.remove(HOME_BLOGS_KEY);
        this.transferState.remove(HOME_PROMO_WINES_KEY);
      } else {
        this.getPageData();
      }
    } else {
      this.getPageData();
    }

    this.howWorksItems[1].dataName = this.searchSystemAbilities$;

    this.urlPrefix$ = this.store.select(getUserLocationUrlPrefix);

    this.store.select(getUserLocation).subscribe((res) => {
      this._urlPrefix = res.url_prefix;
    });
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.store
        .pipe(
          select(getUserLocation),
          filter((userLocationData: UserLocationData) => Boolean(userLocationData.country.length)),
          takeUntil(this.destroy$)
        )
        .subscribe((response) => {
          this.userLocationData = response;

          const market_country = response?.country || '';
          const market_region = response?.region || '';

          this.updateFilters({ market_country, market_region });

          combineLatest([
            this.searchForm.controls['countries'].valueChanges.pipe(
              startWith(this.searchForm.controls['countries'].value)
            ),
            this.searchForm.controls['wine_colors'].valueChanges.pipe(
              startWith(this.searchForm.controls['wine_colors'].value)
            ),
            this.searchForm.controls['wine_types'].valueChanges.pipe(
              startWith(this.searchForm.controls['wine_types'].value)
            ),
          ])
            .pipe(skip(1), takeUntil(this.destroy$))
            .subscribe(([countries, wine_colors, wine_types]) => {
              const request = mapWineFiltersValues(
                removeEmptyValues({ countries, wine_types, wine_colors })
              );

              this.updateFilters({ ...request, market_country, market_region });
            });
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getWineColorClass(): string {
    return getWineClassByColor(this.searchForm.get('wine_colors')?.value);
  }

  getWineTypeClass(): string {
    return getWineClassByType(this.searchForm.get('wine_types')?.value);
  }

  onSelectRecommended(filter: string): void {
    this.googleAnalyticsService.push(
      GOOGLE_ANALYTICS_EVENTS.HOME_PAGE.RECOMMENDED_FILTER_SELECTED,
      {
        home_recommended_filter_type: filter,
      }
    );

    this.wineService
      .getPromoWines(filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: WineResponse[]) => {
        this.promoWines = res;
      });
  }

  onRangeChanged(event: number[]): void {
    this.searchForm.get('price_range')?.setValue(event);
  }

  goToRecommendation(): void {
    if (!this.searchForm.valid) {
      this.searchForm.markAllAsTouched();
      return;
    }

    const { price_range, ...data } = this.searchForm.value;

    const wineType = this.searchForm.controls['wine_types'].value;
    const wineColor = this.searchForm.controls['wine_colors'].value;

    const max = this.searchForm.controls['price_range'].value[1];
    const min = this.searchForm.controls['price_range'].value[0];

    const params = removeEmptyValues({
      ...data,
      query: this.searchForm.controls['query'].value?.trim() || '',
      wine_colors: [!wineColor ? '' : this.searchForm.controls['wine_colors'].value],
      wine_types: [!wineType ? '' : this.searchForm.controls['wine_types'].value],
      price_min: min === this.rangeData.rangeMinValue ? null : min,
      price_max: max === this.rangeData.rangeMaxValue ? null : max,
      countries: [this.searchForm.controls['countries'].value || ''],
      size: ITEMS_PER_PAGE,
    });

    this.router
      .navigate([this._urlPrefix, 'recommendations'], {
        queryParams: params,
      })
      .then(() => setSearchQueryKey(window.location.search));
  }

  private getPageData(): void {
    combineLatest([
      this.blogService.getBlogPosts(3, 1, 3),
      this.wineService.getPromoWines(MAIN_PAGE_RECOMMEND_WINES_FILTERS[0]),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([articles, promoWines]) => {
        this.blogPosts = articles.items;
        this.promoWines = promoWines;

        if (!this.isBrowser) {
          this.transferState.set(HOME_BLOGS_KEY, articles.items);
          this.transferState.set(HOME_PROMO_WINES_KEY, promoWines);
          this.seoService.setJsonLd(getHomePageJsonLD(promoWines, articles.items));
        }
      });
  }

  private updateFilters(request: any): void {
    this.wineService
      .getHomepageFilterMeta(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.countries = res.countries;
        this.wineColors = res.wine_colors.map((item) => {
          return {
            value: item,
            label: item,
          };
        });

        this.wineTypes = res.wine_types.map((item) => {
          return {
            value: item,
            label: item,
          };
        });

        this.searchForm.patchValue({
          price_range: [res.prices.min_value >= 1 ? res.prices.min_value : 0, res.prices.max_value],
        });

        this.rangeData = {
          histogram: res.prices.histogram,
          rangeMaxValue: res.prices.max_value,
          rangeMinValue: res.prices.min_value,
          rangeInitValues: [res.prices.min_value, res.prices.max_value],
          filterRangeOptions: {
            ...this.rangeOptions,
            ceil: res.prices.max_value,
            floor: res.prices.min_value,
            translate: (value: number): string => {
              if (value === res.prices.max_value) {
                return `${res.prices.max_value}+`;
              }

              return `${value}`;
            },
          },
        };
      });
  }
}
