import { Component, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs';

import { LoaderService } from '@services/app/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() forceShow = false;

  showLoader = false;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoading$
      .pipe(filter(() => !this.forceShow))
      .subscribe((loading) => (this.showLoader = loading));

    if (this.forceShow) {
      this.showLoader = true;
    }
  }
}
