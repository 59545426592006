export const HOME_PAGE_RESOLVER = 'home';
export const WINE_PAGE_RESOLVER = 'wine';
export const GRAPE_PAGE_RESOLVER = 'grape';
export const REGION_PAGE_RESOLVER = 'region';
export const WINERY_PAGE_RESOLVER = 'winery';
export const SEARCH_RESULT_PAGE_RESOLVER = 'search-data';
export const FILTERS_SEARCH_RESULT_PAGE_RESOLVER = 'filters-search-data';
export const ARTICLE_PAGE_RESOLVER = 'article';
export const CURRENT_WISHLIST_PAGE_RESOLVER = 'wishlist';
export const NOT_FOUND_PAGE_RESOLVER = '404-not-found';
