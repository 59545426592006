import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import {
  ITEMS_PER_PAGE,
  SEARCH_RESULT_PAGE_RESOLVER,
  SEO_PAGE_DATA,
  STATE_REPLACEMENT_STRING,
} from '@constants';
import { SEOService } from '@services/app/seo.service';
import { WineSearchService } from '@services/app/wine-search.service';
import { AppState, getUserLocationUrlPrefix } from '@store';
import { getLocationFromUrl, getTitleRegex, setSearchQueryKey } from '@utils';
import { replaceStringForQuery } from 'src/app/utils/string.utils';
import { REQUEST_URL } from 'src/tokens';

@Component({
  selector: 'app-wine-search',
  templateUrl: './wine-search.component.html',
  styleUrls: ['./wine-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WineSearchComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;

  queryParams: any = {};

  countries: string[] = [];
  isBrowser: boolean;

  private urlPrefix = '';
  private region = '';
  private destroy$: Subject<void> = new Subject<void>();

  get isQueryTheSame(): boolean {
    return this.queryParams['query'] === this.searchForm.get('query')?.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private wineSearchService: WineSearchService,
    private store: Store<AppState>,
    private SEOService: SEOService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(REQUEST_URL) private requestUrl: string
  ) {
    this.initForm();

    this.isBrowser = isPlatformBrowser(this.platformId);
    this.region = getLocationFromUrl(
      this.isBrowser ? window.location.pathname : this.requestUrl
    ).region;

    this.wineSearchService.searchStringChanged.subscribe((res) => {
      this.searchForm.get('query')?.setValue(replaceStringForQuery(res) || '');
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['query']) {
        this.searchForm
          .get('query')
          ?.setValue(replaceStringForQuery(params['query']) || '', { emitEvent: false });
        this.wineSearchService.wineSearchValue = params['query'];
      } else {
        this.searchForm.get('query')?.setValue('');
        this.wineSearchService.wineSearchValue = '';
      }

      this.queryParams = params;
    });
  }

  ngOnInit() {
    this.store.select(getUserLocationUrlPrefix).subscribe((prefix) => (this.urlPrefix = prefix));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  changeSearchParams(skipCheckTheSame = false) {
    let params = {
      ...this.queryParams,
      ...(this.queryParams?.['page-key'] && { ['page-key']: null }),
      query: this.searchForm.get('query')?.value,
      size: ITEMS_PER_PAGE,
    };

    if (!this.isQueryTheSame && !skipCheckTheSame) {
      params = {
        query: this.searchForm.get('query')?.value,
        size: ITEMS_PER_PAGE,
      };
    }

    this.router
      .navigate([this.urlPrefix, 'recommendations'], {
        relativeTo: this.activatedRoute,
        queryParams: params,
      })
      .then(() => {
        setSearchQueryKey(window.location.search);
        const seoSearchData = SEO_PAGE_DATA[SEARCH_RESULT_PAGE_RESOLVER];
        const searchValue = this.searchForm.get('query')?.value;

        this.SEOService.updateTitle(
          seoSearchData.title
            .replace(
              getTitleRegex(SEARCH_RESULT_PAGE_RESOLVER),
              seoSearchData.optionalSubString!(searchValue)
            )
            .replace(STATE_REPLACEMENT_STRING, this.region.length ? ` - ${this.region}` : '')
            .trim()
        );
      });
  }

  initForm(): void {
    this.searchForm = this.formBuilder.group({
      query: [''],
    });
  }

  clearSearch(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.initForm();

    this.changeSearchParams(true);
  }
}
