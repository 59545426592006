import { BlogPost, WineResponse } from '@interfaces';
import { getTextFirstSentence } from '@utils';

export const getHomePageJsonLD = (promoWines: WineResponse[], latestBlogs: BlogPost[]) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://www.vinovoss.com',
    name: 'VinoVoss',
    description:
      'The leading wine search engine to help enthusiasts discover, compare, and learn about wines.',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.vinovoss.com/recommendations?query={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    hasPart: [
      ...promoWines.map((wine) => {
        return {
          '@type': 'Offer',
          name: `${wine.title}`,
          priceCurrency: 'USD',
          price: `${wine.price}`,
          itemOffered: {
            '@type': 'Offer',
            name: `${wine.title}`,
            image: `${wine.image}`,
            description: `${getTextFirstSentence(wine.description)}`,
            category: `${wine.wine_type}`,
          },
        };
      }),
      ...latestBlogs.map((article) => {
        return {
          '@type': 'Article',
          headline: `${article.title}`,
          // "url": `${article}`,
          datePublished: `${article.published_at}`,
          author: {
            '@type': 'Organization',
            name: 'VinoVoss Publication',
          },
          description: `${article.meta_description}`,
          image: `${article.cover_image}`,
        };
      }),
    ],
    copyrightHolder: {
      '@type': 'Organization',
      name: 'VinoVoss',
      logo: 'URL-of-app-logo',
    },
    copyrightYear: `${new Date().getFullYear()}`,
    mainEntity: {
      '@type': 'CollectionPage',
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Wine Discovery',
            item: 'https://www.vinovoss.com/wine-discovery',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Grape varieties',
            item: 'https://www.vinovoss.com/grape-varieties',
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: 'Regions',
            item: 'https://www.vinovoss.com/regions',
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: 'News and Articles',
            item: 'https://www.vinovoss.com/news-and-articles',
          },
          {
            '@type': 'ListItem',
            position: 5,
            name: 'Handpicked for you',
            item: 'https://www.vinovoss.com/handpicked-for-you',
          },
        ],
      },
      mainContentOfPage: {
        '@type': 'WebPageElement',
        hasPart: [
          {
            '@type': 'SiteNavigationElement',
            name: 'Wine Discovery',
            contains: [
              'Wine Color/Type',
              'Top Occasions',
              'Unique Wines',
              'Grape varieties',
              'Regions',
              'News and Articles',
              'Handpicked for you',
            ],
          },
          {
            '@type': 'SiteNavigationElement',
            name: 'Wine Color/Type',
            contains: ['Red', 'White', 'Rosé', 'Sparkling/Champagne', 'Fortified', 'Dessert'],
          },
          {
            '@type': 'SiteNavigationElement',
            name: 'Top Occasions',
            contains: [
              'Celebration',
              'Fine Dining',
              'Romantic Date',
              'Sunny Afternoon',
              'After Work',
              'Gift-Giving',
            ],
          },
          {
            '@type': 'SiteNavigationElement',
            name: 'Unique Wines',
            contains: [
              'Celebrity',
              'Award-Winning',
              'Organic/Biodynamic',
              'Orange Wines',
              'Rare Collectible',
              'Sommelier Selections',
            ],
          },
          {
            '@type': 'SiteNavigationElement',
            name: 'Grape varieties',
            contains: [
              'Popular Grapes',
              'Cabernet Sauvignon',
              'Merlot',
              'Pinot Noir',
              'Cabernet Franc',
              'Chardonnay',
              'Petit Verdot',
            ],
          },
          {
            '@type': 'SiteNavigationElement',
            name: 'Regions',
            contains: [
              'Popular Regions',
              'Burgundy',
              'Napa',
              'Bordeaux',
              'Piemonte',
              'Sonoma',
              'Tuscany',
            ],
          },
        ],
      },
    },
  };
};
