import { Component, Input } from '@angular/core';

import { ImageSize, WineColor } from '@interfaces';

@Component({
  selector: 'app-wine-image',
  templateUrl: './wine-image.component.html',
  styleUrls: ['./wine-image.component.scss'],
})
export class WineImageComponent {
  @Input() size: ImageSize;
  @Input() url: string;
  @Input() alt = '';
}
