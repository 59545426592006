import { DayGrape, DayRegion } from '@services/menu.service';

export const getNavigationBarPart = (
  surpriseMe: string[],
  topQueries: string[],
  grapes: DayGrape[],
  regions: DayRegion[]
) => {
  return {
    hasPart: {
      '@type': 'WebPageElement',
      name: 'Navigation Bar',
      isAccessibleForFree: 'True',
      cssSelector: ['ul', 'menu'],
      hasPart: [
        {
          '@type': 'SiteNavigationElement',
          name: 'Wine Discovery',
          hasPart: [
            {
              '@type': 'SiteNavigationElement',
              name: 'Wine Color/Type',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Red',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'White',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Rosé',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Sparkling',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Fortified',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Dessert',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Top Occasions',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Celebration',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Fine Dining',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Romantic Date',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Sunny Afternoon',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'After Work',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Gift-Giving',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Unique Wines',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Celebrity',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Award-Winning',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Organic/Biodynamic',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Orange Wines',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Rare Collectible',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Sommelier Selections',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Surprise Me!',
              hasPart: surpriseMe.map((item) => {
                return {
                  '@type': 'SiteNavigationElement',
                  name: `${item}`,
                };
              }),
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Top Searched Queries',
              hasPart: topQueries.map((item) => {
                return {
                  '@type': 'SiteNavigationElement',
                  name: `${item}`,
                };
              }),
            },
          ],
        },
        {
          '@type': 'SiteNavigationElement',
          name: 'Grape varieties',
          hasPart: [
            {
              '@type': 'SiteNavigationElement',
              name: 'Popular Grapes',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Cabernet Sauvignon',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Merlot',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Pinot Noir',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Cabernet Franc',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Chardonnay',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Petit Verdot',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Popular Styles',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Fruity',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Elegant',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Bold',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Fresh',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Smooth',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Oaked',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Classic Grapes',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Pinot Noir',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Cabernet Sauvignon',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Chardonnay',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Merlot',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Syrah/Shiraz',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Sauvignon Blanc',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Emerging Grapes',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Albariño',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Grüner Veltliner',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Vermentino',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Viognier',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Barbera',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Blaufränkisch',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Grapes of the day',
              hasPart: grapes.map((item) => {
                return {
                  '@type': 'SiteNavigationElement',
                  name: `${item.name}`,
                };
              }),
            },
          ],
        },
        {
          '@type': 'SiteNavigationElement',
          name: 'Regions',
          hasPart: [
            {
              '@type': 'SiteNavigationElement',
              name: 'Popular Regions',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Burgundy',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Napa',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Bordeaux',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Piemonte',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Tuscany',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Sonoma',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Old World Classics',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Bordeaux',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Burgundy',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Tuscany',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Piedmont',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Rhone',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Champagne',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'New World Gems',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Napa Valley',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Sonoma County',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Mendoza',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Margaret River',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Stellenbosch',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Casablanca Valley',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Terroir',
              hasPart: [
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Cool',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Warm',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Volcanic',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Coastal/Island',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'High Altitude',
                },
                {
                  '@type': 'SiteNavigationElement',
                  name: 'Extreme',
                },
              ],
            },
            {
              '@type': 'SiteNavigationElement',
              name: 'Regions of the day',
              hasPart: regions.map((item) => {
                return {
                  '@type': 'SiteNavigationElement',
                  name: `${item.region_name}`,
                };
              }),
            },
          ],
        },
        {
          '@type': 'SiteNavigationElement',
          name: 'News and Articles',
        },
        {
          '@type': 'SiteNavigationElement',
          name: 'Handpicked for you',
        },
      ],
    },
  };
};
