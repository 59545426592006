import { NgModule } from '@angular/core';

import { BoldCoincidencePipe } from '@shared/pipes/bold-coincidence.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { SeoUrlFormatPipe } from '@shared/pipes/seo-url-format.pipe';

@NgModule({
  declarations: [SeoUrlFormatPipe, SafeHtmlPipe, BoldCoincidencePipe],
  exports: [SeoUrlFormatPipe, SafeHtmlPipe, BoldCoincidencePipe],
  providers: [SeoUrlFormatPipe],
})
export class PipesModule {}
