<div class="loading-cards-wrapper">
  <div class="loading-card" *ngIf="isHorizontal; else vertical">
    <div class="fake-image">
      <ngx-skeleton-loader
        [theme]="{ width: '80px', height: '80px' }"
        appearance="circle"
      ></ngx-skeleton-loader>
    </div>
    <div class="fake-lines">
      <div *ngFor="let data of fakeLinesThemeData">
        <ngx-skeleton-loader
          *ngIf="!data.items; else circle"
          [theme]="data"
          appearance="line"
        ></ngx-skeleton-loader>

        <ng-template #circle>
          <div class="fake-line">
            <ng-container *ngFor="let subData of data.items">
              <ngx-skeleton-loader [theme]="subData" appearance="line"></ngx-skeleton-loader>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="fake-price">
      <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
    </div>
  </div>
  <ng-template #vertical>
    <div class="loading-card loading-card--vertical">
      <div class="fake-image">
        <ngx-skeleton-loader
          [theme]="{ width: '100px', height: '100px', marginTop: '40px' }"
          appearance="circle"
        ></ngx-skeleton-loader>
      </div>
      <div class="fake-lines fake-lines--vertical">
        <div *ngFor="let data of fakeLinesVerticalThemeData">
          <ngx-skeleton-loader [theme]="data" appearance="line"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="fake-price fake-price--vertical">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{ width: '50%', height: '30px', margin: '0' }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </ng-template>
</div>
