export const FOOD_PAIRS = [
  {
    title: 'Pepperoni Pizza',
    key: 'pizza',
    imageName: 'pizza',
  },
  {
    title: 'Salads',
    key: 'salads',
    imageName: 'salads',
  },
  {
    title: 'Vegetables',
    key: 'vegetables',
    imageName: 'vegetables',
  },
  {
    title: 'Barbecue',
    key: 'barbecue',
    imageName: 'barbecue',
  },
  {
    title: 'Asian cuisine',
    key: 'asian_cuisine',
    imageName: 'asian_cuisine',
  },
  {
    title: 'Roast chicken',
    key: 'roast_chicken',
    imageName: 'roast_chicken',
  },
  {
    title: 'Roast beef',
    key: 'roast_beef',
    imageName: 'roast_beef',
  },
  {
    title: 'Roasted meats',
    key: 'roasted_meats',
    imageName: 'roasted_meats',
  },
  {
    title: 'Roasted vegetables',
    key: 'roasted_vegetables',
    imageName: 'roasted_vegetables',
  },
  {
    title: 'Root vegetables',
    key: 'root_vegetables',
    imageName: 'root_vegetables',
  },
  {
    title: 'Tomatoes',
    key: 'tomatoes',
    imageName: 'tomatoes',
  },
  {
    title: 'Duck',
    key: 'duck',
    imageName: 'duck',
  },
  {
    title: 'Dried fruits',
    key: 'dried_fruits',
    imageName: 'dried_fruits',
  },
  {
    title: 'Mushrooms',
    key: 'mushrooms',
    imageName: 'mushrooms',
  },
  {
    title: 'Grilled vegetables',
    key: 'grilled_vegetables',
    imageName: 'grilled_vegetables',
  },
  {
    title: 'Cakes',
    key: 'cakes',
    imageName: 'cakes',
  },
  {
    title: 'Caramelized fruits',
    key: 'caramelized_fruits',
    imageName: 'caramelized_fruits',
  },
  {
    title: 'Chocolate',
    key: 'chocolate',
    imageName: 'chocolate',
  },
  {
    title: 'Desserts',
    key: 'desserts',
    imageName: 'desserts',
  },
  {
    title: 'Salmon',
    key: 'salmon',
    imageName: 'salmon',
  },
  {
    title: 'Grains',
    key: 'grains',
    imageName: 'grains',
  },
  {
    title: 'Walnuts',
    key: 'walnuts',
    imageName: 'walnuts',
  },
  {
    title: 'Stews',
    key: 'stews',
    imageName: 'stews',
  },
  {
    title: 'Pies',
    key: 'pies',
    imageName: 'pies',
  },
  {
    title: 'Nuts',
    key: 'nuts',
    imageName: 'nuts',
  },
  {
    title: 'Herbs',
    key: 'herbs',
    imageName: 'herbs',
  },
  {
    title: 'Olives',
    key: 'olives',
    imageName: 'olives',
  },
  {
    title: 'Spicy dishes',
    key: 'spicy_dishes',
    imageName: 'spicy_dishes',
  },
  {
    title: 'Stilton cheese',
    key: 'stilton_cheese',
    imageName: 'stilton_cheese',
  },
  {
    title: 'Grilled fish',
    key: 'grilled_fish',
    imageName: 'grilled_fish',
  },
  {
    title: 'Legumes',
    key: 'legumes',
    imageName: 'legumes',
  },
  {
    title: 'Sashimi',
    key: 'sashimi',
    imageName: 'sashimi',
  },
  {
    title: 'Ice cream',
    key: 'ice_cream',
    imageName: 'ice_cream',
  },
  {
    title: 'Sushi',
    key: 'sushi',
    imageName: 'sushi',
  },
  {
    title: 'Truffles',
    key: 'truffles',
    imageName: 'truffles',
  },
  {
    title: 'Sweet dishes',
    key: 'sweet_dishes',
    imageName: 'sweet_dishes',
  },
  {
    title: 'Seafood',
    key: 'seafood',
    imageName: 'seafood',
  },
  {
    title: 'Steak',
    key: 'steak',
    imageName: 'steak',
  },
  {
    title: 'Sweet spices',
    key: 'sweet_spices',
    imageName: 'sweet_spices',
  },
  {
    title: 'Figs',
    key: 'figs',
    imageName: 'figs',
  },
  {
    title: 'Fried foods',
    key: 'fried_foods',
    imageName: 'fried_foods',
  },
  {
    title: 'Fried chicken',
    key: 'fried_chicken',
    imageName: 'fried_chicken',
  },
  {
    title: 'Foie gras',
    key: 'foie_gras',
    imageName: 'foie_gras',
  },
  {
    title: 'Chocolate desserts',
    key: 'chocolate_desserts',
    imageName: 'chocolate_desserts',
  },
  {
    title: 'Creamy sauces',
    key: 'creamy_sauces',
    imageName: 'creamy_sauces',
  },
  {
    title: 'Curries',
    key: 'curries',
    imageName: 'curries',
  },
  {
    title: 'Dark chocolate',
    key: 'dark_chocolate',
    imageName: 'dark_chocolate',
  },
  {
    title: 'Burgers',
    key: 'burgers',
    imageName: 'burgers',
  },
  {
    title: 'Fruits',
    key: 'fruits',
    imageName: 'fruits',
  },
  {
    title: 'Fruit-based desserts',
    key: 'fruit-based_desserts',
    imageName: 'fruit-based_desserts',
  },
  {
    title: 'Fruit-based dishes',
    key: 'fruit-based_dishes',
    imageName: 'fruit-based_dishes',
  },
  {
    title: 'Strawberry Cake',
    key: 'cakes',
    imageName: 'strawberry_cake',
  },
  {
    title: 'Beef',
    key: 'beef',
    imageName: 'beef',
  },
  {
    title: 'Roast Beef',
    key: 'roast beef',
    imageName: 'beef',
  },
  {
    key: 'game',
    title: 'Game (deer, venison)',
    imageName: 'game',
  },
  {
    title: 'Poultry',
    key: 'poultry',
    imageName: 'poultry',
  },
  {
    title: 'Lamb',
    key: 'lamb',
    imageName: 'lamb',
  },
  {
    title: 'Pork',
    key: 'pork',
    imageName: 'pork',
  },
  {
    key: 'rich_fish',
    title: 'Rich fish (salmon, tuna etc)',
    imageName: 'rich_fish',
  },
  {
    key: 'fish',
    title: 'Fish',
    imageName: 'fish',
  },
  {
    key: 'cheese',
    title: 'Cheese',
    imageName: 'cheese',
  },
  {
    key: 'cheese_plates',
    title: 'Cheese plates',
    imageName: 'cheese',
  },
  {
    key: 'cheese_platter',
    title: 'Cheese platter',
    imageName: 'cheese',
  },
  {
    key: 'cheeses',
    title: 'Cheeses',
    imageName: 'cheese',
  },
  {
    title: 'Shellfish',
    key: 'shellfish',
    imageName: 'shellfish',
  },
  {
    title: 'Pasta',
    key: 'pastas',
    imageName: 'pasta',
  },
  {
    title: 'Pasta',
    key: 'pasta',
    imageName: 'pasta',
  },
  {
    title: 'Soft cheeses',
    key: 'soft_cheeses',
    imageName: 'soft_cheeses',
  },
  {
    title: 'Grilled meats',
    key: 'grilled_meats',
    imageName: 'grilled_meats',
  },
  {
    title: 'Fruity desserts',
    key: 'fruit-based desserts',
    imageName: 'fruity_desserts',
  },
  {
    title: 'Spicy dishes',
    key: 'spicy dishes',
    imageName: 'spicy_food',
  },
  {
    title: 'Mild and soft cheese',
    key: 'soft cheeses',
    imageName: 'mild_soft_cheese',
  },
  {
    title: 'Blue cheese',
    key: 'blue cheese',
    imageName: 'blue_cheese',
  },
  {
    title: 'Sweet desserts',
    key: 'desserts',
    imageName: 'sweet_desserts',
  },
  {
    title: 'Appetizers and snacks',
    key: 'appetizers',
    imageName: 'appetizers_snacks',
  },
  //TODO don't have images
  {
    title: 'Aperitif',
    imageName: 'aperitif',
  },
  {
    title: 'Lean fish',
    key: 'lean_fish',
    imageName: 'lean_fish',
  },
  {
    title: 'Cured Meat',
    imageName: 'cured_meat',
  },
  {
    title: 'Goat cheese',
    imageName: 'goat_cheese',
  },
  {
    title: 'Vegetarian',
    imageName: 'vegetarian',
  },
  {
    title: 'Mature and hard cheese',
    imageName: 'mature_cheese',
  },
  {
    title: 'Veal',
    imageName: 'veal',
  },
  {
    title: 'Tortellini',
    imageName: 'tortellini',
  },
];
