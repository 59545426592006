import { User, UserLocationData } from '@interfaces';

const JWTTokenKey = 'JWTToken';
export const setJWTToken = (token: string): void => localStorage.setItem(JWTTokenKey, token);
export const getJWTToken = (): string | null => localStorage.getItem(JWTTokenKey);
export const clearJWTToken = (): void => localStorage.removeItem(JWTTokenKey);

const refreshToken = 'refreshToken';
export const setRefreshToken = (token: string): void => localStorage.setItem(refreshToken, token);
export const getRefreshToken = (): string | null => localStorage.getItem(refreshToken);
export const clearRefreshToken = (): void => localStorage.removeItem(refreshToken);

const searchQueryKey = 'lastSearchQuery';
export const setSearchQueryKey = (query: string): void =>
  localStorage.setItem(searchQueryKey, query);
export const getSearchQueryKey = (): string | null => localStorage.getItem(searchQueryKey);
export const clearSearchQueryKey = (): void => localStorage.removeItem(searchQueryKey);

// eslint-disable-next-line
const setEntity = (key: string, entity: any, session = false) =>
  session
    ? sessionStorage.setItem(key, JSON.stringify(entity))
    : localStorage.setItem(key, JSON.stringify(entity));
export const getEntity = <T>(key: string, session = false) => {
  if (session) {
    return sessionStorage.getItem(key)
      ? (JSON.parse(sessionStorage.getItem(key) || '') as T)
      : null;
  }

  return localStorage.getItem(key) ? (JSON.parse(localStorage.getItem(key) || '') as T) : null;
};

const userProfileKey = 'userProfile';
export const setUserProfile = (profile: User): void => setEntity(userProfileKey, profile);

// eslint-disable-next-line
export const getUserProfile = (): any => getEntity<User>(userProfileKey);
export const clearUserProfile = (): void => localStorage.removeItem(userProfileKey);

const userLocationKey = 'userSessionLocation';
export const setUserLocation = (location: UserLocationData): void =>
  setEntity(userLocationKey, location, true);
export const getBrowserStorageUserLocation = (): any =>
  getEntity<UserLocationData>(userLocationKey, true);

export const clearUserLocation = (): void => sessionStorage.removeItem(userLocationKey);
const articlePrevPageKey = 'articlePrevPage';
export const setArticlePrevPage = (page: number): void => setEntity(articlePrevPageKey, page, true);
export const getArticlePrevPage = (): string | null => getEntity(articlePrevPageKey, true);
export const clearArticlePrevPage = (): void => sessionStorage.removeItem(articlePrevPageKey);
