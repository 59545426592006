import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HOME_PAGE_RESOLVER, NOT_FOUND_PAGE_RESOLVER, SEO_PAGE_DATA } from '@constants';
import { RegionResolverComponent } from '@modules/region/region.component';
import { CustomURLMatcher } from '@modules/region/region.matcher';
import { RegionResolver } from '@modules/region/region.resolver';
import { UserRootComponent } from '@modules/user/user.component';
import { WineRecommendationRootComponent } from '@modules/wine-recommendation/wine-recommendation.component';
import { GoogleCallbackComponent } from '@pages/google-callback/google-callback.component';
import { HomeComponent } from '@pages/home/home.component';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { VerificationComponent } from '@pages/verification/verification.component';

import { AuthGuard } from '../../guards/auth.guard';

const routes: Routes = [
  {
    matcher: CustomURLMatcher.languageMatcher,
    component: RegionResolverComponent,
    resolve: {
      resolver: RegionResolver,
    },
    pathMatch: 'full',
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          seo: SEO_PAGE_DATA[HOME_PAGE_RESOLVER],
          skip: true,
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/content-pages/content-pages.module').then(
            (mod) => mod.ContentPagesModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/info-pages/info-pages.module').then((mod) => mod.InfoPagesModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('@modules/user-flow/user-flow.module').then((mod) => mod.UserFlowModule),
      },
      {
        path: 'wine',
        loadChildren: () => import('@modules/wine/wine.module').then((mod) => mod.WineModule),
        data: {
          breadcrumb: 'Wine Recommendations',
          breadcrumbUrl: '/recommendations',
        },
      },
      {
        path: 'recommendations',
        component: WineRecommendationRootComponent,
        loadChildren: () =>
          import('@modules/wine-recommendation/wine-recommendation.module').then(
            (mod) => mod.WineRecommendationModule
          ),
        data: {
          breadcrumb: 'Wine Recommendations',
        },
      },
      {
        path: 'news-and-articles',
        loadChildren: () => import('@modules/blog/blog.module').then((mod) => mod.BlogModule),
        data: {
          breadcrumb: 'News & Articles',
        },
      },
      {
        path: 'profile',
        component: UserRootComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('@modules/user/user.module').then((mod) => mod.UserModule),
        data: {
          breadcrumb: 'My Profile',
        },
      },
      {
        path: 'reset_password',
        component: VerificationComponent,
      },
      {
        path: 'google/callback',
        component: GoogleCallbackComponent,
      },
      {
        path: 'embedded/wine/:slug',
        loadChildren: () =>
          import('../embedded/embedded-wine-card/embedded-wine-card.module').then(
            (m) => m.EmbeddedWineCardModule
          ),
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
        pathMatch: 'full',
        data: {
          seo: SEO_PAGE_DATA[NOT_FOUND_PAGE_RESOLVER],
          skip: true,
        },
      },
      {
        path: '**',
        redirectTo: 'page-not-found',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegionResolverRoutingModule {}
