<div class="wine-search">
  <div class="main">
    <div class="search-section">
      <div class="search-section__search">
        <label class="search-icon" (click)="changeSearchParams()"></label>
        <div class="form">
          <app-autocomplete-input
            [placeholder]="'Describe your perfect wine...'"
            [formGroup]="searchForm"
            [fcName]="'query'"
            (onKeyEnter)="changeSearchParams()"
            (onClearClick)="clearSearch($event)"
          ></app-autocomplete-input>
        </div>
      </div>
      <!-- Advanced Search excluded by ClickUp task 86869cdb4, there commit message for return functionality-->
    </div>
  </div>
</div>
