import { UserLocationData } from '@interfaces';
import { UPDATE_USER_LOCATION, UserLocationActions } from '@store/actions';
import { initialUserLocationData } from '@store/state';
import { setUserLocation } from '@utils';

export const userLocationReducers = (
  state = initialUserLocationData,
  action: UserLocationActions
): UserLocationData => {
  switch (action.type) {
    case UPDATE_USER_LOCATION:
      setUserLocation({
        ...state,
        ...action.payload,
      });

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
