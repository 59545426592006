import { UserLocationData } from '@interfaces';

export const DEFAULT_IP_DATA: UserLocationData = {
  country: '',
  region: '',
  initial_country: '',
  initial_region: '',
  latitude: 0,
  longitude: 0,
  url_prefix: '',
};
